import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config:
        {
            headerName: "Etapa en el onboarding",
            field: "ETAPA ONBOARDING",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config:
        {
            headerName: "Estado en el onboarding",
            field: "ESTADO ONBOARDING",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: getLocalizedString("incomeDate"),
            field: "FECHA DE INGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: getLocalizedString("position"),
            field: "CARGO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: 'Función',
            field: "FUNCIÓN",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: getLocalizedString("management"),
            field: "GERENCIA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: getLocalizedString("department"),
            field: "DEPARTAMENTO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: 'Centro de costo de gestión',
            field: "CENTRO DE COSTO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: 'Unidad de negocio',
            field: "UNIDAD DE NEGOCIO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: 'Email',
            field: "EMAIL",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: 'Email personal',
            field: "EMAIL PERSONAL",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: 'Modalidad de trabajo',
            field: "MODALIDAD DE TRABAJO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcessesAssignment],
        config: {
            headerName: 'Manager',
            field: "MANAGER",
            filter: "agTextColumnFilter",
        }
    },

    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "ESTADO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config:
        {
            headerName: "Acceso a ICARIUS",
            field: "HABILITAR EL ACCESO A ICARIUS",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("incomeDate"),
            field: "FECHA DE INGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("position"),
            field: "CARGO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: 'Función',
            field: "FUNCIÓN",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "TIPO DE FUNCIONARIO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: 'Obra o Faena',
            field: "OBRA O FAENA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: 'Centro de costo de gestión',
            field: "CENTRO DE COSTO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("management"),
            field: "GERENCIA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("department"),
            field: "DEPARTAMENTO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: 'Tipo de jornada',
            field: "TIPO DE JORNADA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: 'Unidad de negocio',
            field: "UNIDAD DE NEGOCIO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: 'Email',
            field: "EMAIL",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: 'Email personal',
            field: "EMAIL PERSONAL",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["onboardingProcessesAssignmentUserSelection"],
        config: {
            headerName: 'Modalidad de trabajo',
            field: "MODALIDAD DE TRABAJO",
            filter: "agSetColumnFilter",
        }
    },
]