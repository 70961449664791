import React, { useEffect, useMemo, useState } from "react";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteOnboardingProcessAction,
  getOnboardingProcessesAction,
} from "../actions";
import {
  getData,
  getIsLoading,
  getProfileList,
  getStatusList,
} from "../selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";

const useOnboardingProcesses = () => {

  const [switchValue, setSwitchValue] = useState(true);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector(getData);
  const isLoading = useSelector(getIsLoading);
  const statusList = useSelector(getStatusList);
  const profileList = useSelector(getProfileList);

  useEffect(() => {
    dispatch(getOnboardingProcessesAction());
  }, [dispatch])

  const handleOpenDeleteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      maxWidth: "md",
      title: getLocalizedString("atention"),
      msg: (
        <>
          Si elimina el proceso de onboarding, también se eliminará:
          <br />
          Toda la trazabilidad y el detalle de los colaboradores que han participado.
          <br />
          Sus comentarios, adjuntos, y feedbacks recibidos durante el proceso.
          <br />
          Las acciones realizadas y las etapas por las cuales han pasado.
          <br />
          <br />
          ¿Está seguro que desea continuar?
        </>
      ),
      onConfirm: () => {
        dispatch(openDialogAction({
          title: getLocalizedString("atention"),
          msg: '¿Está seguro que desea eliminar el proceso?',
          onConfirm: () => dispatch(deleteOnboardingProcessAction({ internalCode: selectedRows[0].internalCode })),
        }));
      }
    }));
  }

  const handleOpenCreateDialog = () => {
    setSelectedData(null);
    setFormIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(selectedRows[0]);
    setFormIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setFormIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenAssign = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    history.push({
      pathname: paths.onboardingProcessesAssignment,
      search: `?code=${selectedRows[0].code}&name=${selectedRows[0].name}&status=${selectedRows[0].status}&statusString=${selectedRows[0].statusString}`,
    });
  }

  const handleToggleSwitch = () => {
    setSwitchValue(prev => !prev);
  }

  const filteredData = useMemo(() => {
    if (!switchValue) return data;
    return data.filter((item) => item.status === "A");
  }, [data, switchValue])

  const state = {
    switchValue,
    formIsOpen,
    selectedData,
  }

  const handlers = {
    handleToggleSwitch,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleOpenAssign,
  }

  return {
    data: filteredData,
    statusList,
    profileList,
    isLoading,
    state,
    handlers,
  }
}

export default useOnboardingProcesses;