import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const GET_TREE = NAME + "/GET_TREE";
export const GET_TREE_REJECTED = NAME + "/GET_TREE_REJECTED";
export const GET_TREE_FULFILLED = NAME + "/GET_TREE_FULFILLED"

export const GET_MOMENTS = NAME + "/GET_MOMENTS";
export const GET_MOMENTS_REJECTED = NAME + "/GET_MOMENTS_REJECTED";
export const GET_MOMENTS_FULFILLED = NAME + "/GET_MOMENTS_FULFILLED"

export const GET_PROGRESS = NAME + "/GET_PROGRESS";
export const GET_PROGRESS_REJECTED = NAME + "/GET_PROGRESS_REJECTED";
export const GET_PROGRESS_FULFILLED = NAME + "/GET_PROGRESS_FULFILLED"

export const GET_ACTIONS = NAME + "/GET_ACTIONS";
export const GET_ACTIONS_REJECTED = NAME + "/GET_ACTIONS_REJECTED";
export const GET_ACTIONS_FULFILLED = NAME + "/GET_ACTIONS_FULFILLED";

export const GET_COLLABORATORS = NAME + "/GET_COLLABORATORS";
export const GET_COLLABORATORS_REJECTED = NAME + "/GET_COLLABORATORS_REJECTED";
export const GET_COLLABORATORS_FULFILLED = NAME + "/GET_COLLABORATORS_FULFILLED";

export const ADD_COLLABORATOR = NAME + "/ADD_COLLABORATOR";
export const ADD_COLLABORATOR_REJECTED = NAME + "/ADD_COLLABORATOR_REJECTED";
export const ADD_COLLABORATOR_FULFILLED = NAME + "/ADD_COLLABORATOR_FULFILLED";

export const DELETE_COLLABORATOR = NAME + "/DELETE_COLLABORATOR";
export const DELETE_COLLABORATOR_REJECTED = NAME + "/DELETE_COLLABORATOR_REJECTED";
export const DELETE_COLLABORATOR_FULFILLED = NAME + "/DELETE_COLLABORATOR_FULFILLED";

export const UPDATE_COLLABORATOR = NAME + "/UPDATE_COLLABORATOR";
export const UPDATE_COLLABORATOR_REJECTED = NAME + "/UPDATE_COLLABORATOR_REJECTED";
export const UPDATE_COLLABORATOR_FULFILLED = NAME + "/UPDATE_COLLABORATOR_FULFILLED";

export const ASSIGN_RESPONSIBLE = NAME + "/ASSIGN_RESPONSIBLE";
export const ASSIGN_RESPONSIBLE_REJECTED = NAME + "/ASSIGN_RESPONSIBLE_REJECTED";
export const ASSIGN_RESPONSIBLE_FULFILLED = NAME + "/ASSIGN_RESPONSIBLE_FULFILLED";

export const DELETE_ACTION = NAME + "/DELETE_ACTION";
export const DELETE_ACTION_REJECTED = NAME + "/DELETE_ACTION_REJECTED";
export const DELETE_ACTION_FULFILLED = NAME + "/DELETE_ACTION_FULFILLED";

export const UPDATE_ACTIONS = NAME + "/UPDATE_ACTIONS";
export const UPDATE_ACTIONS_REJECTED = NAME + "/UPDATE_ACTIONS_REJECTED";
export const UPDATE_ACTIONS_FULFILLED = NAME + "/UPDATE_ACTIONS_FULFILLED";

export const UPDATE_INDIVIDUAL_ACTION = NAME + "/UPDATE_INDIVIDUAL_ACTION";
export const UPDATE_INDIVIDUAL_ACTION_REJECTED = NAME + "/UPDATE_INDIVIDUAL_ACTION_REJECTED";
export const UPDATE_INDIVIDUAL_ACTION_FULFILLED = NAME + "/UPDATE_INDIVIDUAL_ACTION_FULFILLED";

export const UPDATE_INDIVIDUAL_ACTION_FEEDBACK = NAME + "/UPDATE_INDIVIDUAL_ACTION_FEEDBACK";

// comments
export const GET_COMMENTS = NAME + "/GET_COMMENTS";
export const GET_COMMENTS_FULFILLED = NAME + "/GET_COMMENTS_FULFILLED";
export const GET_COMMENTS_REJECTED = NAME + "/GET_COMMENTS_REJECTED";

export const CREATE_COMMENT = NAME + "/CREATE_COMMENT";
export const CREATE_COMMENT_FULFILLED = NAME + "/CREATE_COMMENT_FULFILLED";
export const CREATE_COMMENT_REJECTED = NAME + "/CREATE_COMMENT_REJECTED";

export const UPDATE_COMMENT = NAME + "/UPDATE_COMMENT";
export const UPDATE_COMMENT_FULFILLED = NAME + "/UPDATE_COMMENT_FULFILLED";
export const UPDATE_COMMENT_REJECTED = NAME + "/UPDATE_COMMENT_REJECTED";

export const DELETE_COMMENT = NAME + "/DELETE_COMMENT";
export const DELETE_COMMENT_FULFILLED = NAME + "/DELETE_COMMENT_FULFILLED";
export const DELETE_COMMENT_REJECTED = NAME + "/DELETE_COMMENT_REJECTED";

export const RESET_COMMENTS = NAME + "/RESET_COMMENTS";

// files
export const GET_FILES = NAME + "/GET_FILES";
export const GET_FILES_FULFILLED = NAME + "/GET_FILES_FULFILLED";
export const GET_FILES_REJECTED = NAME + "/GET_FILES_REJECTED";

export const CREATE_FILE = NAME + "/CREATE_FILE";
export const CREATE_FILE_FULFILLED = NAME + "/CREATE_FILE_FULFILLED";
export const CREATE_FILE_REJECTED = NAME + "/CREATE_FILE_REJECTED";

export const DELETE_FILE = NAME + "/DELETE_FILE";
export const DELETE_FILE_FULFILLED = NAME + "/DELETE_FILE_FULFILLED";
export const DELETE_FILE_REJECTED = NAME + "/DELETE_FILE_REJECTED";

export const RESET_FILES = NAME + "/RESET_FILES";