import {
  GET_ORGANIZATION_STRUCTURE_DATA,
  GET_ORGANIZATION_STRUCTURE_DATA_FULFILLED,
  GET_ORGANIZATION_STRUCTURE_DATA_REJECTED,
  SAVE_ORGANIZATION_STRUCTURE_DATA,
  SAVE_ORGANIZATION_STRUCTURE_DATA_FULFILLED,
  SAVE_ORGANIZATION_STRUCTURE_DATA_REJECTED,
  GET_ORGANIZATION_OCCUPATION_DATA,
  GET_ORGANIZATION_OCCUPATION_DATA_FULFILLED,
  GET_ORGANIZATION_OCCUPATION_DATA_REJECTED,
} from "./actionTypes";

const initialState = {
  orgChart: [],
  gettingOrgChart: false,
  branches: [],
  statistics: [],
  occupationData: [],
  gettingBranchesData: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case GET_ORGANIZATION_STRUCTURE_DATA:
      return { ...state, gettingOrgChart: true };
    case GET_ORGANIZATION_STRUCTURE_DATA_FULFILLED:
      return { ...state, orgChart: action.payload.orgChart, statistics: action.payload.statistics, gettingOrgChart: false };
    case GET_ORGANIZATION_STRUCTURE_DATA_REJECTED:
      return { ...state, orgChart: [], statistics: [], gettingOrgChart: false };

    case GET_ORGANIZATION_OCCUPATION_DATA:
      return { ...state };
    case GET_ORGANIZATION_OCCUPATION_DATA_FULFILLED:
      return { ...state, occupationData: action.payload.orgChart };
    case GET_ORGANIZATION_OCCUPATION_DATA_REJECTED:
      return { ...state, occupationData: [] };


    case SAVE_ORGANIZATION_STRUCTURE_DATA:
      return { ...state, gettingOrgChart: true };
    case SAVE_ORGANIZATION_STRUCTURE_DATA_FULFILLED:
      return { ...state, orgChart: action.payload.orgChart, statistics: action.payload.statistics, gettingOrgChart: false };
    case SAVE_ORGANIZATION_STRUCTURE_DATA_REJECTED:
      return { ...state, orgChart: [], gettingOrgChart: false };

    default:
      return state;
  }
}
