import React from "react";
import ProfileImg from "@icarius-common/profileImg";
import { Typography } from "@material-ui/core";
import DotsMenu from "@icarius-common/dotsMenu";

const Header = (props) => {

    const {
        image,
        name,
        date,
        hasMenu,
        menuOptions,
    } = props;

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 10 }}>
                <ProfileImg
                    image={image}
                    size={30}
                />
                <Typography className="whiteText" style={{ fontWeight: 600 }}>
                    {name}
                </Typography>
                <Typography
                    className="whiteText firstLetterUppercase"
                    style={{
                        fontSize: 14,
                        fontWeight: 500,
                    }}
                >
                    {date}
                </Typography>                
            </div>
            {
                hasMenu &&
                <DotsMenu>
                    {menuOptions}
                </DotsMenu>
            }
        </div>
    )
}

export default Header;