import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CommonPage from "@icarius-common/commonPage";
import OwnOrgChart from './orgChart';
import { getAppColor } from "src/app/selectors";
import {
  getOrgChart,
  getBranches,
  getLoading,
  getPositionClassifications,
  getPositionOccupationData,
  getPeopleOrgChart,
  getWorkplaces,
  getManagement,
} from "../selectors";
import { getOrgChartAction, getClientBranchesAction, getPositionOccupation } from "../actions";
import { getTheme } from "@icarius-pages/login/selectors";
import StatisticsGridDialog from "./statisticsGridDialog";
import ConfirmActionDialog from "./confirmActionDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DetailsEditDialog from './detailsEditDialog';
import DialogBranches from "./dialogBranches";
import FilterPositionDialog from './filterPositionDialog';
import { IMAGES_ENDPOINT, GROUP_IMAGE_ORGCHART_ENDPOINT } from "@icarius-connection/endpoints";
import FiltersDialog from "./filtersDialog";

const filtersDummyData = {
  "Grado de responsabilidad": ""
};

const highlightDummyData = {
  "workplace": [],
  "management": []
};

const responsabilityLevelSelect = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

const PositionEditor = () => {

  const dispatch = useDispatch();
  const chartRef = useRef();

  const [branch, setBranch] = useState("");
  const [nodesToUse, setNodesToUse] = useState(null);
  const [nodesWithChildren, setNodesWithChildren] = useState(null);
  const [nodeAwaitingDelete, setNodeAwaitingDelete] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [branchDialogIsOpen, setBranchDialogIsOpen] = useState(false);
  const [filterPositionDialogIsOpen, setFilterPositionDialogIsOpen] = useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [statsDialogIsOpen, setStatsDialogIsOpen] = useState(false);
  const [title, setTitle] = useState("Organigrama");
  const [filterData, setFilterData] = useState(filtersDummyData);
  const [filtersDialogIsOpen, setFiltersDialogIsOpen] = useState(false);
  const [filterMode, setFilterMode] = useState("");
  const [highlightData, setHighlightData] = useState(highlightDummyData);
  const [positionOccupation, setPositionOccupation] = useState(null);

  const orgChart = useSelector(getOrgChart);
  const branches = useSelector(getBranches);
  const color = useSelector(getAppColor);
  const gridTheme = useSelector(getTheme);
  const peopleOrgChart = useSelector(getPeopleOrgChart);
  const loading = useSelector(getLoading);
  const positionClassifications = useSelector(getPositionClassifications);
  const positionOccupationData = useSelector(getPositionOccupationData);
  const workplaces = useSelector(getWorkplaces);
  const management = useSelector(getManagement);

  useEffect(() => {
    dispatch(getOrgChartAction(branch));
  }, [dispatch, branch])

  useEffect(() => {
    let nodes = orgChart.map(member => {
      // Me fijo si en el array de personas hay algun elemento correspondiente a este puesto
      const elements = peopleOrgChart.filter(el => el["position"] === member["Nombre del cargo"]);
      let picture = null;

      let employeeName = "";
      if (elements.length > 0) {
        employeeName = elements[0]["name"];

        if (elements[0]["picture"]) {
          picture = IMAGES_ENDPOINT + elements[0]["picture"]
        }
        if (elements.length > 1) {
          employeeName += ` (+${elements.length - 1})`
          picture = GROUP_IMAGE_ORGCHART_ENDPOINT;
        }
      } else {
        employeeName = "(Sin ocupante)";
      }
      let data = {
        id: member["ID"],
        pid: member["ID del cargo superior"],
        "ID del cargo": member["ID"],
        "ID del cargo superior": member["ID del cargo superior"],
        "Nombre del cargo": member["Nombre del cargo"],
        "Cantidad de puestos": member["Cantidad de puestos"],
        "Puestos ocupados": member["Puestos ocupados"],
        "Puestos libres": member["Puestos libres"],
        "Grado de responsabilidad": member["Grado de responsabilidad"],
        "Etiquetas": member["Etiquetas"],
        resume: `${member["Cantidad de puestos"]}/${member["Puestos ocupados"]}/${member["Puestos libres"]}`,
        employeeName,
        employees: elements.map(el => el.name).join(";"),
        children: elements,
        picture
      }

      if (member["Asistente"] === "Y") {
        data.tags = ["assistant"]
      }

      return data;
    });

    setNodesToUse(nodes);

    const parents = [...new Set(nodes.map(el => el["ID del cargo superior"]))];
    const orgchartNodesWithChildren = nodes.filter(el => parents.includes(el["id"])).sort((a, b) => a.employeeName.localeCompare(b.employeeName));
    setNodesWithChildren(orgchartNodesWithChildren);

  }, [orgChart, peopleOrgChart])

  function removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
    })
  }
  const updateFilteredDataObject = (filterData) => {
    let filteredData = [...orgChart];

    if (responsabilityLevelSelect.includes(filterData["Grado de responsabilidad"])) {
      const elements = responsabilityLevelSelect.slice(0, responsabilityLevelSelect.indexOf(filterData["Grado de responsabilidad"]) + 1);
      filteredData = filteredData.filter(row => elements.includes(row["Grado de responsabilidad"]));
    }

    if (filterData["Desde un colaborador con personal a cargo"]) {
      const element = orgChart.find(row => row["ID"] === filterData["Desde un colaborador con personal a cargo"]);

      if (element) {
        let filteredElements = [];
        // Pongo la raiz
        filteredElements.push(element);
        let childrenCount = filteredElements.reduce((acc, node) => acc + orgChart.filter(row => row["ID del cargo superior"] === node["ID"]).length, 0);

        while (filteredElements.length - 1 < childrenCount) {
          let newElements = [];

          for (let i = 0; i < filteredElements.length; i++) {
            // eslint-disable-next-line no-loop-func
            newElements = [...newElements, ...orgChart.filter(row => row["ID del cargo superior"] === filteredElements[i]["ID"])];
          }

          filteredElements = [...filteredElements, ...newElements];
          childrenCount = filteredElements.reduce((acc, node) => acc + orgChart.filter(row => row["ID del cargo superior"] === node["ID"]).length, 0);
        }

        filteredData = removeDuplicates(filteredElements, "ID");
      }
    }

    const nodes = filteredData.map(member => {
      // Me fijo si en el array de personas hay algun elemento correspondiente a este puesto
      const elements = peopleOrgChart.filter(el => el["position"] === member["Nombre del cargo"]);
      let picture = null;

      let employeeName = "";
      if (elements.length > 0) {
        employeeName = elements[0]["name"];

        if (elements[0]["picture"]) {
          picture = IMAGES_ENDPOINT + elements[0]["picture"]
        }
        if (elements.length > 1) {
          employeeName += ` (+${elements.length - 1})`
          picture = GROUP_IMAGE_ORGCHART_ENDPOINT;
        }
      } else {
        employeeName = "(Sin ocupante)";
      }
      let data = {
        id: member["ID"],
        pid: member["ID del cargo superior"],
        "ID del cargo": member["ID"],
        "ID del cargo superior": member["ID del cargo superior"],
        "Nombre del cargo": member["Nombre del cargo"],
        "Cantidad de puestos": member["Cantidad de puestos"],
        "Puestos ocupados": member["Puestos ocupados"],
        "Puestos libres": member["Puestos libres"],
        "Grado de responsabilidad": member["Grado de responsabilidad"],
        "Etiquetas": member["Etiquetas"],
        resume: `${member["Cantidad de puestos"]}/${member["Puestos ocupados"]}/${member["Puestos libres"]}`,
        employeeName,
        employees: elements.map(el => el.name).join(";"),
        children: elements,
        picture
      }

      if (member["Asistente"] === "Y") {
        data.tags = ["assistant"]
      }

      return data;
    });
    setNodesToUse(nodes);

    setFiltersDialogIsOpen(false);
  }

  useEffect(() => {
    if (positionOccupation !== null) {
      dispatch(getPositionOccupation(positionOccupation.id))
        .then(result => result.data.status === "OK" && setStatsDialogIsOpen(true));
    }
  }, [dispatch, positionOccupation])

  useEffect(() => {
    dispatch(getClientBranchesAction());
  }, [dispatch])

  useEffect(() => {
    if (!dialogIsOpen) {
      if (branch) {
        const foundBranch = branches.find(b => b.code === branch);
        if (foundBranch) {
          setTitle(`Organigrama: ${foundBranch["name"]}`)
        }
      } else {
        setTitle(`Organigrama`)
      }
    } else {
      setTitle(`Organigrama`)
    }
  }, [dialogIsOpen, branch, branches])

  useEffect(() => {
    if (branches.length > 1) {
      setBranchDialogIsOpen(true)
    } else if (branches.length === 1) {
      setBranch(branches[0].code)
    }
  }, [branches])

  const highlightPositions = (elements) => {
    setHighlightData({ ...highlightData, [filterMode]: elements });
    setFilterPositionDialogIsOpen(false);
    setFilterMode("");
  }

  const isNumeric = (str) => {
    if (str === 0) return true
    if (str === "0") return true
    if (typeof str === "number") return true
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  const handleChangeField = (key, text) => {
    if (key === "Cantidad de puestos") {
      //Limito el input!
      if (text.length > 4) {
        return;
      }
    }

    if (key === "Cantidad de puestos" && !isNumeric(text)) {
      showSnackbarError("Ingrese un valor numérico válido.")
      return;
    }


    let modifiedNode = JSON.parse(JSON.stringify(currentNode));
    if (key === "Asistente") {
      if (modifiedNode.hasOwnProperty("tags")) {
        if (text) {
          modifiedNode.tags.push("assistant")
        } else {
          delete modifiedNode.tags;
        }
      } else if (text) {
        modifiedNode.tags = ["assistant"]
      }
    } else {
      modifiedNode[key] = text;
    }

    if (key === "Cantidad de puestos") {
      modifiedNode["Puestos libres"] = parseInt(text) - (parseInt(currentNode["Puestos ocupados"]) || 0);

      if (!currentNode["Puestos ocupados"] && currentNode["Puestos ocupados"] !== 0) {
        modifiedNode["Puestos ocupados"] = 0;

      }
    }

    if (key === "Etiquetas") {
      modifiedNode["Etiquetas"] = text;
    }
    setCurrentNode(modifiedNode)
  }

  const closeConfirmActionDialog = () => {
    setConfirmDialogIsOpen(false)
  }

  const setChartRef = (chart) => {
    chartRef.current = chart;
  }

  const deleteNode = () => {
    if (chartRef.current) {
      let oldNode = chartRef.current.get(nodeAwaitingDelete);
      if (oldNode) {
        chartRef.current.removeNode(nodeAwaitingDelete);
        setNodeAwaitingDelete(null)
      }
    }
  }

  const handleOpenHighlightManagementDialog = () => {
    setFilterMode("management");
    setFilterPositionDialogIsOpen(true);
  }

  const handleOpenHighlightWorkplaceDialog = () => {
    setFilterMode("workplace");
    setFilterPositionDialogIsOpen(true);
  }

  const handleCloseFilterPositionDialog = () => {
    setFilterPositionDialogIsOpen(false);
    setFilterMode("");
  }

  const cancelRefClick = () => {
    setDialogIsOpen(false)
  };

  const closeRefClick = () => {
    setDialogIsOpen(false)
    setCurrentNode(null)
  }


  const showSnackbarError = useCallback((msg) => {
    dispatch(openSnackbarAction({ msg: msg, severity: "error" }));
  }, [dispatch])


  const handleGoBack = () => {
    setBranch("")
    setBranchDialogIsOpen(true)
  }

  const handleCloseStatisticsDialog = () => {
    setStatsDialogIsOpen(false)
    setPositionOccupation(null)
  }
  const hasFilterData = filterData["Grado de responsabilidad"] !== "";

  return (
    <CommonPage
      title={title}
      gridTitle={"Organigrama"}
      isNotGridPage
      isLoading={loading}
    >
      {
        branches.length > 1 &&
        <DialogBranches
          branches={branches}
          setBranch={setBranch}
          branch={branch}
          open={branchDialogIsOpen}
          setBranchDialogIsOpen={setBranchDialogIsOpen}
        />
      }
      <div style={{ height: '100%' }}>
        {
          !loading && !branchDialogIsOpen &&
          <OwnOrgChart
            nodes={nodesToUse}
            color={color}
            setDialogIsOpen={setDialogIsOpen}
            setCurrentNode={setCurrentNode}
            setChartRef={setChartRef}
            handleGoBack={handleGoBack}
            hasMultipleBranches={branches.length > 1 ? true : false}
            setPositionOccupation={setPositionOccupation}
            theme={gridTheme}
            setFiltersDialogIsOpen={setFiltersDialogIsOpen}
            hasFilterData={hasFilterData}
            handleOpenHighlightManagementDialog={handleOpenHighlightManagementDialog}
            handleOpenHighlightWorkplaceDialog={handleOpenHighlightWorkplaceDialog}
            highlightData={highlightData}
          />
        }
      </div>
      <ConfirmActionDialog
        open={confirmDialogIsOpen}
        nodeId={nodeAwaitingDelete}
        handleClose={closeConfirmActionDialog}
        deleteNode={deleteNode}
      />
      {
        dialogIsOpen &&
        <DetailsEditDialog
          dialogIsOpen={dialogIsOpen}
          handleClose={() => setDialogIsOpen(false)}
          currentNode={currentNode}
          handleChangeField={handleChangeField}
          cancelRefClick={cancelRefClick}
          closeRefClick={closeRefClick}
          positionClassifications={positionClassifications}
          color={color}
        />
      }
      <StatisticsGridDialog
        title={title}
        open={statsDialogIsOpen}
        statistics={orgChart}
        chart={chartRef.current}
        positionOccupation={positionOccupation}
        positionOccupationData={positionOccupationData}
        setPositionOccupation={setPositionOccupation}
        setStatsDialogIsOpen={setStatsDialogIsOpen}
        handleClose={handleCloseStatisticsDialog}
        positionClassifications={positionClassifications}
      />
      {filterPositionDialogIsOpen && <FilterPositionDialog
        open={filterPositionDialogIsOpen}
        handleClose={handleCloseFilterPositionDialog}
        handleSave={highlightPositions}
        selects={{ workplace: workplaces, management }}
        filterMode={filterMode}
        highlightData={highlightData}
      />}
      {
        filtersDialogIsOpen &&
        <FiltersDialog
          nodesWithChildren={nodesWithChildren}
          open={filtersDialogIsOpen}
          data={filterData}
          selects={{ responsabilityLevelSelect }}
          setFilterData={setFilterData}
          handleClose={() => setFiltersDialogIsOpen(false)}
          handleConfirm={updateFilteredDataObject}
        />
      }
    </CommonPage>
  );
}

export default PositionEditor;