import React from "react";
import paths from "@icarius-localization/paths";
import { DialogTitle } from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import GridMenu from "@icarius-common/gridMenu";

const StatisticsGridDialogContent = (props) => {

  const {
    data,
    dateFormat,
    isStatistics,
    subtitle,
  } = props;

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginTop: 10, marginBottom: 10 }}>
        {
          subtitle &&
          <DialogTitle style={{ padding: 0 }}>
            {subtitle}
          </DialogTitle>
        }
        <div style={{ display: 'flex' }}>
          <GridMenu
            hasHelp
          />
        </div>
      </div>
      <CommonPage
        gridTitle={"Estadistica de cargo"}
        columnDefPage={!isStatistics ? "organizationOccupation" : paths.organizationStructure}
        rowData={data}
        dateFormat={dateFormat}
        gridHeight={'75vh'}
        ignoreProcessesbyPath
      />
    </>
  );
}

export default StatisticsGridDialogContent;
