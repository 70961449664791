import * as actionTypes from "./actionTypes";
import {
  getOrganizationalBenefitsAPI,
  deleteOrganizationalBenefitsAPI,
  createOrganizationalBenefitsAPI,
  modifyOrganizationalBenefitsAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'El plan de reconocimiento ingresado ya está asociado al tipo de beneficio seleccionado';
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar el registro porque está en uso';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getOrganizationalBenefitsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getOrganizationalBenefitsAPI();

    let data = response.data && response.data.data;
    let typeBenefitList = response.data && response.data.typeBenefitList;
    let typeFuncList = response.data && response.data.typeFuncList;
    let functionList = response.data && response.data.functionList;
    let positionList = response.data && response.data.positionList;
    let laborUnionList = response.data && response.data.laborUnionList;
    let categoryList = response.data && response.data.categoryList;
    let specialityList = response.data && response.data.specialityList;
    let workPlaceList = response.data && response.data.workPlaceList;
    let managementList = response.data && response.data.managementList;
    let workOrTaskList = response.data && response.data.workOrTaskList;
    let costCenterList = response.data && response.data.costCenterList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        typeBenefitList,
        typeFuncList,
        functionList,
        positionList,
        laborUnionList,
        categoryList,
        specialityList,
        workPlaceList,
        managementList,
        workOrTaskList,
        costCenterList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteOrganizationalBenefitsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteOrganizationalBenefitsAPI(dataToSend);

    let data = response.data && response.data.data;
    let typeBenefitList = response.data && response.data.typeBenefitList;
    let typeFuncList = response.data && response.data.typeFuncList;
    let functionList = response.data && response.data.functionList;
    let positionList = response.data && response.data.positionList;
    let laborUnionList = response.data && response.data.laborUnionList;
    let categoryList = response.data && response.data.categoryList;
    let specialityList = response.data && response.data.specialityList;
    let workPlaceList = response.data && response.data.workPlaceList;
    let managementList = response.data && response.data.managementList;
    let workOrTaskList = response.data && response.data.workOrTaskList;
    let costCenterList = response.data && response.data.costCenterList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: {
        data,
        typeBenefitList,
        typeFuncList,
        functionList,
        positionList,
        laborUnionList,
        categoryList,
        specialityList,
        workPlaceList,
        managementList,
        workOrTaskList,
        costCenterList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Registro eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createOrganizationalBenefitsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createOrganizationalBenefitsAPI(dataToSend);

    let data = response.data && response.data.data;
    let typeBenefitList = response.data && response.data.typeBenefitList;
    let typeFuncList = response.data && response.data.typeFuncList;
    let functionList = response.data && response.data.functionList;
    let positionList = response.data && response.data.positionList;
    let laborUnionList = response.data && response.data.laborUnionList;
    let categoryList = response.data && response.data.categoryList;
    let specialityList = response.data && response.data.specialityList;
    let workPlaceList = response.data && response.data.workPlaceList;
    let managementList = response.data && response.data.managementList;
    let workOrTaskList = response.data && response.data.workOrTaskList;
    let costCenterList = response.data && response.data.costCenterList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: {
        data,
        typeBenefitList,
        typeFuncList,
        functionList,
        positionList,
        laborUnionList,
        categoryList,
        specialityList,
        workPlaceList,
        managementList,
        workOrTaskList,
        costCenterList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Registro creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};
export const modifyOrganizationalBenefitsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyOrganizationalBenefitsAPI(dataToSend);

    let data = response.data && response.data.data;
    let typeBenefitList = response.data && response.data.typeBenefitList;
    let typeFuncList = response.data && response.data.typeFuncList;
    let functionList = response.data && response.data.functionList;
    let positionList = response.data && response.data.positionList;
    let laborUnionList = response.data && response.data.laborUnionList;
    let categoryList = response.data && response.data.categoryList;
    let specialityList = response.data && response.data.specialityList;
    let workPlaceList = response.data && response.data.workPlaceList;
    let managementList = response.data && response.data.managementList;
    let workOrTaskList = response.data && response.data.workOrTaskList;
    let costCenterList = response.data && response.data.costCenterList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: {
        data,
        typeBenefitList,
        typeFuncList,
        functionList,
        positionList,
        laborUnionList,
        categoryList,
        specialityList,
        workPlaceList,
        managementList,
        workOrTaskList,
        costCenterList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Registro modificado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};