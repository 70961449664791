import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import {
  CategoryIcon,
  SpecialtyIcon,
  FamilyIcon,
  FunctionTypeIcon,
  FunctionaryTypeIcon,
  PositionsClassificationIcon,
  LaborUnionsIcon,
  PaymentTypesIcon,
  DigitalWarehouseFileTypeIcon,
  FestivitiesTableIcon,
  BenefitsCenterIcon,
  ProjectsIcon,
  ActionTypesIcon,
  HolidayTableIcon,
  GroupClassificationIcon,
  CommunicationClassificationIcon,
  FeedbackPurposeIcon,
} from "@icarius-icons/index";

const Index = ({ history }) => {

  const data = [
    {
      name: "Tipos de funcionario",
      icon: <FunctionaryTypeIcon />,
      path: paths.functionaryTypesSettings,
    },
    {
      name: "Tipos de familiares",
      icon: <FamilyIcon />,
      path: paths.familySettings,
    },
    {
      name: "Categorías",
      icon: <CategoryIcon />,
      path: paths.categoriesSettings,
    },
    {
      name: "Especialidades",
      icon: <SpecialtyIcon />,
      path: paths.specialtiesSettings,
    },
    {
      name: "Definición de funciones",
      icon: <FunctionTypeIcon />,
      path: paths.functionsSettings,
    },
    {
      name: "Clasificaciones de cargos",
      icon: <PositionsClassificationIcon />,
      path: paths.positionsClassification,
    },
    {
      name: "Sindicatos",
      icon: <LaborUnionsIcon />,
      path: paths.laborUnions,
    },
    {
      name: "Formas de pago",
      icon: <PaymentTypesIcon />,
      path: paths.paymentTypes,
    },
    {
      name: "Tipos de archivo de bodega digital",
      icon: <DigitalWarehouseFileTypeIcon />,
      path: paths.digitalWarehouseFileTypes,
    },
    {
      name: "Feriados y días festivos",
      icon: <FestivitiesTableIcon />,
      path: paths.festivitiesTable,
    },
    {
      name: "Centros de costo",
      icon: <BenefitsCenterIcon />,
      path: paths.benefitsCenter,
    },
    {
      name: "Proyectos",
      icon: <ProjectsIcon />,
      path: paths.projects,
    },
    {
      name: "Tipos de acciones y recordatorios",
      icon: <ActionTypesIcon />,
      path: paths.actionTypes,
    },
    {
      name: "Tabla de vacaciones",
      icon: <HolidayTableIcon />,
      path: paths.holidayTable,
    },
    {
      name: "Clasificación de grupos",
      icon: <GroupClassificationIcon />,
      path: paths.groupClassification,
    },
    {
      name: "Clasificación de comunicaciones",
      icon: <CommunicationClassificationIcon />,
      path: paths.communicationClassification,
    },
    {
      name: "Propósitos de feedback",
      icon: <FeedbackPurposeIcon />,
      path: paths.feedbackPurpose,
      onClick: () => {
        history.push({
          pathname: paths.feedbackPurpose,
          state: {
            from: paths.organizationalDefinitions,
          },
        });
      }
    },
  ];

  return (
    <ScreenSelector
      title={"Definición de la organización"}
      data={data}
    />
  );
}

export default Index;
