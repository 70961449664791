import React from "react";
import { DialogTitle, Dialog, DialogContent } from "@material-ui/core";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import PaperDraggable from "@icarius-common/paperDraggable";
import StatisticsGridDialogContent from "./statisticsGridDialogContent";
import DialogTransition from "@icarius-common/dialogTransition";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "95%",
  },
})(Dialog);

const TasksGridDialog = (props) => {

  const {
    open,
    statistics,
    dateFormat,
    positionOccupation,
    positionOccupationData,
    chart,
    handleClose,
    positionClassifications
  } = props;

  let data = positionOccupationData;
  const isStatistics = positionOccupation === null

  let title = JSON.parse(JSON.stringify(props.title))
  title = title.replace(/Editor/g, 'Estadística');
  let subtitle = ""

  if (positionOccupation === null) {
    let formattedData = JSON.parse(JSON.stringify(statistics))
    data = formattedData.map(el => {
      el["Asistente"] = el["Asistente"] === "Y" ? "Si" : "No";

      const cargoSuperior = formattedData.find(e => e["ID"] === el["ID del cargo superior"]);

      if (cargoSuperior) {
        el["Cargo superior"] = cargoSuperior["Nombre del cargo"] || "";
      } else {
        el["Cargo superior"] = "";
      }

      let tags = [];
      if (el["Etiquetas"] && el["Etiquetas"].length > 0) {
        el["Etiquetas"].forEach(etiq => {
          const positionElement = positionClassifications.find(e => e.key === etiq);
          if (positionElement && positionElement.value) {
            tags.push(positionElement.value)
          }
        })
      }

      el["Etiquetas"] = tags.join(", ");

      return el;
    });
  } else {
    const position = statistics.find(item => item["ID"] === positionOccupation.id);
    if (position && position["ID del cargo superior"]) {
      const parentPosition = statistics.find(item => item["ID"] === position["ID del cargo superior"]);
      if (parentPosition) {
        subtitle = `Cargo superior: ${parentPosition["Nombre del cargo"]}`
      }

      title = `${position["ID"]} - ${position["Nombre del cargo"]}`
    } else {
      title = `${positionOccupation["ID del cargo"]} - ${positionOccupation["Nombre del cargo"]}`
      const parent = chart.config.nodes.find(item => item.id === positionOccupation["pid"]);
      if (parent) {
        subtitle = `Cargo superior: ${parent["Nombre del cargo"]}`
      }
    }
  }

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <StatisticsGridDialogContent
          subtitle={subtitle}
          data={data}
          dateFormat={dateFormat}
          isStatistics={isStatistics}
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default TasksGridDialog;
