import React from "react";
import * as actionTypes from "./actionTypes";
import {
  getOnboardingProcessAssignmentAPI,
  createOnboardingProcessAssignmentAPI,
  deleteOnboardingProcessAssignmentAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { openDialogAction } from "@icarius-common/dialog/actions";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "DUPLICATED_DATA":
      errorString = "Uno de los colaboradores ingresados ya está asociado al proceso";
      break;
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getOnboardingProcessAssignmentAction = (processCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getOnboardingProcessAssignmentAPI(`?processCode=${processCode}`);

    let data = response.data && response.data.data;
    let collaboratorsList = response.data && response.data.collaboratorsList;
    let canAssign = response.data && response.data.canAssign;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        collaboratorsList,
        canAssign,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createOnboardingProcessAssignmentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createOnboardingProcessAssignmentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Registro creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    if (e.response?.data?.status === "IN_USE_ONBOARDING") {
      dispatch(openDialogAction({
        maxWidth: "md",
        fullWidth: true,
        acceptOnly: true,
        title: "Atención",
        msg: (
          <>
            La asignación no se ha realizado.
            <br />
            Ha seleccionado empleados que ya se encuentran en un proceso actual de onboarding.
            <br />
            Debe omitirlos de la lista seleccionada:
            <br />
            {
              e.response?.data?.users?.map((item) => {
                return (
                  <span key={item.key}>
                    {`${item.key} ${item.value} - ${item.processName}`}
                    <br />
                  </span>
                )
              })
            }
          </>
        )
      }))
    } else {
      errorHandler(e, dispatch);
    }
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
  }
};

export const deleteOnboardingProcessAssignmentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteOnboardingProcessAssignmentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Registro eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};