import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

const CommentsIndicator = ({ commentsAmount }) => {

    return (
        <div
            style={{
                width: "100%",
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                paddingTop: 10,
                gap: 20,
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <Typography className="whiteText">
                    {commentsAmount || "0"}
                </Typography>
                <Tooltip title='Comentarios'>
                    <ChatBubbleOutlineIcon />
                </Tooltip>
            </div>
        </div>
    )
}

export default CommentsIndicator;