import {
  GET_ORGANIZATION_CHART_DATA,
  GET_ORGANIZATION_CHART_DATA_FULFILLED,
  GET_ORGANIZATION_CHART_DATA_REJECTED,
  GET_CLIENT_BRANCHES_DATA,
  GET_CLIENT_BRANCHES_DATA_FULFILLED,
  GET_CLIENT_BRANCHES_DATA_REJECTED,
  GET_POSITION_OCCUPATION_DATA,
  GET_POSITION_OCCUPATION_DATA_FULFILLED,
  GET_POSITION_OCCUPATION_DATA_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import {
  getOrgChart,
  getClientBranches,
  getPositionOccupationEndpoint,
} from "@icarius-connection/api";

export const getOrgChartAction = (branch) => async (dispatch) => {
  dispatch({ type: GET_ORGANIZATION_CHART_DATA });
  try {
    let query = ""

    if (branch !== undefined && branch !== "") {
      query = `&code=${branch}`;
    }

    let response = await getOrgChart(`?isPeopleOrgChart=true` + query);

    if (response.data.status === "OK") {
      let orgChart = response.data.result.orgchart;
      let workplaces = response.data.result.workplaces;
      let management = response.data.result.management;
      let peopleOrgChart = response.data.result.peopleOrgChart;
      let positionClassifications = response.data.result.positionClassifications;

      dispatch({
        type: GET_ORGANIZATION_CHART_DATA_FULFILLED,
        payload: {
          orgChart,
          peopleOrgChart,
          positionClassifications,
          workplaces,
          management,
        },
      });

      return response;
    } else {
      dispatch({ type: GET_ORGANIZATION_CHART_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_ORGANIZATION_CHART_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getClientBranchesAction = () => async (dispatch) => {
  dispatch({ type: GET_CLIENT_BRANCHES_DATA });
  try {
    let response = await getClientBranches();

    if (response.data.status === "OK") {
      let branches = response.data.result;

      dispatch({
        type: GET_CLIENT_BRANCHES_DATA_FULFILLED,
        payload: { branches },
      });

      return response;
    } else {
      dispatch({ type: GET_CLIENT_BRANCHES_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_CLIENT_BRANCHES_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getPositionOccupation = (node) => async (dispatch) => {
  dispatch({ type: GET_POSITION_OCCUPATION_DATA });
  try {
    let query = `?id=${node}`;
    let response = await getPositionOccupationEndpoint(query);
    if (response.data.status === "OK") {

      let positionOccupationData = response.data.result;

      dispatch({
        type: GET_POSITION_OCCUPATION_DATA_FULFILLED,
        payload: {
          positionOccupationData,
        },
      });

      return response;
    } else {
      dispatch({ type: GET_POSITION_OCCUPATION_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_POSITION_OCCUPATION_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};