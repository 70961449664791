import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.onboardingProfiles],
        config: {
            headerName: 'Código de perfil',
            field: 'code',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.onboardingProfiles],
        config: {
            headerName: 'Nombre de perfil',
            field: 'name',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProfiles],
        config: {
            headerName: 'Comentario',
            field: 'comment',
            filter: "agTextColumnFilter",
        }
    },
]