import React from "react";
import {
  TextField,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { withStyles } from "@material-ui/core/styles";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});
const Form = (props) => {
  const { materialsList, allRows, momentList, actionTypeList, data, handleClose, classes, items, setParentFormValue, disabled } = props;
  const dispatch = useDispatch();


  const create = (dataToSend) => {
    // Inserto en el array
    const newItems = [...items, { ...dataToSend, number: items.length }];
    setParentFormValue(newItems.map((item, index) => ({ ...item, number: index + 1 })), "details");
    handleClose();
  }

  const modify = (dataToSend) => {
    // Busco segun el número
    let itemsClone = [...items];
    let elementToChange = itemsClone.findIndex(el => el.number === dataToSend.number);
    if (elementToChange > -1) {
      itemsClone[elementToChange] = dataToSend;
      setParentFormValue(itemsClone.map((item, index) => ({ ...item, number: index + 1 })), "details");
    }
    handleClose();
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(allRows, data, create, modify, openValidationError);

  const handleSubmit = () => {
    submit();
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={4} style={gridStyle}> {/* Pregunta */}
            <TextField
              disabled={disabled}
              required
              fullWidth
              margin={"none"}
              label={"Nombre del paso"}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={'label-moment'}>{'Momento'}</InputLabel>
              <Select
                value={formData.moment}
                labelId={'label-moment'}
                id={'select-moment'}
                onChange={(e) => setFormValue(e.target.value, "moment")}
                margin={"none"}
              >
                {
                  momentList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={'label-actionType'}>{'Tipo de acción'}</InputLabel>
              <Select
                value={formData.actionType}
                labelId={'label-actionType'}
                id={'select-actionType'}
                onChange={(e) => setFormValue(e.target.value, "actionType")}
                margin={"none"}
              >
                {
                  actionTypeList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.code}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, marginBottom: 70 }}> {/* Descripción */}
            <TextField
              disable={disabled}
              fullWidth
              label={'Descripción del paso'}
              variant="outlined"
              multiline
              rows={5}
              margin={"none"}
              value={formData.comment}
              onChange={(e) => setFormValue(e.target.value, "comment")}
              inputProps={{ spellCheck: 'false' }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
            />
          </Grid>
          
          <Grid container item alignItems="center" xs={8} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={'label-actionType'}>{'Materiales o recursos (Puede seleccionar más de uno a la vez)'}</InputLabel>
              <Select
                value={formData.materials}
                labelId={'label-materials'}
                id={'select-materials'}
                onChange={(e) => setFormValue(e.target.value, "materials")}
                margin={"none"}
                multiple
              >
                {
                  materialsList?.filter(material => formData.moment === material.moment || formData.actionType === material.action).map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {
            !disabled &&
            <Grid container item xs={12} justify={"flex-end"} alignItems="center" >
              <ButtonDialogAction isAccept onClick={handleSubmit} text={"Guardar paso"} />
            </Grid>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(styles)(Form);
