import { useState } from "react";
import * as yup from "yup";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "access") {
        return isCreate ? "R" : data[fieldName];
      }

      if (fieldName === "accessRequired") {
        return (isCreate || !data[fieldName]) ? "N" : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "guide",
      "action",
      "access",
      "link",
      "file",
      "poll",
      "consent",
      "accessRequired",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const codeRegex = /^[A-ZÑÁÉÍÓÚ]+[A-ZÑÁÉÍÓÚ0-9_-]*$/;
    const nameRegex = /^[a-zA-ZÑÁÉÍÓÚñáéíóú]+[a-zA-ZÑÁÉÍÓÚñáéíóú0-9_ -]*$/;

    const schema = yup.object().shape({
      code: yup.string().max(12).matches(codeRegex).required(),
      name: yup.string().max(60).matches(nameRegex).required(),
      action: yup.string().required(),
      access: yup.string().required(),
      guide: yup.string(),
      link: yup.string(),
      poll: yup.string(),
      consent: yup.string(),
      accessRequired: yup.string(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
      };

      if (!isCreate) {
        dataToSend.keepFile = Boolean(data.file && data.file === formData.file);
        dataToSend.file = data.file === formData.file ? null : formData.file;
      }

      isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    if (fieldName === "file") {
      return value ? value[0] : null;
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName),
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;