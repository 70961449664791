import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, modifyCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (['startDate', 'endDate', 'reminderDate'].includes(fieldName)) return data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : null;
      return data[fieldName] || "";
    }

    const fieldNames = [
      'startDate',
      'endDate',
      'reminderDate',
      'description',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'startDate': yup.date().required(),
      'endDate': yup.date().required(),
      'reminderDate': yup.date().nullable(),
      'description': yup.string(),
    });

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        startDate: formatDateYYYYMMDD(formData.startDate),
        endDate: formatDateYYYYMMDD(formData.endDate),
        reminderDate: formData.reminderDate ? formatDateYYYYMMDD(formData.reminderDate) : null,
      };

      modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "startDate") {
      setFormData({
        ...formData,
        [fieldName]: value,
        endDate: null,
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;