import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'typeBenefit',
      'typeFunc',
      'position',
      'function',
      'laborUnion',
      'category',
      'speciality',
      'workPlace',
      'management',
      'workOrTask',
      'costCenter',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      typeBenefit: yup.string().max(20).required(),
      typeFunc: yup.string().max(8),
      position: yup.string().max(8),
      function: yup.string().max(8),
      laborUnion: yup.string().max(8),
      category: yup.string().max(8),
      speciality: yup.string().max(8),
      workPlace: yup.string().max(8),
      management: yup.string().max(8),
      workOrTask: yup.string().max(12),
      costCenter: yup.string().max(8),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData
      };

      isCreate ? createCallback(dataToSend) : modifyCallback({ internalCode: data.internalCode, ...dataToSend });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
