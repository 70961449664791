import React from 'react';
import { Typography } from '@material-ui/core';

const StatusRenderer = ({ value }) => {

    const applyStyles = value === "Cerrado";

    return (
        <Typography
            noWrap
            style={{
                fontSize: 12,
                marginTop: 5,
                fontWeight: applyStyles ? 600 : 400,
                color: applyStyles ? 'red' : 'var(--mainText)',
            }}
        >
            {value}
        </Typography>
    );
}

export default StatusRenderer;