import { useState } from "react";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useHandleForm = (allRows, data, createCallback, modifyCallback, invalidDataCallback) => {

  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "number") {
        return isCreate ? "99" : data[fieldName];
      }

      if (fieldName === "moment") {
        return isCreate ? "SIN" : data[fieldName];
      }

      if (fieldName === "materials") {
        return isCreate ? [] : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = ["number", "name", "moment", "comment", "actionType", "materials"];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      name: yup.string().max(60).required(),
      moment: yup.string().max(6),
      comment: yup.string(),
      actionType: yup.string().max(8).required(),
      materials: yup.array(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {

    const rowCoincidence = allRows.filter(el => el.number !== data?.number && el.name === formData?.name);
    if (rowCoincidence.length > 0) {
      dispatch(openSnackbarAction({ msg: 'No puede haber pasos con nombres repetidos', severity: 'error' }));
      return false;
    }

    if (await dataIsValid()) {
      let dataToSend = formData;

      if (!isCreate) {
        dataToSend.number = formData.number;
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if ((fieldName === "moment" || fieldName === "actionType") && formData.materials.length > 0) {
      dispatch(openDialogAction({
        title: "Atención",
        msg: "Si cambia el momento o tipo de acción hay materiales o recursos vinculados al paso del Onboarding que no corresponden al tipo de momento o acción que está definiendo y serán eliminados, desea proceder de todos modos ?",
        onConfirm: () => setFormData({
          ...formData,
          [fieldName]: value,
          materials: [],
        }),
      }));

      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
