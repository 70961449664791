import React from "react";
import {
  Typography,
  Tooltip,
  MenuItem,
} from "@material-ui/core";
import ProfileImg from "@icarius-common/profileImg";
import { useSelector } from "react-redux";
import { getAppColor, getUserData } from "src/app/selectors";
import DotsMenu from "@icarius-common/dotsMenu";

const UserSection = (props) => {

  const {
    employees,
    handleSelect,
    handleViewCollaborator,
    handleAddCollaborator,
    handleDeleteCollaborator,
    handleChangeCollaboratorStatus,
  } = props;

  const color = useSelector(getAppColor);
  const { level } = useSelector(getUserData);

  return (
    <div style={{ width: "100%", overflow: "auto", paddingBottom: 5 }}>
      <div style={{ display: "flex", gap: 25, marginTop: 10 }}>
        {
          employees.map((item) => {
            return (
              <div
                key={item.key}
                style={{ display: "flex", gap: 10 }}
              >
                <ProfileImg
                  image={item.image}
                  size={40}
                />
                <div style={{ display: "flex", gap: 5 }}>
                  <Tooltip title={item.name}>
                    <div onClick={() => handleSelect(item.key)} style={{ cursor: "pointer" }}>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: item.isChecked ? 600 : 100,
                          color: item.isChecked ? color : "var(--mainText)",
                        }}
                        noWrap
                      >
                        {item.name}
                      </Typography>
                      <Typography className="whiteText" style={{ fontSize: 14 }}>
                        {item.statusName}
                      </Typography>
                    </div>
                  </Tooltip>
                  {
                    ["M", "E"].includes(level) &&
                    <DotsMenu>
                      <MenuItem onClick={() => handleViewCollaborator(item)}>
                        Ver datos del empleado
                      </MenuItem>
                      {
                        level === "E" && ["P"].includes(item.status) &&
                        <MenuItem onClick={() => handleAddCollaborator(item.key)}>
                          Asignar al control del proceso
                        </MenuItem>
                      }
                      {
                        level === "E" && ["C", "D"].includes(item.status) &&
                        <MenuItem onClick={() => handleChangeCollaboratorStatus(item.key, "P")}>
                          Quitar del control del proceso
                        </MenuItem>
                      }
                      {
                        level === "E" && ["C"].includes(item.status) &&
                        <MenuItem onClick={() => handleChangeCollaboratorStatus(item.key, "D")}>
                          Detener del control del proceso
                        </MenuItem>
                      }
                      {
                        level === "E" && ["D"].includes(item.status) &&
                        <MenuItem onClick={() => handleChangeCollaboratorStatus(item.key, "C")}>
                          Reasignar al control del proceso
                        </MenuItem>
                      }
                      {
                        level === "E" && ["C"].includes(item.status) &&
                        <MenuItem onClick={() => handleChangeCollaboratorStatus(item.key, "R")}>
                          Pasar al estado “Realizado”
                        </MenuItem>
                      }
                      {
                        level === "E" && ["P", "C", "D"].includes(item.status) &&
                        <MenuItem onClick={() => handleDeleteCollaborator(item.key)}>
                          Eliminar completamente del proceso
                        </MenuItem>
                      }
                    </DotsMenu>
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default UserSection;