import React, { useCallback, useState, useRef, useEffect } from 'react';
import OrgChart from '@balkangraph/orgchart.js';
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
    Grid, Button, FormControl, InputLabel, Select, MenuItem,
    Tooltip,
} from "@material-ui/core";
import { SaveIcon, PieChartIcon, KeyboardBackspaceIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";

const OwnOrgChart = React.memo((props) => {
    const {
        setStatsDialogIsOpen,
        setNodeAwaitingDelete,
        setCurrentNode,
        color,
        dispatch,
        setDepartmentOccupation,
        setEditMode,
        setDialogIsOpen,
        setConfirmDialogIsOpen,
        nodes,
        setChartRef,
        handleGoBack,
    } = props;

    const chartRef = useRef();
    const divRef = useRef();
    const nodeIdRef = useRef();
    const [nodeDepartmentOccupation, setNodeDepartmentOccupation] = useState(null);

    const editIcon = '<svg width="24" height="24" viewBox="0 0 528.899 528.899"><path fill="#7A7A7A" d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981 c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611 C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069 L27.473,390.597L0.3,512.69z"></path></svg>';
    const detailsIcon = '<svg width="24" height="24" viewBox="0 0 512 512"><path fill="#7A7A7A" d="M447.933,103.629c-0.034-3.076-1.224-6.09-3.485-8.352L352.683,3.511c-0.004-0.004-0.007-0.005-0.011-0.008 C350.505,1.338,347.511,0,344.206,0H89.278C75.361,0,64.04,11.32,64.04,25.237v461.525c0,13.916,11.32,25.237,25.237,25.237 h333.444c13.916,0,25.237-11.32,25.237-25.237V103.753C447.96,103.709,447.937,103.672,447.933,103.629z M356.194,40.931 l50.834,50.834h-49.572c-0.695,0-1.262-0.567-1.262-1.262V40.931z M423.983,486.763c0,0.695-0.566,1.261-1.261,1.261H89.278 c-0.695,0-1.261-0.566-1.261-1.261V25.237c0-0.695,0.566-1.261,1.261-1.261h242.94v66.527c0,13.916,11.322,25.239,25.239,25.239 h66.527V486.763z"></path><path fill="#7A7A7A" d="M362.088,164.014H149.912c-6.62,0-11.988,5.367-11.988,11.988c0,6.62,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.368,11.988-11.988C374.076,169.381,368.707,164.014,362.088,164.014z"></path><path fill="#7A7A7A" d="M362.088,236.353H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.62,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.368,11.988-11.988C374.076,241.721,368.707,236.353,362.088,236.353z"></path><path fill="#7A7A7A" d="M362.088,308.691H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.367,11.988-11.988C374.076,314.06,368.707,308.691,362.088,308.691z"></path><path fill="#7A7A7A" d="M256,381.031H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.368,11.988,11.988,11.988H256 c6.62,0,11.988-5.367,11.988-11.988C267.988,386.398,262.62,381.031,256,381.031z"></path></svg>'
    const personIcon = '<svg fill="#7A7A7A" width="24" height="24" class="MuiSvgIcon-root MuiSvgIcon-root css-zjt8k" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="PersonIcon" tabindex="-1" title="Person"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>'
    const [chart, setChart] = useState(null);
    const [orientation, setOrientation] = useState(0);

    const openStatsDialog = () => {
        setStatsDialogIsOpen(true)
    }

    const showSnackbarError = useCallback((msg) => {
        dispatch(openSnackbarAction({ msg: msg, severity: "error" }));
    }, [dispatch])


    useEffect(() => {
        chartRef.current = chart;
        setChartRef(chart)
    }, [chart, setChartRef])

    useEffect(() => {
        if (nodeDepartmentOccupation)
            setDepartmentOccupation(nodeDepartmentOccupation);
    }, [nodeDepartmentOccupation, setDepartmentOccupation])

    const showEditForm = useCallback(() => {
        setEditMode(true)
        setDialogIsOpen(true)
    }, [setEditMode, setDialogIsOpen])


    const showDetailsForm = useCallback(() => {
        setEditMode(false)
        setDialogIsOpen(true)
    }, [setEditMode, setDialogIsOpen])

    const showRemoveConfirmDialog = useCallback(((nodeId) => {
        setConfirmDialogIsOpen(true)
        setNodeAwaitingDelete(nodeId)
    }), [setConfirmDialogIsOpen, setNodeAwaitingDelete])


    const editForm = function () {
        nodeIdRef.current = null;
    };

    const editHandler = useCallback((idNode) => {
        showEditForm();
        nodeIdRef.current = idNode;
        if (chartRef.current) {

            const node = chartRef.current.get(nodeIdRef.current);
            if (node) {
                setCurrentNode(node)
            }
        }
    }, [showEditForm, setCurrentNode])


    const detailsHandler = useCallback((idNode) => {
        showDetailsForm();
        nodeIdRef.current = idNode;

        if (chartRef.current) {
            const node = chartRef.current.get(nodeIdRef.current);
            if (node) {
                setCurrentNode(node)
            }
        }
    }, [showDetailsForm, setCurrentNode])

    useEffect(() => {
        editForm.prototype.init = function (obj) {
            this.obj = obj;
        };


        editForm.prototype.show = function (idNode) {
            showEditForm();
            nodeIdRef.current = idNode;
            const node = chartRef.current.get(nodeIdRef.current);
            setCurrentNode(node)
        };

        editForm.prototype.hide = function (showldUpdateTheNode) {

        };



        const occupationHandler = (idNode) => {
            setNodeDepartmentOccupation(null)
            setNodeDepartmentOccupation(idNode)
        }



        OrgChart.SEARCH_PLACEHOLDER = "Buscar...";
        OrgChart.RES.IT_IS_LONELY_HERE_LINK = "Comienza a diagramar tu estructura!";
        OrgChart.IT_IS_LONELY_HERE = '<g transform="translate(-95, 0)" style="cursor:pointer;"  control-add="control-add"><text fill="#039be5" style="font-size: smaller;">Comienza a diagramar tu estructura!</text></g>'

        OrgChart.templates.invisibleGroup.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';
        OrgChart.templates.group.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';
        OrgChart.templates.group.nodeMenuButton = '';
        OrgChart.templates.group.min = Object.assign({}, OrgChart.templates.group);
        OrgChart.templates.group.min.imgs = "{val}";
        OrgChart.templates.group.min.img_0 = "";
        //OrgChart.templates.group.min.description = '<text width="230" text-overflow="multiline" style="font-size: 14px;" fill="#000000" x="125" y="100" text-anchor="middle">{val}</text>';
        // para que salga el circulo con la cantidad
        OrgChart.templates.ula.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
            + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';
        OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.ula);
        OrgChart.templates.myTemplate.field_0 = '<text data-width="230" data-text-overflow="multiline" style="font-size: 18px;" fill="#FFFFFF" x="125" y="55" text-anchor="middle">{val}</text>';

        if (!chart) {

            const newChart = new OrgChart(divRef.current, {
                nodes: nodes, //carga de datos
                template: "myTemplate",
                align: OrgChart.ORIENTATION,
                enableDragDrop: true,
                nodeMouseClick: OrgChart.action.none,
                nodeMenu: {
                    ocupacion: {
                        icon: personIcon,
                        text: "Ocupación",
                        onClick: occupationHandler
                    },
                    detalles: {
                        icon: detailsIcon,
                        text: "Detalles",
                        onClick: detailsHandler
                    },
                    editar: {
                        icon: editIcon,
                        text: "Editar",
                        onClick: editHandler
                    },
                    add: { text: "Agregar" },
                    remove: { text: "Eliminar" },
                },
                editUI: new editForm(),

                //el menu
                toolbar: {
                    zoom: true,
                    fit: true,
                    expandAll: true
                },

                collapse: {
                    level: 2,
                    allChildren: true
                },

                nodeBinding: {
                    field_0: "Nombre"
                },

                tags: {
                    "no-group": {
                        template: "invisibleGroup", //sin grupo                   
                    },
                    "group": {
                        template: "group", //con grupo
                    },
                    "subtree": { //config general
                        subTreeConfig: {
                            //layout: OrgChart.treeRightOffset,
                            collapse: {
                                level: 2,
                            }
                        },
                    },
                    "subtree-min": { //con min
                        min: true,
                    }
                }
            });
            setChart(newChart)

        }
    }, [chart, editHandler, detailsHandler, showEditForm, setCurrentNode, nodes])

    const handleChangeOrientation = (orientation) => {
        setOrientation(orientation);
        chart.draw(OrgChart.action.update, null, function () {
            chart.setOrientation(orientation);
        });
    }

    useEffect(() => {
        if (chart) {
            chart.on('click', function (sender, args) {
                if (args.node.tags.indexOf("group") !== -1) {
                    if (args.node.min) {
                        sender.maximize(args.node.id);
                    }
                    else {
                        sender.minimize(args.node.id);
                    }
                    chart.draw(OrgChart.action.update, null, function () {
                        chart.fit();
                    });
                    return false;
                }
                return true;
            });

            chart.on('add', function (sender, node) {
                node["Código"] = "";
                node["Nombre"] = "";
                const nodeInfo = chartRef.current.getNode(node["pid"]);
                if (nodeInfo) {
                    chart.expand(node["id"], nodeInfo.childrenIds);

                    switch (nodeInfo.level) {
                        case 0:
                            node.tags = ["Gerencia"]
                            break;
                        case 1:
                            node.tags = ["Departamento"]
                            break;
                        case 2:
                            node.tags = ["Seccion"]
                            break;
                        default:
                            node.tags = ["Cliente"]
                            break;
                    }
                }
            });

            chart.on('added', function (sender, id) {
                if (id.startsWith("_") && nodeIdRef.current !== id) {
                    showEditForm();
                    nodeIdRef.current = id;
                    if (chartRef.current) {

                        const node = chartRef.current.get(nodeIdRef.current);
                        if (node) {
                            setCurrentNode(node)
                        }
                    }
                    chart.draw(OrgChart.action.update, null, function () {
                        chart.fit();
                    });
                }
            });

            chart.nodeMenuUI.on('show', function (sender, args) {
                const node = chartRef.current.get(args.firstNodeId);
                const children = chart.config.nodes.filter(n => n && n["pid"] && n["pid"] === args.firstNodeId);
                let nodeOccupation = nodes.find(n => n["Código"] === node["Código"]);

                if (nodeOccupation === null) { nodeOccupation = { quantity: 0 } }
                if (node["Código"] === "-") {
                    delete args.menu.editar;
                    delete args.menu.remove;
                    delete args.menu.detalles;
                } else {

                    args.menu.detalles = {
                        icon: detailsIcon,
                        text: "Detalles",
                        onClick: detailsHandler
                    };

                    args.menu.editar = {
                        icon: editIcon,
                        text: "Editar",
                        onClick: editHandler
                    }

                    if (!node.tags.includes("Seccion")) {
                        args.menu.add = { text: "Agregar" }
                    } else {
                        delete args.menu.add;
                    }

                    if ((nodeOccupation && nodeOccupation.quantity !== undefined && nodeOccupation.quantity !== 0) || children.length > 0) {
                        delete args.menu.remove
                    } else {
                        if (!args.menu.hasOwnProperty("remove")) {
                            args.menu.remove = { text: "Eliminar" };
                        }
                    }
                }
            });

            const getTreeHeight = (c, n) => {
                if (n == null) {
                    return 0;
                }

                let h = 0;

                for (let i = 0; i < n.childrenIds.length; i++) {
                    h = Math.max(h, getTreeHeight(c, c.getNode(n.childrenIds[i])));
                }

                return h + 1;
            }

            const modifyChildren = (level, node, detailedNode) => {
                if (node == null) {
                    return { status: "OK" };
                }

                switch (level) {
                    case 0:
                        node.tags = ["Gerencia"]
                        break;
                    case 1:
                        node.tags = ["Departamento"]
                        break;
                    case 2:
                        node.tags = ["Seccion"]
                        break;
                    default:
                        node.tags = ["Cliente"]
                        break;
                }

                const coincidence = chart.config.nodes.find(n => (
                    n["Código"].includes("!") ? node["Código"].includes("!") ? n["Código"].substr(3) === node["Código"].substr(3) : n["Código"].substr(3) === node["Código"] : n["Código"] === node["Código"])
                    && n["id"] !== node["id"] && n["tags"].includes(node["tags"][0])
                );

                if (coincidence) {
                    return { status: "ERROR", msg: "Existe otro nodo con el mismo código." }
                }

                for (let i = 0; i < detailedNode.childrenIds.length; i++) {
                    const result = modifyChildren(level + 1, chart.get(detailedNode.childrenIds[i]), chart.getNode(detailedNode.childrenIds[i]));
                    if (result.status && result.status !== "OK") {
                        return { status: "ERROR", msg: result.msg }
                    }
                }

                chart.updateNode(node);
                return { status: "OK" };
            }

            chart.on('drop', function (sender, draggedNodeId, droppedNodeId) {
                if (draggedNodeId === "-") {
                    return false
                }

                if (droppedNodeId) {
                    const nodeInfo = chartRef.current.getNode(draggedNodeId);
                    const node = chartRef.current.get(draggedNodeId);
                    const droppedNode = chartRef.current.get(droppedNodeId);
                    if (!droppedNode.tags.includes("Seccion")) {
                        let key = "";

                        // Tengo que cambiar el tag
                        if (droppedNode["tags"].includes("Gerencia")) {
                            // Como maximo puede tener 2 niveles (el raiz y ningun hijo tiene otros hijos)
                            if (getTreeHeight(chartRef.current, nodeInfo) > 2) {
                                showSnackbarError("No se puede mover porque la altura del árbol excede el límite!")
                                return false;
                            }
                            key = "!D!"
                            node.tags = ["Departamento"]
                        } else if (droppedNode["tags"].includes("Departamento")) {
                            // Como maximo puede tener 1 niveles (la raiz)
                            if (getTreeHeight(chartRef.current, nodeInfo) > 1) {
                                showSnackbarError("No se puede mover porque la altura del árbol excede el límite!")
                                return false;
                            }
                            key = "!S!"
                            node.tags = ["Seccion"]
                        } else if (droppedNode["id"] === "-") {
                            // Como maximo puede tener 3 niveles (la raiz, el departamento y la seccion)
                            if (getTreeHeight(chartRef.current, nodeInfo) > 3) {
                                showSnackbarError("No se puede mover porque la altura del árbol excede el límite!")
                                return false;
                            }
                            key = "!G!"
                            node.tags = ["Gerencia"]
                        }
                        if (key) {
                            node["Código"] = node.id.replace(/(!G!|!D!|!S!)/, key)
                        }
                        // Tambien, tengo que fijarme si en el nuevo nivel que va a estar este nodo no tengo algun nombre repetido
                        const coincidence = chart.config.nodes.find(n => (
                            n["Código"].includes("!") ? node["Código"].includes("!") ? n["Código"].substr(3) === node["Código"].substr(3) : n["Código"].substr(3) === node["Código"] : n["Código"] === node["Código"])
                            && n["id"] !== node["id"] && n["tags"].includes(node["tags"][0])
                        );
                        if (coincidence) {
                            showSnackbarError("Ya existe un elemento con el código del nodo!")
                            return false;
                        }
                        // AHora tengo que verificar de forma recusiva los nodos hijos
                        // Si tengo algun problema, detengo todo

                        const result = modifyChildren(chartRef.current.getNode(droppedNodeId).level, node, nodeInfo)
                        if (result.status !== "OK") {
                            showSnackbarError(result.msg)
                            return false
                        }

                        chart.updateNode(node);
                        const updatedDraggedNode = chartRef.current.getNode(droppedNodeId);
                        chart.expand(droppedNodeId, updatedDraggedNode.childrenIds);


                        return true
                    }
                }

                return false
            });
            chart.on('remove', function (sender, nodeId, newPidsAndStpidsForIds) {
                showRemoveConfirmDialog(nodeId)
                return false;
            });

            if (nodes && nodes.length)
                chart.load(nodes)

            chart.draw(OrgChart.action.update, null, function () {
                chart.fit();
            });
        }
    }, [chart, orientation, detailsHandler, nodes,
        editHandler, setCurrentNode, showEditForm, showRemoveConfirmDialog, showSnackbarError, setChart])

    const showSaveConfirmDialog = () => {
        setConfirmDialogIsOpen(true)
    }
    
    return (
        <>
            <Grid container direction="row" justify="center">
                <Tooltip title={getLocalizedString("goBack")}>
                    <Button
                        height="25px"
                        style={{
                            color: color,
                            minWidth: "56px",
                            marginRight: 5,
                        }}
                        disableRipple={true}
                        variant={"outlined"}
                        onClick={handleGoBack} >
                        <KeyboardBackspaceIcon htmlColor={color} height="25px" />
                    </Button>
                </Tooltip>
                <Tooltip title={getLocalizedString("save")}>
                    <Button
                        height="25px"
                        style={{
                            color: color,
                            minWidth: "56px",
                            marginRight: 5,
                        }}
                        disableRipple={true}
                        variant={"outlined"}
                        onClick={showSaveConfirmDialog} >
                        <SaveIcon htmlColor={color} height="25px" />
                    </Button>
                </Tooltip>
                <Tooltip title={getLocalizedString("statistics")}>
                    <Button
                        height="25px"
                        style={{
                            color: color,
                            minWidth: "56px",
                            marginRight: 5,
                        }}
                        disableRipple={true}
                        variant={"outlined"}
                        onClick={openStatsDialog} >
                        <PieChartIcon htmlColor={color} height="25px" />
                    </Button>
                </Tooltip>
                <FormControl style={{ width: "350px", margin: "0px 10px" }}>
                    <InputLabel id="orientation-label">{"Orientación"}</InputLabel>
                    <Select labelId="orientation-label" value={orientation} onClick={(e) => handleChangeOrientation(e.target.value || 0)}>
                        <MenuItem className={"whiteText"} value={0}>{"Arriba"}</MenuItem>
                        <MenuItem className={"whiteText"} value={3}>{"Izquierda"}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <div id="tree" ref={divRef} style={{ maxHeight: "calc(100vh - 200px)" }}></div>
        </>
    );
})

export default OwnOrgChart;