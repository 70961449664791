import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Código del proceso',
            field: 'code',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Nombre del proceso',
            field: 'name',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Estado del proceso',
            field: 'statusString',
            filter: "agSetColumnFilter",
            cellRenderer: "StatusRenderer",
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Fecha inicio onboarding',
            field: 'startDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Fecha fin onboarding',
            field: 'endDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Perfil',
            field: 'profileString',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Total colaboradores asignados',
            field: 'onboardingAmount',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Colaboradores con estado planificado',
            field: 'plannedAmount',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Colaboradores con estado en curso',
            field: 'onCourseAmount',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Colaboradores con onboarding realizado',
            field: 'doneAmount',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Colaboradores con onboarding detenido',
            field: 'stoppedAmount',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.onboardingProcesses],
        config: {
            headerName: 'Comentarios',
            field: 'comment',
            filter: "agTextColumnFilter",
        }
    },
]