import NAME from "./constants";

export const getLoading = store => store[NAME].isLoading;
export const getData = store => store[NAME].data;
export const getTypeBenefitList = store => store[NAME].typeBenefitList;
export const getTypeFuncList = store => store[NAME].typeFuncList;
export const getPositionList = store => store[NAME].positionList;
export const getFunctionList = store => store[NAME].functionList;
export const getLaborUnionList = store => store[NAME].laborUnionList;
export const getCategoryList = store => store[NAME].categoryList;
export const getSpecialityList = store => store[NAME].specialityList;
export const getWorkPlaceList = store => store[NAME].workPlaceList;
export const getManagementList = store => store[NAME].managementList;
export const getWorkOrTaskList = store => store[NAME].workOrTaskList;
export const getCostCenterList = store => store[NAME].costCenterList;