import React, { useMemo, useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import { SearchIcon } from "@icarius-icons/index";
import { useSelector } from "react-redux";
import { getAppColor, getIsAdmin, getUserData } from "src/app/selectors";
import {
  Typography,
  Divider,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { getTheme } from "@icarius-pages/login/selectors";
import Kanban from "@icarius-common/kanban";
import useHandleUserSelection from "@icarius-common/userSelectionDialog/useHandleUserSelection";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import ConsentDialog from "@icarius-common/consentDialog";
import FeedbackFormDialog from "@icarius-pages/feed/components/form/formDialog";
import { createFeedbackPublicationAction } from "@icarius-pages/feedback/actions";
import useOnboardingTracking from "./useOnboardingTracking";
import Action from "./action";
import UserSection from "./userSection";
import EditActionDialog from "./editActionDialog";
import ProgressBar from "./progressBar";
import TreeViewDialog from "./treeViewDialog";
import ViewActionDialog from "./viewActionDialog";
import useFeedback from "./useFeedback";
import FeedbackPublication from "./feedbackPublication";
import {
  createOnboardingTrackingActionCommentsAction,
  deleteOnboardingTrackingActionCommentsAction,
  updateOnboardingTrackingActionCommentsAction,
} from "../actions";

const OnboardingTracking = ({ history }) => {

  const {
    isLoading,
    state,
    handlers,
    processList,
    momentList,
    actionTypeList,
    actionList,
    comments: actionComments,
    files,
    treeData,
    progressData,
    processCollaboratorList,
    employeeList,
    firstFetchIsDone,
    kanbanColumnDefinition,
  } = useOnboardingTracking();

  const {
    state: feedbackState,
    handlers: feedbackHandlers,
    typeList,
    modeList,
    people,
    groups,
    tasks,
    goals,
    comments: feedbackComments,
    isLoadingFeedback,
  } = useFeedback();

  const [searchValue, setSearchValue] = useState('');

  const color = useSelector(getAppColor);
  const { theme } = useSelector(getTheme);
  const { code, level } = useSelector(getUserData);
  const isAdmin = useSelector(getIsAdmin);

  const title = level === "E" ? "Seguimiento de onboarding" : "Mis tareas de onboarding";

  const processObject = processList.find((item) => item.key === state.selectedProcess);

  const getProgressValue = (progressObject) => {
    if (progressObject && progressObject.amountTotal > 0) return progressObject.amountInProgress * 100 / progressObject.amountTotal;
    return null;
  }

  const processProgressValue = getProgressValue(progressData?.processProgress);
  const momentProgressValue = getProgressValue(progressData?.momentProgress);

  const filteredProcessCollaboratorList = useMemo(() => {
    // filtrar segun campo de busqueda
    if (!searchValue) return processCollaboratorList;

    return [...processCollaboratorList].filter((item) => {
      return item.name && item.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [processCollaboratorList, searchValue])

  const {
    keysSelected,
    checkedList,
    handleSelection,
    resetUsersSelected,
  } = useHandleUserSelection(filteredProcessCollaboratorList, [], true);

  const isLoadingEverything = isLoading || isLoadingFeedback.gridData || isLoadingFeedback.privacyData || isLoadingFeedback.feed;

  const getActionIsReadOnly = (action) => {
    if (!processObject?.active) return true; // si el proceso esta cerrado
    if (!isAdmin && action.responsibleCode !== code) return true; // si no es admin ni responsable

    const actionCollaborator = processCollaboratorList.find((item) => item.key === action.collaboratorCode);
    if (["D", "R"].includes(actionCollaborator?.status)) return true; // si el user de la task esta detenido o realizado

    return false;
  }

  const getAllowedDestinationColumns = (actionCode) => {
    const action = actionList.find((item) => item.code === actionCode);
    if (!action) return [];

    // si no tiene responsable y no está en Realizado, permitir mover a No necesario
    if (!action.responsibleCode && action.status !== "R") return [action.status, "N"];

    if (action.status === "P") return ["P", "C", "N"];
    if (action.status === "C") return ["P", "C", "R", "N"];
    return [];
  }

  const renderPieceContent = ({ data }) => {
    // no mostrar si no es del usuario seleccionado o del tipo de accion seleccionado
    if (keysSelected.length && !keysSelected.includes(data.collaboratorCode)) return null;
    if (state.selectedActionType && data.type !== state.selectedActionType) return null;
    return (
      <Action
        data={data}
        collaboratorList={processCollaboratorList}
        responsibleOptionsList={employeeList}
        handleClick={handlers.handleOpenViewActionDialog}
        handleEdit={handlers.handleOpenEditActionDialog}
        handleDelete={handlers.handleDeleteAction}
        handleReplicate={handlers.handleReplicateAction}
        handleAssignResponsible={handlers.handleAssignResponsible}
        handleOpenMaterial={handlers.handleOpenMaterial}
        readOnly={getActionIsReadOnly(data)}
      />
    )
  }

  if (firstFetchIsDone && ["C", "M"].includes(level) && processList.length === 0) {
    return (
      <CommonPage
        isLoading={isLoadingEverything}
        title={title}
        isNotGridPage
      >
        <Typography
          className="whiteText"
          variant="h2"
          style={{
            fontSize: 32,
            fontWeight: 700,
            margin: "150px auto",
            maxWidth: 900,
            textAlign: "center",
          }}
        >
          {"Usted no tiene en estos momentos tareas de Onboarding donde este definido como “Responsable de su atención”"}
        </Typography>
      </CommonPage>
    )
  }

  return (
    <CommonPage
      isLoading={isLoading}
      title={title}
      isNotGridPage
    >
      <div style={{ padding: 24, paddingTop: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 20, minHeight: 60 }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', gap: 10 }}>
            <div style={{ minWidth: 300, maxWidth: 300 }}>
              <FormControl fullWidth style={{ width: "100%" }}>
                <InputLabel required id={`label-process`}>{"Proceso"}</InputLabel>
                <Select
                  value={state.selectedProcess}
                  labelId={`label-process`}
                  id={`select-process`}
                  onChange={(e) => {
                    resetUsersSelected();
                    handlers.handleChangeProcess(e.target.value)
                  }}
                  margin={"none"}
                >
                  {
                    processList?.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            {
              level === "E" &&
              Boolean(processObject && processObject?.startDate && processObject?.endDate) &&
              <>
                <div>
                  <Typography className="whiteText" style={{ fontWeight: 600 }}>
                    {`Perfil: ${processObject?.profileName}`}
                  </Typography>
                  <Typography className="whiteText">
                    {`Desde el ${processObject?.startDate || ''} hasta el ${processObject?.endDate || ''}`}
                  </Typography>
                </div>
                <Tooltip
                  title={`Progreso total del proceso: ${formatNumberExactDecimals(processProgressValue, 2)}% (${progressData?.processProgress.amountInProgress} de ${progressData?.processProgress.amountTotal})`}
                  arrow
                >
                  <div style={{ minWidth: 125 }}>
                    <ProgressBar value={processProgressValue} />
                  </div>
                </Tooltip>
              </>
            }
          </div>
          <div style={{ display: 'flex', justifyContent: "flex-end", marginLeft: "auto" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.filterActiveProcesses}
                  onChange={() => {
                    resetUsersSelected();
                    handlers.handleFilterActiveProcesses();
                  }}
                  value={state.filterActiveProcesses}
                  color="primary"
                />
              }
              label={state.filterActiveProcesses ? 'Procesos activos' : 'Todos los procesos'}
              labelPlacement="start"
              style={{ margin: 0 }}
            />
            <CustomIconButton
              title={getLocalizedString("goBack")}
              onClick={() => history.push(paths.onboardingScreenSelector)}
              type={"goBack"}
            />
            {
              level === "E" &&
              <CustomIconButton
                title={"Ver árbol del proceso"}
                onClick={handlers.handleOpenTreeDialog}
                type={"preview"}
              />
            }
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <Divider style={{ margin: "20px 0px", background: color, height: 1 }} />
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "flex-end", flexWrap: "wrap", gap: 10 }}>
            {
              state.selectedProcess &&
              <>
                <div style={{ minWidth: 300, maxWidth: 300 }}>
                  <FormControl fullWidth style={{ width: "100%" }}>
                    <InputLabel required id={`label-moment`}>{"Momento"}</InputLabel>
                    <Select
                      value={state.selectedMoment}
                      labelId={`label-moment`}
                      id={`select-moment`}
                      onChange={(e) => handlers.handleChangeMoment(state.selectedProcess, e.target.value)}
                      margin={"none"}
                    >
                      {
                        momentList?.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}
                          >
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
                <div style={{ minWidth: 300, maxWidth: 300 }}>
                  <FormControl fullWidth style={{ width: "100%" }}>
                    <InputLabel shrink id={`label-actionType`}>{"Tipo de acción"}</InputLabel>
                    <Select
                      value={state.selectedActionType}
                      labelId={`label-actionType`}
                      id={`select-actionType`}
                      onChange={(e) => handlers.handleChangeActionType(e.target.value)}
                      margin={"none"}
                      displayEmpty
                    >
                      <MenuItem className={"whiteText"} value={""}>
                        {"Todos"}
                      </MenuItem>
                      {
                        actionTypeList?.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}
                          >
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
              </>
            }
            {
              Boolean(filteredProcessCollaboratorList.length) && state.selectedMoment &&
              <div style={{ minWidth: 300, maxWidth: 300 }}>
                <TextField
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    resetUsersSelected();
                  }}
                  placeholder={"Buscar colaborador"}
                  style={{ borderBottom: `1px solid ${theme === "dark" ? "#747474" : "#949494"}` }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </div>
            }
            {
              Boolean(progressData?.momentProgress) && state.selectedMoment &&
              <Tooltip
                title={`Progreso del momento: ${formatNumberExactDecimals(momentProgressValue, 2)}% (${progressData?.momentProgress.amountInProgress} de ${progressData?.momentProgress.amountTotal})`}
                arrow
              >
                <div style={{ minWidth: 125 }}>
                  <ProgressBar value={momentProgressValue} />
                </div>
              </Tooltip>
            }
          </div>
          {
            Boolean(filteredProcessCollaboratorList.length) && state.selectedMoment &&
            <div style={{ margin: "10px 0px" }}>
              <UserSection
                employees={checkedList}
                handleSelect={handleSelection}
                handleViewCollaborator={handlers.handleViewCollaborator}
                handleAddCollaborator={handlers.handleAddCollaborator}
                handleDeleteCollaborator={handlers.handleDeleteCollaborator}
                handleChangeCollaboratorStatus={handlers.handleChangeCollaboratorStatus}
              />
            </div>
          }
          <Kanban
            data={actionList}
            columnDefinition={kanbanColumnDefinition}
            handleChange={handlers.handleChangeBoardData}
            renderPieceContent={renderPieceContent}
            getIsDisabled={(action) => getActionIsReadOnly(action) || ["N", "R"].includes(action.status)}
            getAllowedDestinationColumns={getAllowedDestinationColumns}
          />
        </div>
      </div>
      {
        Boolean(state.editDialogIsOpen) &&
        <EditActionDialog
          open={Boolean(state.editDialogIsOpen)}
          isLoading={isLoading}
          data={state.selectedAction}
          collaboratorList={processCollaboratorList}
          handleSubmit={handlers.handleUpdateIndividualAction}
          handleClose={handlers.handleCloseEditActionDialog}
        />
      }
      {
        Boolean(state.viewDialogIsOpen) &&
        <ViewActionDialog
          open={Boolean(state.viewDialogIsOpen)}
          data={state.selectedAction}
          isLoading={isLoading}
          collaboratorList={processCollaboratorList}
          employeeList={employeeList}
          comments={actionComments}
          files={files}
          createCommentAction={createOnboardingTrackingActionCommentsAction}
          updateCommentAction={updateOnboardingTrackingActionCommentsAction}
          deleteCommentAction={deleteOnboardingTrackingActionCommentsAction}
          handleOpenFeedbackForm={feedbackHandlers.handleOpenFeedbackForm}
          handleOpenFeedbackPublication={feedbackHandlers.handleOpenFeedbackPublication}
          handleClose={handlers.handleCloseViewActionDialog}
        />
      }
      {
        Boolean(state.treeDialogIsOpen) &&
        <TreeViewDialog
          open={Boolean(state.treeDialogIsOpen)}
          processName={processObject.value}
          data={treeData}
          collaboratorList={processCollaboratorList}
          handleClose={handlers.handleCloseTreeDialog}
        />
      }
      {
        Boolean(state.selectedConsent) &&
        <ConsentDialog
          open={Boolean(state.selectedConsent)}
          data={state.selectedConsent}
          handleClose={handlers.handleCloseConsentDialog}
          readOnly
        />
      }
      {
        Boolean(feedbackState.selectedFeedbackPublication) &&
        <FeedbackPublication
          isLoading={isLoadingFeedback}
          data={feedbackState.selectedFeedbackPublication}
          people={people}
          groups={groups}
          tasks={tasks}
          comments={feedbackComments}
          handleClose={feedbackHandlers.handleCloseFeedbackPublication}
        />
      }
      {
        feedbackState.feedbackFormIsOpen &&
        <FeedbackFormDialog
          open={feedbackState.feedbackFormIsOpen}
          preselectedData={{
            onboardingAction: state.selectedAction?.code,
            type: "ONB",
            reference: momentList?.find((item) => item.key === state.selectedMoment)?.value || "",
            userCodes: [state.selectedAction?.collaboratorCode]
          }}
          data={null}
          goals={goals}
          modeList={modeList}
          typeList={typeList}
          groups={groups}
          tasks={tasks}
          people={people}
          statusToCreate={'E'}
          createPublicationAction={(dataToSend) => createFeedbackPublicationAction(dataToSend, true)}
          isLoading={isLoadingEverything}
          handleClose={feedbackHandlers.handleCloseFeedbackForm}
          isFeedback
          handleAfterCreateCallback={feedbackHandlers.handleUpdateAfterCreatePublication}
        />
      }
    </CommonPage>
  )
}

export default OnboardingTracking;