import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import Form from "./form";

const CreateEditForm = (props) => {

  const {
    allRows,
    isLoading,
    data,
    actionTypeList,
    materialsList,
    momentList,
    handleClose,
  } = props;

  const isEdit = Boolean(data);

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => handleClose(false)}
      type={"goBack"}
    />

  return (
    <CommonPage
      title={`${isEdit ? 'Editar' : 'Crear'} perfil de onboarding`}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <Form
        materialsList={materialsList}
        allRows={allRows}
        data={data}
        actionTypeList={actionTypeList}
        momentList={momentList}
        handleClose={handleClose}
      />
    </CommonPage>
  )
}
export default CreateEditForm;
