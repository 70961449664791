import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsAdmin, getUserData } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { withStyles } from "@material-ui/core/styles";
import Loader from "@icarius-common/loader";
import ProfileImg from "@icarius-common/profileImg";
import DynamicInput from "@icarius-common/dynamicInputs";
import useHandleForm from "./useHandleForm";
import { getFiles } from "../../selectors";
import ActionFileHandler from "../actionFileHandler";

const styles = () => ({
  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },
  cssFocused: {
    color: "var(--mainText) !important",
  },
  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const EditActionDialog = (props) => {

  const {
    open,
    isLoading,
    data,
    collaboratorList,
    handleSubmit,
    handleClose,
    classes,
  } = props;

  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);
  const { code } = useSelector(getUserData);
  const files = useSelector(getFiles);

  const taskCollaborator = collaboratorList.find((item) => item.key === data.collaboratorCode);
  const isResponsible = data.responsibleCode === code;

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }
  const {
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, handleSubmit, openValidationError);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div style={{ display: "flex", alignItems: "center", gap: 15, marginRight: 20 }}>
            <ProfileImg
              image={taskCollaborator.image}
              size={50}
            />
            <div>
              <Typography className="whiteText" style={{ fontSize: 20 }}>
                {taskCollaborator.name}
              </Typography>
              <Typography className="whiteText" style={{ fontSize: 18, fontWeight: 600 }}>
                {`${data.name} (${data.statusName} al ${data.statusDate})`}
              </Typography>
            </div>
          </div>
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <Grid container spacing={2}>
            <Grid container item alignItems="center" xs={12} sm={6} md={4}>
              {
                (!isResponsible && !isAdmin) ?
                  <DynamicInput
                    fieldConfig={{ type: "date" }}
                    label={'Fecha inicio'}
                    value={data.startDate}
                  />
                  :
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      disabled={!isResponsible && !isAdmin}
                      required
                      label={'Fecha inicio'}
                      value={formData.startDate}
                      onChange={(e) => setFormValue(e, 'startDate')}
                      fullWidth
                      cancelLabel={getLocalizedString("cancel")}
                      okLabel={getLocalizedString("ok")}
                      invalidDateMessage=""
                      minDateMessage=""
                      format="dd/MM/yyyy"
                      margin="none"
                    />
                  </MuiPickersUtilsProvider>
              }
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4}>
              {
                (!isResponsible && !isAdmin) ?
                  <DynamicInput
                    fieldConfig={{ type: "date" }}
                    label={'Fecha final'}
                    value={data.endDate}
                  />
                  :
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      disabled={!isResponsible && !isAdmin}
                      required
                      label={'Fecha final'}
                      value={formData.endDate}
                      onChange={(e) => setFormValue(e, 'endDate')}
                      fullWidth
                      minDate={formData.startDate}
                      cancelLabel={getLocalizedString("cancel")}
                      okLabel={getLocalizedString("ok")}
                      invalidDateMessage=""
                      minDateMessage=""
                      format="dd/MM/yyyy"
                      margin="none"
                    />
                  </MuiPickersUtilsProvider>
              }
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4}>
              {
                (!isResponsible && !isAdmin) ?
                  <DynamicInput
                    fieldConfig={{ type: "date" }}
                    label={'Fecha recordatorio'}
                    value={data.reminderDate}
                  />
                  :
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      disabled={!isResponsible && !isAdmin}
                      label={'Fecha recordatorio'}
                      value={formData.reminderDate}
                      onChange={(e) => setFormValue(e, 'reminderDate')}
                      fullWidth
                      minDate={formData.reminderDate}
                      cancelLabel={getLocalizedString("cancel")}
                      okLabel={getLocalizedString("ok")}
                      invalidDateMessage=""
                      minDateMessage=""
                      format="dd/MM/yyyy"
                      margin="none"
                    />
                  </MuiPickersUtilsProvider>
              }
            </Grid>
            <Grid container item alignItems="center" xs={12}>
              {
                (!isResponsible && !isAdmin) ?
                  <DynamicInput
                    label={'Descripción'}
                    value={data.description}
                  />
                  :
                  <TextField
                    disabled={!isResponsible && !isAdmin}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={5}
                    margin={"none"}
                    label={'Descripción'}
                    value={formData.description}
                    onChange={(e) => setFormValue(e.target.value, "description")}
                    InputLabelProps={{
                      classes: {
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      }
                    }}
                    inputProps={{ maxLength: 100, spellCheck: 'false' }}
                    helperText={"Cuando ingrese la descripción o la modifique, recuerde que para que llegue una notificación al responsable asignado, es necesario que acceda nuevamente a esta acción, pulse clic y cree un comentario"}
                  />
              }
            </Grid>
            <ActionFileHandler
              actionCode={data.code}
              files={files}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(EditActionDialog);