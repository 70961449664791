import {
  GET_ORGANIZATION_CHART_DATA,
  GET_ORGANIZATION_CHART_DATA_FULFILLED,
  GET_ORGANIZATION_CHART_DATA_REJECTED,
  GET_CLIENT_BRANCHES_DATA,
  GET_CLIENT_BRANCHES_DATA_FULFILLED,
  GET_CLIENT_BRANCHES_DATA_REJECTED,
  GET_POSITION_OCCUPATION_DATA,
  GET_POSITION_OCCUPATION_DATA_FULFILLED,
  GET_POSITION_OCCUPATION_DATA_REJECTED,
} from "./actionTypes";

const initialState = {
  orgChart: [],
  peopleOrgChart: [],
  gettingOrgChart: false,
  branches: [],
  positionClassifications: [],
  gettingBranchesData: false,
  positionOccupationData: [],
  workplaces: [],
  management: [],
  gettingPositionOccupationData: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case GET_ORGANIZATION_CHART_DATA:
      return { ...state, gettingOrgChart: true };
    case GET_ORGANIZATION_CHART_DATA_FULFILLED:
      return { ...state, workplaces: action.payload.workplaces, management: action.payload.management, orgChart: action.payload.orgChart, peopleOrgChart: action.payload.peopleOrgChart, positionClassifications: action.payload.positionClassifications, gettingOrgChart: false };
    case GET_ORGANIZATION_CHART_DATA_REJECTED:
      return { ...state, orgChart: [], peopleOrgChart: [], gettingOrgChart: false };

    case GET_CLIENT_BRANCHES_DATA:
      return { ...state, gettingBranchesData: true }
    case GET_CLIENT_BRANCHES_DATA_FULFILLED:
      return { ...state, branches: action.payload.branches, gettingBranchesData: false }
    case GET_CLIENT_BRANCHES_DATA_REJECTED:
      return { ...state, gettingBranchesData: false }

    case GET_POSITION_OCCUPATION_DATA:
      return { ...state, gettingPositionOccupationData: true };
    case GET_POSITION_OCCUPATION_DATA_FULFILLED:
      return { ...state, gettingPositionOccupationData: false, positionOccupationData: action.payload.positionOccupationData };
    case GET_POSITION_OCCUPATION_DATA_REJECTED:
      return { ...state, gettingPositionOccupationData: false };

    default:
      return state;
  }
}
