import React from 'react';
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import Input from "./input";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import NoteLabel from './noteLabel';
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";
import { GROUP_IMAGE_ORGCHART_ENDPOINT } from "@icarius-connection/endpoints";

const fieldMapper = {
  name: "Apellido y nombres",
  position: "Cargo",
  function: "Función",
  level: "Grado de responsabilidad",
  hireDate: "Fecha de contratación",
  management: "Gerencia",
  department: "Departamento",
  workplace: "Lugar de trabajo",
}

const fieldMapperEmptyPosition = {
  "Nombre del cargo": "Cargo",
  "Grado de responsabilidad": "Grado de responsabilidad",
}

const DetailsEditDialog = ({ dialogIsOpen, currentNode, closeRefClick, positionClassifications, color }) => {
  return (
    <Dialog
      open={dialogIsOpen}
      PaperComponent={PaperDraggable}
      maxWidth={currentNode && currentNode?.children?.length === 1 ? "md" : "xs"}
      fullWidth={true}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Ver detalle"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={closeRefClick} />
        <DialogContent>
          {currentNode &&
            <Grid container item direction="column" justify="center" alignItems="center">
              {
                currentNode?.children?.length > 1 || (currentNode?.children?.length === 1 && currentNode.picture) ? (
                  <>
                    <div className={"photo-container"}>
                      <img
                        src={currentNode?.children?.length > 1 ? GROUP_IMAGE_ORGCHART_ENDPOINT : currentNode.picture}
                        onError={event => event.target.src = DefaultImage}
                        alt={currentNode["name"]}
                        className={"employee-image"}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    {
                      currentNode && currentNode?.children?.length > 1 &&
                      <Typography className={"whiteText"} style={{ fontSize: '1.2rem', textDecoration: 'underline' }}>
                        {`${currentNode?.children?.length} ocupantes`}
                      </Typography>
                    }
                  </>
                ) :
                  (
                    <div
                      className={"employee-image"}
                      style={{
                        borderRadius: '50%',
                        height: 140,
                        width: 140,
                        margin: 0,
                        background: color,
                      }}
                    />
                  )
              }
            </Grid>
          }
          {
            currentNode && currentNode?.children?.length === 1 &&
            <Grid container item xs={12}>
              {
                Object.keys(fieldMapper).map(e => Object.keys(currentNode?.children[0]).includes(e) &&
                  <Grid key={e} container item alignItems="center" xs={12} sm={6} style={{ height: 75, padding: "0px 10px" }}>
                    <Input
                      fieldKey={e}
                      label={fieldMapper[e]}
                      currentNode={currentNode?.children[0]}
                      isAssistantField={false}
                    />
                  </Grid>
                )
              }
            </Grid>
          }

          {
            currentNode && (currentNode?.children?.length === 0 || currentNode?.children?.length > 1) &&
            Object.keys(fieldMapperEmptyPosition).map(e => Object.keys(currentNode).includes(e) &&
              <Input
                key={e}
                fieldKey={e}
                label={fieldMapperEmptyPosition[e]}
                currentNode={currentNode}
                isAssistantField={false}
              />
            )
          }
          {currentNode && currentNode["Etiquetas"] &&
            <Grid container item xs={12} style={currentNode?.children?.length === 1 ? { paddingLeft: 10 } : {}}>
              <div className="flex flex-wrap w-full p-4" style={{ paddingBottom: 8 }}>
                {currentNode["Etiquetas"].map((item, i) => {
                  return <NoteLabel
                    actualLabels={currentNode["Etiquetas"]}
                    labels={positionClassifications}
                    labelCode={item}
                    key={i}
                    className="mt-4 mr-4"
                    onDelete={null} />
                }
                )}
              </div>
            </Grid>
          }
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default DetailsEditDialog;