import React from "react";
import { getProgressColor } from "@icarius-utils/colors";
import { Typography } from "@material-ui/core";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const ProgressBar = ({ value }) => {
    
    if (typeof value !== "number" || isNaN(value)) return null;

    return (
        <div>
            <Typography
                className="whiteText"
                align="center"
                style={{ fontWeight: 600 }}
            >
                {`${formatNumberExactDecimals(value, 2)}%`}
            </Typography>
            <div
                style={{
                    height: 16,
                    position: 'relative',
                    marginBottom: 5,
                    marginTop: 5,
                }}
            >
                <div
                    style={{
                        zIndex: 2,
                        height: 16,
                        width: `${value}%`,
                        position: 'absolute',
                        borderRadius: 15,
                        backgroundColor: getProgressColor(value),
                    }}
                />
                <div
                    style={{
                        height: 16,
                        width: '100%',
                        position: 'absolute',
                        borderRadius: 15,
                        border: `1px solid ${getProgressColor(value)}`,
                    }}
                />
            </div>
        </div>
    );
}

export default ProgressBar;