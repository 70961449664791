import { useEffect, useState } from "react";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOnboardingProfilesAction,
  getOnboardingProfilesAction,
  getMaterialTreeAction,
} from "../actions";
import {
  getData,
  getLoading,
  getActionTypeList,
  getMomentList,
  getMaterialsList,
  getMaterialTreeData,
} from "../selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getSocietySelected, getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";

const useOnboardingProfiles = () => {

  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [treeViewDialogIsOpen, setTreeViewDialogIsOpen] = useState(false);

  const [selectedData, setSelectedData] = useState(null); //seleccionado para editar y detalle
  const [selectedTreeRow, setSelectedTreeRow] = useState(null); //seleccionado para árbol de visualización

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const actionTypeList = useSelector(getActionTypeList);
  const momentList = useSelector(getMomentList);
  const societySelected = useSelector(getSocietySelected);
  const color = useSelector(getAppColor);
  const theme = useSelector(getTheme);
  const materialsList = useSelector(getMaterialsList);
  const materialTreeData = useSelector(getMaterialTreeData);

  useEffect(() => {
    dispatch(getOnboardingProfilesAction());
  }, [dispatch])

  const handleOpenCreateDialog = () => {
    setSelectedData(null);
    setCreateDialogIsOpen(true);
  }

  const handleDelete = (document) => {
    dispatch(deleteOnboardingProfilesAction({ code: document.code }));
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: 'Si elimina un perfil de etapas de onboarding tambien se eliminarán todos los pasos que este contiene, ¿está seguro que desea eliminarlo?',
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenDuplicateDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(documents[0]);
    setDuplicateDialogIsOpen(true);
  }

  const handleCloseDuplicateDialog = (gridRef) => {
    setDuplicateDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleViewTree = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedTreeRow(documents[0]);
    dispatch(getMaterialTreeAction({ code: documents[0].code })).then(resp => resp.status === 200 && setTreeViewDialogIsOpen(true));
  }

  const handleCloseTreeViewDialog = () => {
    setTreeViewDialogIsOpen(false);
    setSelectedTreeRow(null);
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    duplicateDialogIsOpen,
    selectedData,
    theme,
    color,
    selectedTreeRow,
    societySelected,
    treeViewDialogIsOpen,
  }

  const handlers = {
    handleViewTree,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleDelete,
    handleOpenDuplicateDialog,
    handleCloseDuplicateDialog,
    handleCloseTreeViewDialog,
  }

  return {
    materialsList,
    data,
    materialTreeData,
    actionTypeList,
    momentList,
    isLoading,
    state,
    handlers,
  }
}

export default useOnboardingProfiles;