import React from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import FileInput from "@icarius-common/fileUpload/fileInput";
import LinkDownloadPollItem from "@icarius-pages/feed/components/form/components/linkDownloadPollItem";
import {
    createOnboardingTrackingActionFileAction,
    deleteOnboardingTrackingActionFileAction,
} from "@icarius-pages/onboardingTracking/actions";

const ActionFileHandler = (props) => {

    const {
        actionCode,
        files,
        readOnly,
    } = props;

    const dispatch = useDispatch();

    const handleUploadFile = (files) => {
        const dataToSend = {
            actionCode,
            file: files[0],
        }

        dispatch(createOnboardingTrackingActionFileAction(dataToSend));
    }

    const handleDeleteFile = (internalCode) => {
        dispatch(deleteOnboardingTrackingActionFileAction({ internalCode }));
    }

    return (
        <>
            {
                !readOnly &&
                <Grid container item alignItems="center" xs={12}>
                    <FileInput
                        acceptedFormats={['.jpg', '.bmp', '.png', '.jpeg', '.pdf', '.xlsx', '.xls', '.doc', '.docx']}
                        handleChange={handleUploadFile}
                    />
                </Grid>
            }
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                {
                    files?.map((file) => {
                        return (
                            <LinkDownloadPollItem
                                key={file.internalCode}
                                data={file}
                                type='file'
                                handleDelete={readOnly ? null : () => handleDeleteFile(file.internalCode)}
                            />
                        )
                    })
                }
            </div>
        </>
    );
}

export default ActionFileHandler;