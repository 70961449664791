import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";

export default [
    {
        pages: [paths.organizationStructure],
        config: {
            headerName: "Código de gerencia",
            field: "Código de gerencia",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.organizationStructure],
        config: {
            headerName: "Gerencia",
            field: "Gerencia",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.organizationStructure],
        config: {
            headerName: "Código de departamento",
            field: "Código de departamento",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.organizationStructure],
        config: {
            headerName: "Departamento",
            field: "Departamento",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.organizationStructure],
        config: {
            headerName: "Código de sección",
            field: "Código de sección",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.organizationStructure],
        config: {
            headerName: "Sección",
            field: "Sección",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["organizationOccupation"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["organizationOccupation"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["organizationOccupation"],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "Personal de planta",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["organizationOccupation"],
        config: {
            headerName: getLocalizedString("positionEditorAssistant"),
            field: "Asistente",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["organizationOccupation"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["organizationOccupation"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["organizationOccupation"],
        config: {
            headerName: getLocalizedString("section"),
            field: "Seccion",
            filter: "agSetColumnFilter",
        }
    },
]