import React from "react";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogActions, DialogContent, Grid, MenuItem } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { Select } from "mui-rff";
import { Form } from "react-final-form";
import DialogTransition from "@icarius-common/dialogTransition";

const FiltersDialog = (props) => {

  const { open, data, handleClose, handleConfirm, setFilterData, selects, nodesWithChildren } = props;

  const validateAndCreate = (filters) => {
    setFilterData(filters);
    handleConfirm(filters);
  }

  const handleChange = (field, newValues) => {
    window.setFormValue("Grado de responsabilidad", "");
    window.setFormValue("Desde un colaborador con personal a cargo", "");

    //Si deselecciono todo, setear el valor "-"
    if (newValues.length === 0) {
      window.setFormValue(field, '');
      return;
    }

    window.setFormValue(field, newValues);
  }

  return (
    <Dialog open={open} TransitionComponent={DialogTransition} PaperComponent={PaperDraggable} fullWidth maxWidth={"sm"} >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {"Filtrar organigrama"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <Form
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={data}
        onSubmit={validateAndCreate}
        render={({ handleSubmit, form, values }) => {
          window.setFormValue = form.mutators.setValue;
          return (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent style={{ paddingTop: 0 }}>
                <Grid container direction="column" style={{ maxHeight: 500, flexWrap: "nowrap" }}>
                  <Grid container direction="row" justify="center" item>
                    <Select
                      disabled={false}
                      label={"Grado de responsabilidad"}
                      name={"Grado de responsabilidad"}
                      onChange={(e) => handleChange("Grado de responsabilidad", String(e.target.value))}
                      formControlProps={{
                        margin: "normal",
                      }}>
                      <MenuItem className={"whiteText"} value={''}>
                        {'Sin filtro'}
                      </MenuItem>
                      {
                        selects.responsabilityLevelSelect.map((item) => (
                          <MenuItem
                            className={"whiteText"}
                            key={item}
                            value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid container direction="row" justify="center" item>
                    <Select
                      disabled={false}
                      label={"Desde un colaborador con personal a cargo"}
                      name={"Desde un colaborador con personal a cargo"}
                      onChange={(e) => handleChange("Desde un colaborador con personal a cargo", String(e.target.value))}
                      formControlProps={{
                        margin: "normal",
                      }}>
                      <MenuItem className={"whiteText"} value={''}>
                        {'Sin filtro'}
                      </MenuItem>
                      {
                        nodesWithChildren.map((item) => (
                          <MenuItem
                            className={"whiteText"}
                            key={item["id"]}
                            value={item["id"]}>
                            {item.employeeName}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonDialogAction onClick={handleClose} text={getLocalizedString("close")} />
                <ButtonDialogAction type="submit" isAccept text={getLocalizedString("agree")} />
              </DialogActions>
            </form>
          )
        }}
      />
    </Dialog>
  )
}

export default FiltersDialog;
