import NAME from "./constants";

export const getOrgChart = store => store[NAME].orgChart;

export const getBranches = store => store[NAME].branches;

export const getPositionClassifications = store => store[NAME].positionClassifications;

export const getLoading = store => store[NAME].gettingOrgChart || store[NAME].gettingBranchesData;

export const getPositionOccupationData = store => store[NAME].positionOccupationData;

export const getPeopleOrgChart = store => store[NAME].peopleOrgChart;

export const getWorkplaces = store => store[NAME].workplaces;

export const getManagement = store => store[NAME].management;