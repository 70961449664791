import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    Select, MenuItem, Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl, InputLabel, Grid,
} from "@material-ui/core";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";

const DialogBranches = (props) => {
    const { open, setBranchDialogIsOpen, branches, setBranch, branch } = props;

    return (
        <Dialog
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={"xs"}
            fullWidth={true}>
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {"Atención"}
                    <DialogTitleDivider />
                </DialogTitle>
                <DialogContent>
                    <Grid container justify="flex-start" alignItems="center" item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="branchLabel" required={true}>{"Seleccione la sucursal"}</InputLabel>
                            <Select
                                labelId="branchLabel"
                                fullWidth
                                onChange={(e) => setBranch(e.target.value)}
                                margin={"none"}
                                value={branch}
                            >
                                {
                                    branches.map(item => (
                                        <MenuItem
                                            className={"whiteText"}
                                            key={item.code}
                                            value={item.code}>
                                            {item.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonDialogAction
                        onClick={() => setBranchDialogIsOpen(false)}
                        isAccept
                        disabled={branch === ""}
                        text={getLocalizedString("agree")}
                    />
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default DialogBranches;

