import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { createOnboardingProcessAction, updateOnboardingProcessAction } from "@icarius-pages/onboardingProcesses/actions";
import { withStyles } from "@material-ui/core/styles";
import Loader from "@icarius-common/loader";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },
  cssFocused: {
    color: "var(--mainText) !important",
  },
  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const CreateEditDialog = (props) => {

  const {
    open,
    isLoading,
    data,
    statusList,
    profileList,
    handleClose,
    classes,
  } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createOnboardingProcessAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateOnboardingProcessAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  const fieldsAreDisabled = formData.status === "C";
  const hasCollaborators = Boolean(!isCreate && data.onboardingAmount > 0);
  const hasCollaboratorsPlanned = Boolean(!isCreate && data.plannedAmount > 0);
  const hasCollaboratorsOnCourse = Boolean(!isCreate && data.onCourseAmount > 0);

  const handleStatusChange = (value) => {
    if (value === "C" && (hasCollaboratorsPlanned || hasCollaboratorsOnCourse)) {
      dispatch(openDialogAction({
        title: "Atención",
        msg: (
          <>
            Si modifica el estado del proceso a “Cerrado”, considere que aún existen colaboradores para los cuales el proceso no se ha realizado por completo.
            <br />
            ¿Cambia el estado a “Cerrado” de todos modos?
          </>
        ),
        onConfirm: () => setFormValue(value, 'status'),
      }));
      return;
    }

    setFormValue(value, 'status');
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {`${isCreate ? "Crear" : "Modificar"} proceso de onboarding`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <Grid container>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                disabled={!isCreate}
                required
                fullWidth
                margin={"none"}
                label={'Código del proceso'}
                value={formData.code}
                onChange={(e) => setFormValue(e.target.value, "code")}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                disabled={fieldsAreDisabled}
                required
                fullWidth
                margin={"none"}
                label={'Nombre del proceso'}
                value={formData.name}
                onChange={(e) => setFormValue(e.target.value, "name")}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-status`}>
                  {"Estado"}
                </InputLabel>
                <Select
                  value={formData.status}
                  labelId={`label-status`}
                  id={`select-status`}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  margin={"none"}
                >
                  {
                    statusList?.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-profile`}>
                  {"Perfil"}
                </InputLabel>
                <Select
                  disabled={fieldsAreDisabled}
                  value={formData.profile}
                  labelId={`label-profile`}
                  id={`select-profile`}
                  onChange={(e) => setFormValue(e.target.value, 'profile')}
                  margin={"none"}
                >
                  {
                    profileList?.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled={fieldsAreDisabled || hasCollaborators}
                  required
                  label={'Fecha inicio del onboarding'}
                  value={formData.startDate}
                  onChange={(e) => setFormValue(e, 'startDate')}
                  fullWidth
                  minDate={isCreate ? new Date() : createDateFromDDMMYYYY(data.startDate)}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  minDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled={fieldsAreDisabled || hasCollaborators}
                  required
                  label={'Fecha final del onboarding'}
                  value={formData.endDate}
                  onChange={(e) => setFormValue(e, 'endDate')}
                  fullWidth
                  minDate={formData.startDate}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  minDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={5}
                margin={"none"}
                label={'Comentarios'}
                value={formData.comment}
                onChange={(e) => setFormValue(e.target.value, "comment")}
                InputLabelProps={{
                  classes: {
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ maxLength: 100, spellCheck: 'false' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(CreateEditDialog);