import { useState } from "react";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

    const isCreate = !Boolean(data);

    const createInitialData = () => {

        const getInitialFieldData = (fieldName) => {
            return (isCreate || !data[fieldName]) ? "" : data[fieldName];
        }

        const fieldNames = [
            'text',
            'htmlText',
        ];

        return fieldNames.reduce((initialData, fieldName) => {
            return ({
                ...initialData,
                [fieldName]: getInitialFieldData(fieldName)
            })
        }, {});
    }

    const [formData, setFormData] = useState(createInitialData());

    const dataIsValid = () => {
        return Boolean(formData.text);
    }

    const submit = () => {
        if (dataIsValid()) {
            let dataToSend = {
                text: formData.text,
                htmlText: formData.htmlText,
            };

            isCreate ? createCallback(dataToSend) : modifyCallback({ internalCode: data.internalCode, ...dataToSend });
            return true;
        } else {
            invalidDataCallback();
            return false;
        }
    }

    const setFormValue = (value, fieldName) => {
        if (fieldName === 'text') {
            setFormData({
                ...formData,
                text: value.text,
                htmlText: value.htmlText,
            });
            return;
        }

        setFormData({
            ...formData,
            [fieldName]: value,
        })
    }

    return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
