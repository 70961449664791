import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import CreateEditDialog from "./createEditDialog";
import useEvaluationProcessDefinition from "./useOnboardingProcesses";
import StatusRenderer from "./statusRenderer";

const OnboardingProcesses = ({ history }) => {

  const {
    data,
    statusList,
    profileList,
    isLoading,
    state,
    handlers,
  } = useEvaluationProcessDefinition();

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.onboardingScreenSelector)}
      type={"goBack"}
    />
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const assignButton = (gridRef) => (
    <CustomIconButton
      title={'Asignar colaboradores al proceso'}
      onClick={() => handlers.handleOpenAssign(gridRef)}
      type={"addPerson"}
    />
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Procesos de onboarding'}
      gridTitle={'Procesos de onboarding'}
      columnDefPage={paths.onboardingProcesses}
      rowData={data}
      menuItems={[
        goBackButton,
        createButton,
        modifyButton,
        deleteButton,
        assignButton,
      ]}
      frameworkComponents={{ StatusRenderer }}
      hasSwitch
      handleSwitchClick={handlers.handleToggleSwitch}
      switchLabelOff={"Todos los procesos"}
      switchLabelOn={"Solo procesos abiertos"}
      switchValue={state.switchValue}
      hasExpand
      hasHelp
    >
      {
        state.formIsOpen &&
        <CreateEditDialog
          open={state.formIsOpen}
          isLoading={isLoading}
          data={state.selectedData}
          statusList={statusList}
          profileList={profileList}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
    </CommonPage>
  )
}

export default OnboardingProcesses;