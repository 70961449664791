import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesAndSpecialtiesAction } from "../actions";
import { getLoading, getData } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import { useLocation } from "react-router-dom";
import paths from "@icarius-localization/paths";

export const TypeContext = React.createContext("");

const CategoriesAndSpecialties = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const type = location.pathname === paths.categoriesSettings ? "C" : "E";

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getCategoriesAndSpecialtiesAction(type));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, type])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  return (
    <TypeContext.Provider value={type}>
      <ABMLayout
        title={`Definición de ${type === "E" ? "especialidades" : "categorías"}`}
        isLoading={isLoading}
        showCreateButton={!createIsOpen}
        createButtonTitle={`Nueva ${type === "E" ? "especialidad" : "categoría"}`}
        createButtonOnClick={() => setCreateIsOpen(true)}
        showGoBack
        handleGoBack={() => history.push(paths.organizationalDefinitions)}
        showNoData={getDataIsEmpty()}
        noDataTitle={`No se encontraron ${type === "E" ? "especialidades" : "categorías"}`}
        noDataCreateTitle={`Nueva ${type === "E" ? "especialidad" : "categoría"}`}
        noDataOnClick={() => setCreateIsOpen(true)}
      >
        <Grid container item xs={12} direction="row">
          {
            createIsOpen &&
            <Grid container item xs={12} style={{ margin: "10px 0px" }}>
              <DataItem
                color={color}
                data={null}
                closeNew={() => setCreateIsOpen(false)}
              />
            </Grid>
          }
          {
            data && data.map((item) => {
              return (
                <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
                  <DataItem data={item} color={color} />
                </Grid>
              )
            })
          }
        </Grid>
      </ABMLayout>
    </TypeContext.Provider>
  )
}

export default CategoriesAndSpecialties;