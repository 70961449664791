import React from "react";
import * as actionTypes from "./actionTypes";
import {
  getOnboardingMaterialsAPI,
  createOnboardingMaterialAPI,
  updateOnboardinMaterialAPI,
  deleteOnboardingMaterialAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { openDialogAction } from "@icarius-common/dialog/actions";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "No puede existir más de un material o recurso que comparta el mismo código o nombre";
      break;
    case "IN_USE":
      errorString = "No es posible eliminar el material o recurso porque está en uso en perfiles de onboarding";
      break;
    case "IN_USE_TYPE_ACTION":
      errorString = (
        <>
          No puede cambiar el "Tipo de acción o momento" o el "Código de acción o momento" de este material o recurso, porque ya está vinculado con pasos en perfiles de onboarding.
          <br />
          Para poder cambiar la acción o momento primero elimine el material o recurso de los perfiles de Onboarding donde estén vinculados.
        </>
      );
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }

  if (e.response?.data?.status === "IN_USE_TYPE_ACTION") {
    dispatch(openDialogAction({
      title: "Atención",
      msg: errorString,
      acceptOnly: true,
      maxWidth: "md",
      fullWidth: true,
    }));
    return;
  }

  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getOnboardingMaterialsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getOnboardingMaterialsAPI();

    let data = response.data && response.data.data;
    let actionList = response.data && response.data.actionList;
    let accessList = response.data && response.data.accessList;
    let pollList = response.data && response.data.pollList;
    let consentList = response.data && response.data.consentList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        actionList,
        accessList,
        pollList,
        consentList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteOnboardingMaterialAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteOnboardingMaterialAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "El material o recurso se ha eliminado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createOnboardingMaterialAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let formData = new FormData();

    Object.keys(dataToSend).forEach((key) => {
      if (!dataToSend[key]) {
        formData.append(key, '');
        return;
      }

      formData.append(key, dataToSend[key])
    });

    let response = await createOnboardingMaterialAPI(formData);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "El material o recurso se ha creado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateOnboardinMaterialAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let formData = new FormData();

    Object.keys(dataToSend).forEach((key) => {
      if (!dataToSend[key]) {
        if (key === 'keepFile') {
          formData.append(key, JSON.stringify(dataToSend[key]));
          return;
        }

        formData.append(key, '');
        return;
      }

      formData.append(key, dataToSend[key])
    });

    let response = await updateOnboardinMaterialAPI(formData);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "El material o recurso se ha actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};