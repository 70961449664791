import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createOnboardingProfilesAction, updateOnboardingProfilesAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { getAppColor } from "src/app/selectors";
import Header from "./header";
import Details from "./details";

const Form = (props) => {
  const {
    allRows,
    data,
    materialsList,
    momentList,
    actionTypeList,
    handleClose,
  } = props;
  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const create = (dataToSend) => {
    const modifiedDataToSend = {
      ...dataToSend,
      evaluateSkills: dataToSend.evaluateSkills === "" ? false : dataToSend.evaluateSkills,
      evaluateTargets: dataToSend.evaluateTargets === "" ? false : dataToSend.evaluateTargets,
    }

    dispatch(createOnboardingProfilesAction(modifiedDataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    const modifiedDataToSend = {
      ...dataToSend,
      evaluateSkills: dataToSend.evaluateSkills === "" ? false : dataToSend.evaluateSkills,
      evaluateTargets: dataToSend.evaluateTargets === "" ? false : dataToSend.evaluateTargets,
    }

    dispatch(updateOnboardingProfilesAction(modifiedDataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(allRows, data, create, modify, openValidationError);

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12} justify="center">
          <Header
            isCreate={isCreate}
            formData={formData}
            setFormValue={setFormValue}
          />
          <Details
            materialsList={materialsList}
            formData={formData}
            setFormValue={setFormValue}
            momentList={momentList}
            actionTypeList={actionTypeList}
            color={color}
          />
        </Grid>
        <Grid container item sm={12} justify="center">
          <Grid container item sm={12} md={10} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20, paddingTop: 10, paddingRight: 10 }}>
            <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Form;
