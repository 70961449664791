import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getUserData } from "src/app/selectors";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { previewFromURL } from "@icarius-utils/download";
import DynamicInput from "@icarius-common/dynamicInputs";
import { Grid } from "@material-ui/core";
import {
  getIsLoading,
  getProcessList,
  getMomentList,
  getProcessCollaboratorList,
  getActionTypeList,
  getEmployeeList,
  getFirstFetchisDone,
  getTreeData,
  getProgressData,
  getComments,
  getFiles,
} from "../selectors";
import { RESET_FILES, RESET_COMMENTS, RESET_STATE } from "../actionTypes";
import {
  addCollaboratorOnboardingTrackingAction,
  changeCollaboratorStatusOnboardingTrackingAction,
  deleteCollaboratorOnboardingTrackingAction,
  getOnboardingTrackingAction,
  getActionsOnboardingTrackingAction,
  getCollaboratorsOnboardingTrackingAction,
  getMomentsOnboardingTrackingAction,
  updateActionsOnboardingTrackingAction,
  deleteActionOnboardingTrackingAction,
  assignActionResponsibleOnboardingTrackingAction,
  updateIndividualActionOnboardingTrackingAction,
  getTreeOnboardingTrackingAction,
  getProgressOnboardingTrackingAction,
  getOnboardingTrackingActionFilesAction,
  getOnboardingTrackingActionCommentsAction,
  replicateActionOnboardingTrackingAction,
} from "../actions";
import { COLLABORATOR_FIELDS } from "../constants";

const useOnboardingTracking = () => {

  const [selectedProcess, setSelectedProcess] = useState("");
  const [filterActiveProcesses, setFilterActiveProcesses] = useState(true);
  const [selectedMoment, setSelectedMoment] = useState("");
  const [selectedActionType, setSelectedActionType] = useState("");
  const [boardData, setBoardData] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedResponsibleCode, setSelectedResponsibleCode] = useState("");
  const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [treeDialogIsOpen, setTreeDialogIsOpen] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState(false);

  const dispatch = useDispatch();
  const { level } = useSelector(getUserData);
  const isLoading = useSelector(getIsLoading);
  const processList = useSelector(getProcessList);
  const momentList = useSelector(getMomentList);
  const actionTypeList = useSelector(getActionTypeList);
  const processCollaboratorList = useSelector(getProcessCollaboratorList);
  const treeData = useSelector(getTreeData);
  const progressData = useSelector(getProgressData);
  const employeeList = useSelector(getEmployeeList);
  const firstFetchIsDone = useSelector(getFirstFetchisDone);
  const comments = useSelector(getComments);
  const files = useSelector(getFiles);

  useEffect(() => {
    dispatch(getOnboardingTrackingAction());

    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleFetchProgressData = (process, moment) => {
    if (level !== "C") {
      dispatch(getProgressOnboardingTrackingAction(process, moment));
    }
  }

  const handleChangeProcess = (newProcess) => {
    setSelectedProcess(newProcess);
    setSelectedMoment("");
    setSelectedActionType("");
    setBoardData([]);

    dispatch(getMomentsOnboardingTrackingAction(newProcess))
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(getCollaboratorsOnboardingTrackingAction(newProcess));

          const currentMoment = resp.data.momentList.find((item) => !item.completed) || resp.data.momentList.at(-1);
          handleChangeMoment(newProcess, currentMoment.key);
        }
      });
  }

  const handleFilterActiveProcesses = () => {
    setFilterActiveProcesses(prev => !prev);
    setBoardData([]);
    setSelectedActionType("");
    setSelectedMoment("");
    setSelectedProcess("");
  }

  const handleChangeMoment = (process, newMoment) => {
    setSelectedMoment(newMoment);
    setSelectedActionType("");
    handleFetchActions(process, newMoment);
    handleFetchProgressData(process, newMoment);
  }

  const handleFetchActions = (processCode, momentCode) => {
    if (processCode && momentCode) {
      dispatch(getActionsOnboardingTrackingAction(processCode, momentCode))
        .then((resp) => {
          if (resp.status === 200) {
            setBoardData(resp.data?.actionList || []);
          }
        })
    }
  }

  const handleChangeBoardData = (newBoardData) => {
    setBoardData(newBoardData);
    dispatch(updateActionsOnboardingTrackingAction({ actions: newBoardData }))
      .then((resp) => {
        if (resp.status === 200) {
          setBoardData(resp.data?.actionList || []);
          handleFetchProgressData(selectedProcess, selectedMoment);
        }
      })
  }

  const handleChangeActionType = (newActionType) => setSelectedActionType(newActionType);

  const handleOpenViewActionDialog = (newSelectedAction) => {
    dispatch(getOnboardingTrackingActionFilesAction(newSelectedAction.code))
      .then((res) => {
        if (res?.status === 200) {
          dispatch(getOnboardingTrackingActionCommentsAction(newSelectedAction.code))
            .then((res) => {
              if (res?.status === 200) {
                setSelectedAction(newSelectedAction);
                setViewDialogIsOpen(true);
              }
            });
        }
      });
  }

  const handleCloseViewActionDialog = () => {
    setViewDialogIsOpen(false);
    setSelectedAction(null);
    dispatch({ type: RESET_FILES });
    dispatch({ type: RESET_COMMENTS });
  }

  const handleOpenEditActionDialog = (newSelectedAction) => {
    dispatch(getOnboardingTrackingActionFilesAction(newSelectedAction.code))
      .then((res) => {
        if (res?.status === 200) {
          setSelectedAction(newSelectedAction);
          setEditDialogIsOpen(true);
        }
      });
  }

  const handleCloseEditActionDialog = () => {
    setEditDialogIsOpen(false);
    setSelectedAction(null);
    setSelectedResponsibleCode("");
    dispatch({ type: RESET_FILES });
  }

  const handleAssignResponsible = (newSelectedAction, newSelectedResponsibleCode) => {
    // el responsable se guarda despues de haber ingresado las fechas en el modal del action, entonces se pone en el state y se guarda en handleUpdateIndividualAction
    setSelectedResponsibleCode(newSelectedResponsibleCode);
    handleOpenEditActionDialog(newSelectedAction);
  }

  const handleReplicateAction = (actionCode) => {
    dispatch(openDialogAction({
      title: "Atención",
      msg: (
        <>
          El responsable asignado, la descripción de la acción, y las fechas serán asignadas al resto de las acciones del mismo tipo que estén en estado <u>planificado</u>.
          <br />
          ¿Desea continuar?
        </>
      ),
      onConfirm: () => {
        dispatch(replicateActionOnboardingTrackingAction({ actionCode }))
          .then((resp) => {
            if (resp.status === 200) {
              setBoardData(resp.data?.actionList || []);
              handleFetchProgressData(selectedProcess, selectedMoment);
            }
          })
      },
    }))
  }

  const handleUpdateIndividualAction = (dataToSend) => {
    dispatch(updateIndividualActionOnboardingTrackingAction({ ...dataToSend, actionCode: selectedAction.code }))
      .then((resp) => {
        if (resp.status === 200) {
          setBoardData(resp.data?.actionList || []);

          if (selectedResponsibleCode) {
            dispatch(assignActionResponsibleOnboardingTrackingAction({
              actionCodes: [selectedAction.code],
              responsibleCode: selectedResponsibleCode,
            }))
              .then((resp) => {
                if (resp.status === 200) {
                  setBoardData(resp.data?.actionList || []);
                  handleFetchProgressData(selectedProcess, selectedMoment);
                }
              })
          }
          handleCloseEditActionDialog();
        }
      })
  }

  const handleViewCollaborator = (collaborator) => {
    dispatch(openDialogAction({
      title: collaborator.name,
      children: (
        <Grid container spacing={2}>
          {
            COLLABORATOR_FIELDS.map((item, index) => {
              return (
                <Grid item xs={6} key={index}>
                  <DynamicInput
                    label={item.label}
                    value={collaborator[item.name]}
                    readOnlyType={item.readOnlyType}
                  />
                </Grid>
              )
            })
          }
        </Grid>
      ),
      acceptOnly: true,
      acceptText: "Cerrar",
    }))
  }

  const handleAddCollaborator = (collaboratorCode) => {
    if (filteredMomentList?.length !== 1) {
      dispatch(openDialogAction({
        title: "Atención",
        msg: (
          <>
            No es posible asignar el colaborador al control del proceso, porque el proceso ya ha avanzado al 2do momento para otros colaboradores.
            <br />
            Solo pueden asignarse cuando el proceso está en el 1er momento.
            <br />
            Elimine al colaborador de este proceso y asígnelo en otro proceso de onboarding
          </>
        ),
        acceptOnly: true,
      }));
      return;
    }

    dispatch(addCollaboratorOnboardingTrackingAction({
      processCode: selectedProcess,
      collaboratorCode,
    }))
      .then((resp) => {
        if (resp.status === 200) {
          handleFetchActions(selectedProcess, selectedMoment);
          handleFetchProgressData(selectedProcess, selectedMoment);
        }
      })
  }

  const handleDeleteAction = (actionCode) => {
    dispatch(openDialogAction({
      maxWidth: "md",
      title: getLocalizedString("atention"),
      msg: "¿Está seguro que desea eliminar la acción?",
      onConfirm: () => {
        dispatch(deleteActionOnboardingTrackingAction({ actionCode }))
          .then((resp) => {
            if (resp.status === 200) {
              setBoardData(resp.data?.actionList || []);
              handleFetchProgressData(selectedProcess, selectedMoment);
            }
          })
      }
    }));
  }

  const handleDeleteCollaborator = (collaboratorCode) => {
    dispatch(openDialogAction({
      maxWidth: "md",
      title: getLocalizedString("atention"),
      msg: (
        <>
          Si elimina al colaborador del proceso de onboarding, también se eliminará:
          <br />
          Toda la trazabilidad y el detalle de su participación en el proceso.
          <br />
          Sus comentarios, adjuntos, y feedbacks recibidos durante el proceso.
          <br />
          Las acciones realizadas y las etapas por las cuales ha pasado.
          <br />
          <br />
          ¿Está seguro que desea continuar?
        </>
      ),
      onConfirm: () => {
        dispatch(deleteCollaboratorOnboardingTrackingAction({
          processCode: selectedProcess,
          collaboratorCode,
        }))
          .then((resp) => {
            if (resp.status === 200) {
              dispatch(getMomentsOnboardingTrackingAction(selectedProcess))
                .then((resp) => {
                  if (resp.status === 200) {
                    handleFetchActions(selectedProcess, selectedMoment);
                  }
                })
            }
          })
      }
    }));
  }

  const handleChangeCollaboratorStatus = (collaboratorCode, statusCode) => {
    const changeCollaboratorStatus = () => {
      dispatch(changeCollaboratorStatusOnboardingTrackingAction({
        processCode: selectedProcess,
        collaboratorCode,
        statusCode,
      }))
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(getMomentsOnboardingTrackingAction(selectedProcess))
              .then((resp) => {
                if (resp.status === 200) {
                  handleFetchActions(selectedProcess, selectedMoment);
                  handleFetchProgressData(selectedProcess, selectedMoment);
                }
              })
          }
        })
    }

    if (statusCode === "P") {
      dispatch(openDialogAction({
        maxWidth: "md",
        title: getLocalizedString("atention"),
        msg: (
          <>
            Si quita el colaborador del control del proceso de onboarding (Kanban), también se eliminará:
            <br />
            Toda la trazabilidad y el detalle de su participación en el proceso.
            <br />
            Sus comentarios, adjuntos, y feedbacks recibidos durante el proceso.
            <br />
            Las acciones realizadas y las etapas por las cuales ha pasado.
            <br />
            <br />
            Si bien permanecerá dentro del proceso, no se mantendrá el detalle de las acciones realizadas.
            <br />
            Sin embargo, se permitirá que posteriormente lo rasigne al control del proceso nuevamente.
            <br />
            <br />
            ¿Está seguro que desea continuar?
          </>
        ),
        onConfirm: changeCollaboratorStatus,
      }));

      return;
    }

    if (statusCode === "R") {
      dispatch(openDialogAction({
        maxWidth: "md",
        title: getLocalizedString("atention"),
        msg: (
          <>
            Si pasa el colaborador a la etapa “Realizado” significa que el proceso de onboarding para el colaborador ha finalizado.
            <br />
            A partir de ese estado solo podrá consultarlo, pero ya no podrá modificarlo o realizar nuevas acciones dentro del control del proceso de onboarding para dicho colaborador.
            <br />
            <br />
            ¿Está seguro que desea continuar?
          </>
        ),
        onConfirm: changeCollaboratorStatus,
      }));

      return;
    }

    changeCollaboratorStatus();
  }

  const handleOpenTreeDialog = () => {
    if (!selectedProcess) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un proceso", severity: "warning" }));
      return;
    }

    dispatch(getTreeOnboardingTrackingAction(selectedProcess))
      .then((resp) => {
        if (resp.status === 200) {
          setTreeDialogIsOpen(true);
        }
      })
  }

  const handleCloseTreeDialog = () => {
    setTreeDialogIsOpen(false);
  }

  const handleOpenMaterial = (material) => {
    const onlyGuide = Boolean(!material.poll && !material.consent && !material.link && !material.file);

    const openMaterial = () => {
      if (material.poll) {
        dispatch(openDialogAction({
          title: material.poll.name,
          msg: (
            <>
              {material.poll.text}
              <br />
              <br />
              {material.poll.isCompleted ? "Ha sido respondida." : "Aún no ha sido respondida."}
            </>
          ),
          acceptText: "Cerrar",
          acceptOnly: true,
        }));
      }

      if (material.consent) {
        setSelectedConsent(material.consent);
      }

      if (material.link) {
        previewFromURL(material.link);
      }

      if (material.file) {
        previewFromURL(RESOURCES_ENDPOINT + material.file.path)
      }
    }

    if (material.guide) {
      dispatch(openDialogAction({
        title: material.name,
        msg: material.guide,
        onConfirm: openMaterial,
        acceptText: onlyGuide ? "Cerrar" : "Acceder a material",
        acceptOnly: true,
        maxWidth: "md",
        fullWidth: true,
      }));

      return;
    }

    openMaterial();
  }

  const handleCloseConsentDialog = () => {
    setSelectedConsent(null);
  }

  const state = {
    selectedProcess,
    filterActiveProcesses,
    selectedMoment,
    selectedActionType,
    boardData,
    selectedAction,
    viewDialogIsOpen,
    editDialogIsOpen,
    treeDialogIsOpen,
    selectedConsent,
  }

  const handlers = {
    handleChangeProcess,
    handleChangeMoment,
    handleChangeActionType,
    handleChangeBoardData,
    handleFilterActiveProcesses,
    handleOpenEditActionDialog,
    handleCloseEditActionDialog,
    handleOpenViewActionDialog,
    handleCloseViewActionDialog,
    handleAddCollaborator,
    handleDeleteCollaborator,
    handleChangeCollaboratorStatus,
    handleDeleteAction,
    handleAssignResponsible,
    handleUpdateIndividualAction,
    handleReplicateAction,
    handleOpenTreeDialog,
    handleCloseTreeDialog,
    handleOpenMaterial,
    handleCloseConsentDialog,
    handleViewCollaborator,
  }

  const kanbanColumnDefinition = [
    {
      code: "P",
      name: "Planificado",
    },
    {
      code: "C",
      name: "En curso",
    },
    {
      code: "N",
      name: "No necesario",
      confirmation: (moveCallback) => {
        dispatch(openDialogAction({
          title: "Atención",
          msg: (
            <>
              Si mueve la acción a “No necesaria” tenga presente que posteriormente no podrá moverla a otro estado.
              <br />
              ¿Desea continuar?
            </>
          ),
          maxWidth: "sm",
          onConfirm: moveCallback,
        }));
      },
    },
    {
      code: "R",
      name: "Realizado",
      confirmation: (moveCallback) => {
        dispatch(openDialogAction({
          title: "Atención",
          msg: (
            <>
              Si mueve la acción a la columna Realizado es porque la tarea ha terminado.
              <br />
              Considere que no podrá moverse a otro estado posteriormente.
              <br />
              ¿Desea continuar?
            </>
          ),
          maxWidth: "md",
          onConfirm: moveCallback,
        }));
      },
    },
  ].filter((item) => {
    // si es colaborador, no mostrar columna de No Necesario
    if (level !== "C") return true;
    return item.code !== "N";
  })
    ;

  const filteredProcessList = useMemo(() => {
    if (!filterActiveProcesses) return processList;

    return processList.filter((item) => item.active);
  }, [filterActiveProcesses, processList])

  const filteredMomentList = useMemo(() => {
    // devuelvo los momentos completos y el primero sin completar
    const firstNotCompletedMomentIndex = momentList.findIndex((item) => !item.completed);
    if (firstNotCompletedMomentIndex === -1) return momentList;
    return momentList.slice(0, firstNotCompletedMomentIndex + 1);
  }, [momentList]);

  const filteredActionTypeList = useMemo(() => {
    if (!selectedMoment || !boardData?.length) return actionTypeList;

    return actionTypeList.filter((actionType) => boardData.some((action) => action.type === actionType.key));
  }, [actionTypeList, selectedMoment, boardData]);

  return {
    isLoading,
    state,
    handlers,
    processList: filteredProcessList,
    momentList: filteredMomentList,
    actionTypeList: filteredActionTypeList,
    actionList: boardData,
    comments,
    files,
    treeData,
    progressData,
    processCollaboratorList,
    employeeList,
    firstFetchIsDone,
    kanbanColumnDefinition,
  };
}

export default useOnboardingTracking;