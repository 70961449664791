import React from "react";
import {
    TextField
} from "@material-ui/core";

const Input = ({ handleChangeField, label, editMode, currentNode }) => {

    let length = 100;


    if (label === "Código") {
        length = 10
    }
    return (<TextField
        disabled={(editMode && label === "Código" && currentNode["id"].substr(0, 1) !== "_") || !editMode}
        fullWidth
        required
        margin={"none"}
        label={`${label}`}
        value={label.includes("Código") && currentNode[label].includes("!") ? `${currentNode[label].substring(3)}` : `${currentNode[label]}`}
        onChange={m => handleChangeField(label, m.target.value)}
        style={{ marginTop: 10 }}
        inputProps={{ maxLength: length }}
        type={"text"}
    />);
}

export default Input;

