import React from "react";
import {
    TextField
} from "@material-ui/core";

const Input = ({ fieldKey, label, currentNode }) => {

    let length = 100;

    if (label === "Código") {
        length = 10
    }

    return (<TextField
        disabled
        fullWidth
        margin={"none"}
        label={`${label}`}
        value={currentNode[fieldKey] || " "}
        style={{ marginTop: 10 }}
        inputProps={{ maxLength: length }}
        type={"text"}
    />);
}

export default Input;
