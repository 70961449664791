import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (['startDate', 'endDate'].includes(fieldName)) return (isCreate) ? null : createDateFromDDMMYYYY(data[fieldName]);
      if (fieldName === 'status') return isCreate ? "A" : data[fieldName];
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'code',
      'name',
      'status',
      'profile',
      'startDate',
      'endDate',
      'comment',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const codeRegex = /^([A-ZÁÉÍÓÚÑ])([A-ZÁÉÍÓÚÑ]|[0-9]|_|-|\/)*/;

    const schema = yup.object().shape({
      'code': yup.string().matches(codeRegex).required(),
      'name': yup.string().required(),
      'status': yup.string().required(),
      'startDate': yup.date().required(),
      'endDate': yup.date().required(),
      'profile': yup.string().required(),
      'comment': yup.string(),
    });

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        startDate: formatDateYYYYMMDD(formData.startDate),
        endDate: formatDateYYYYMMDD(formData.endDate),
      };

      isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "startDate") {
      setFormData({
        ...formData,
        [fieldName]: formatValue(value, fieldName),
        endDate: null,
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;