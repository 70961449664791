import React from "react";
import { useSelector } from "react-redux";
import { getIsAdmin, getUserData } from "src/app/selectors";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Chip,
  Button,
} from "@material-ui/core";
import FeedbackIcon from '@material-ui/icons/Feedback';
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import ProfileImg from "@icarius-common/profileImg";
import DynamicInput from "@icarius-common/dynamicInputs";
import CommentsIndicator from "./commentsIndicator";
import CommentList from "./comment/list";
import ActionFileHandler from "../actionFileHandler";

const ViewActionDialog = (props) => {

  const {
    open,
    data,
    isLoading,
    comments,
    files,
    collaboratorList,
    employeeList,
    createCommentAction,
    updateCommentAction,
    deleteCommentAction,
    handleOpenFeedbackForm,
    handleOpenFeedbackPublication,
    handleClose,
  } = props;

  const { code, level } = useSelector(getUserData);
  const isAdmin = useSelector(getIsAdmin);

  const isResponsible = data.responsibleCode === code;

  const taskCollaborator = employeeList.find((item) => item.key === data.collaboratorCode);
  const taskAssigner = employeeList.find((item) => item.key === data.assignerCode);
  const taskResponsible = employeeList.find((item) => item.key === data.responsibleCode);

  const mentionOptions = (() => {
    let auxMentionOptions = [...(collaboratorList || [])];

    if (taskAssigner) auxMentionOptions = [...auxMentionOptions, taskAssigner];
    if (taskResponsible) auxMentionOptions = [...auxMentionOptions, taskResponsible];

    return auxMentionOptions
      .map((item) => ({ ...item, img: item.image }))
      .sort((a, b) => -b.name?.localeCompare(a.name));
  })();

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div style={{ display: "flex", alignItems: "center", gap: 15, marginRight: 20 }}>
            <ProfileImg
              image={taskCollaborator.image}
              size={50}
            />
            <div>
              <Typography className="whiteText" style={{ fontSize: 20 }}>
                {taskCollaborator.name}
              </Typography>
              <Typography className="whiteText" style={{ fontSize: 18, fontWeight: 600 }}>
                {`${data.name} (${data.statusName} al ${data.statusDate})`}
              </Typography>
            </div>
          </div>
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingBottom: 20 }}>
          <Grid container spacing={2}>
            <Grid container item alignItems="center" xs={12} sm={6} md={4}>
              <DynamicInput
                fieldConfig={{ type: "date" }}
                label={'Fecha inicio'}
                value={data.startDate}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4}>
              <DynamicInput
                fieldConfig={{ type: "date" }}
                label={'Fecha final'}
                value={data.endDate}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4}>
              <DynamicInput
                fieldConfig={{ type: "date" }}
                label={'Fecha recordatorio'}
                value={data.reminderDate}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12}>
              <DynamicInput
                label={'Descripción'}
                value={data.description}
              />
            </Grid>
            {
              Boolean(
                (level !== "C" && data.isFeedbackEnabled && !Boolean(data.feedback)) ||
                Boolean(data.feedback)
              ) &&
              <Grid container item alignItems="center" xs={12}>
                {
                  level !== "C" && data.isFeedbackEnabled && !Boolean(data.feedback) &&
                  <div>
                    <Button
                      onClick={handleOpenFeedbackForm}
                      variant="outlined"
                      startIcon={<FeedbackIcon />}
                    >
                      {"Dar feedback"}
                    </Button>
                  </div>
                }
                {
                  Boolean(data.feedback) &&
                  <div style={{ paddingBottom: 20 }}>
                    <Chip
                      label={`Feedback: ${data.feedback.reference}`}
                      onClick={() => handleOpenFeedbackPublication(data.feedback)}
                    />
                  </div>
                }
              </Grid>
            }
            <ActionFileHandler
              actionCode={data.code}
              files={files}
              readOnly={!isResponsible && !isAdmin}
            />
            <CommentsIndicator
              // el campo data.commentsAmount no se actualiza hasta que se haga otro fetch, asi que se usa comments.length directamente
              commentsAmount={comments?.length || 0}
            />
            <CommentList
              data={comments}
              actionCode={data.code}
              people={mentionOptions}
              isLoading={isLoading}
              createCommentAction={createCommentAction}
              updateCommentAction={updateCommentAction}
              deleteCommentAction={deleteCommentAction}
            />
          </Grid>
        </DialogContent>
      </div>
    </Dialog >
  );
}

export default ViewActionDialog;