import React from "react";
import { DialogTitle, Dialog, DialogContent } from "@material-ui/core";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import PaperDraggable from "@icarius-common/paperDraggable";
import StatisticsGridDialogContent from "./statisticsGridDialogContent";
import DialogTransition from "@icarius-common/dialogTransition";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "95%",
  },
})(Dialog);

const TasksGridDialog = React.memo((props) => {

  const {
    open,
    color,
    theme,
    dateFormat,
    departmentOccupation,
    departmentOccupationData,
    statistics,
    setDepartmentOccupation,
    setStatsDialogIsOpen,
    chart
  } = props;

  const handleClose = () => {
    setStatsDialogIsOpen(false)
    setDepartmentOccupation(null)
  }
  const data = departmentOccupation !== null ? departmentOccupationData : statistics
  const isStatistics = departmentOccupation === null

  let title = "Estructura departamental de la empresa";
  let subtitle;
  if (departmentOccupation) {
    const result = chart.config.nodes.find(n => n["Código"] === departmentOccupation);
    if (result) {
      const moreInfo = chart.getNode(result["id"])
      switch (result["type"]) {
        case "Gerencia":
          subtitle = `Gerencia: ${result["Nombre"]}`
          break;
        case "Departamento":
          const parent = chart.get(moreInfo.parent["id"])
          subtitle = `Gerencia: ${parent["Nombre"]} / Departamento: ${result["Nombre"]}`
          break;
        case "Seccion":
          const departamento = chart.get(moreInfo.parent["id"])
          const departamentoPlus = chart.getNode(departamento["id"])
          const gerencia = chart.get(departamentoPlus.parent["id"])
          subtitle = `Gerencia: ${gerencia["Nombre"]} / Departamento: ${departamento["Nombre"]} / Sección: ${result["Nombre"]}`
          break;
        default:
          subtitle = ""
          break;
      }
    }
  }
  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <StatisticsGridDialogContent
          subtitle={subtitle}
          isStatistics={isStatistics}
          color={color}
          data={data}
          theme={theme}
          dateFormat={dateFormat}
          isGeneralGrid
          title={title}
        />
      </DialogContent>
    </StyledDialog>
  );
})

export default TasksGridDialog;
