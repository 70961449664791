import React from "react";
import { Button } from "@material-ui/core";
import { SendIcon } from "@icarius-icons/index";
import TextEditor from "@icarius-common/textEditor";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const Form = (props) => {

    const {
        data,
        actionCode,
        readOnly,
        people,
        createCommentAction,
        updateCommentAction,
        handleClose,
    } = props;

    const dispatch = useDispatch();

    const create = (dataToSend) => {
        dispatch(createCommentAction({ actionCode, ...dataToSend }))
        handleClose();
    }

    const update = (dataToSend) => {
        dispatch(updateCommentAction(dataToSend))
        handleClose();
    }

    const openValidationError = () => {
        dispatch(openSnackbarAction({ msg: 'El comentario no puede estar vacío', severity: "error", duration: 10000 }));
    }

    const {
        isCreate,
        setFormValue,
        submit,
    } = useHandleForm(data, create, update, openValidationError);

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
                flex: 1,
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
            }}
        >
            <div style={{ flex: 1, minWidth: '50%' }}>
                <TextEditor
                    initialValue={isCreate ? '' : data.text}
                    placeholder={isCreate ? 'Crear comentario' : 'Editar comentario'}
                    handleChange={(text, htmlText) => setFormValue({ text, htmlText }, 'text')}
                    suggestionOptions={people}
                    readOnly={readOnly}
                    helperText={readOnly ? "" : "Para que tu comentario también llegue por correo, menciona a los destinatarios con @"}
                />
            </div>
            {
                !readOnly &&
                <Button
                    onClick={submit}
                    style={{ height: 40, width: 140, border: '1px solid var(--icons)' }}
                    variant="outlined"
                    className="whiteText"
                    startIcon={<SendIcon />}
                >
                    {isCreate ? 'Comentar' : 'Guardar'}
                </Button>
            }
        </div>
    )
}

export default Form;