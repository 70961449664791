import { useState } from "react";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";


const useHandleFormDuplicate = (allRows, data, duplicateCallback, invalidDataCallback) => {

  const dispatch = useDispatch();
  const createInitialData = () => {

    const getInitialFieldData = () => {
      return "";
    }

    const fieldNames = [
      'code',
      'name',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const codeRegex = /^([A-Za-z])([A-Za-z]|[0-9]|_|-){0,12}$/;

    const schema = yup.object().shape({
      'code': yup.string().matches(codeRegex),
      'name': yup.string().max(60).required(),
    });


    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    const rowCodeCoincidence = allRows.filter(el => el.code === formData?.code);
    if (rowCodeCoincidence.length > 0) {
      dispatch(openSnackbarAction({ msg: 'No puede haber perfiles con códigos repetidos', severity: 'error' }));
      return false;
    }

    const rowCoincidence = allRows.filter(el => el.code !== formData?.code && el.name === formData?.name);
    if (rowCoincidence.length > 0) {
      dispatch(openSnackbarAction({ msg: 'No puede haber perfiles con nombres repetidos', severity: 'error' }));
      return false;
    }

    if (await dataIsValid()) {
      duplicateCallback({ ...formData, ref: data.code });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleFormDuplicate;
