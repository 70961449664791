import React, { useEffect, useState } from "react";
import { Grid, ListItem } from "@material-ui/core";
import DataItem from "./dataItem";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { makeStyles } from "@material-ui/core/styles";
import { Draggable } from 'react-beautiful-dnd';
import FabButton from "@icarius-common/fabButton";

const useStyles = makeStyles({
  noPadding: {
    padding: '0px'
  }
});

const PublicationTypeList = (props) => {
  const { materialsList, actionTypeList, momentList, publicationArr, color, setFormValue, disabled } = props;

  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [items, setItems] = useState(publicationArr);
  const classes = useStyles();

  useEffect(() => {
    // actualizar setItems cuando el array se modifique
    setItems(publicationArr)
  }, [publicationArr])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    if (disabled) return;
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(items, source.index, destination.index);
    setFormValue(newItems.map((item, index) => ({ ...item, number: index + 1 })), "details");
  };


  return (
    <div style={{ width: "100%" }}>
      <FabButton title={"Nuevo paso"} onClick={() => !disabled && setCreateIsOpen(true)} />
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            materialsList={materialsList}
            allRows={items}
            actionTypeList={actionTypeList}
            momentList={momentList}
            disabled={disabled}
            data={null}
            closeNew={() => setCreateIsOpen(false)}
            items={items}
            setFormValue={setFormValue}
          />
        </Grid>
      }
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {
            provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {
                  items.map((item, index) => (
                    <Grid container item xs={12} key={index.toString()}>
                      <Draggable isDragDisabled={disabled} draggableId={index.toString()} index={index}>
                        {
                          (provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.noPadding}
                            >
                              <Grid container item xs={12} style={{ margin: "10px 0px" }}>
                                <DataItem
                                  materialsList={materialsList}
                                  allRows={items}
                                  actionTypeList={actionTypeList}
                                  momentList={momentList}
                                  disabled={disabled}
                                  key={index.toString()}
                                  index={index}
                                  data={item}
                                  color={color}
                                  items={items}
                                  setFormValue={setFormValue}
                                />
                              </Grid>
                            </ListItem>
                          )
                        }
                      </Draggable>
                    </Grid>
                  ))
                }
                {provided.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default PublicationTypeList;