import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading || store[NAME].isLoadingCollaborators || store[NAME].isLoadingFiles || store[NAME].isLoadingComments;
export const getIsLoadingComments = store => store[NAME].isLoadingComments;
export const getFirstFetchisDone = store => store[NAME].firstFetchIsDone;
export const getProcessList = store => store[NAME].processList;
export const getActionTypeList = store => store[NAME].actionTypeList;
export const getMomentList = store => store[NAME].momentList;
export const getActionList = store => store[NAME].actionList;
export const getProcessCollaboratorList = store => store[NAME].processCollaboratorList;
export const getEmployeeList = store => store[NAME].employeeList;
export const getTreeData = store => store[NAME].treeData;
export const getProgressData = store => store[NAME].progressData;
export const getComments = store => store[NAME].comments;
export const getFiles = store => store[NAME].files;