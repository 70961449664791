import { fieldTypes } from "@icarius-utils/constants";

const NAME = "ONBOARDING_TRACKING";

export const COLLABORATOR_FIELDS = [
    {
        name: "contractDate",
        label: "Fecha de contratación",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "manager",
        label: "Manager",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "functionaryType",
        label: "Tipo de funcionario",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "management",
        label: "Gerencia",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "department",
        label: "Departamento",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "workplace",
        label: "Lugar de trabajo",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "division",
        label: "Unidad de negocio",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "costCenter",
        label: "Centro de costo de gestión",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "position",
        label: "Cargo",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "workModality",
        label: "Modalidad de trabajo",
        readOnlyType: fieldTypes.text,
    },
    {
        name: "cellphone",
        label: "Celular",
        readOnlyType: fieldTypes.phone,
    },
    {
        name: "email",
        label: "Email",
        readOnlyType: fieldTypes.email,
    },
];

export default NAME;
