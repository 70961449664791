import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOnboardingMaterialsAction } from "../actions";
import { getIsLoading, getData } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import paths from "@icarius-localization/paths";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";

const OnboardingMaterials = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    dispatch(getOnboardingMaterialsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  const handleGoBack = () => {
    history.push(paths.talentAcquisitionSettings);
  }

  return (
    <ABMLayout
      title={"Materiales y recursos de onboarding"}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={"Nuevo material o recurso"}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
      showNoData={getDataIsEmpty()}
      noDataTitle={"No se encontraron materiales o recursos"}
      noDataCreateTitle={"Nuevo material o recurso"}
      noDataOnClick={() => setCreateIsOpen(true)}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={null}
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        data && data.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
              <DataItem
                data={item}
                color={color}
              />
            </Grid>
          )
        })
      }
    </ABMLayout>
  )
}

export default OnboardingMaterials;