import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import FileInput from "@icarius-common/fileUpload/fileInput";
import FileList from "@icarius-common/fileUpload/fileList";
import { yesNoArray } from "@icarius-utils/constants";
import {
  createOnboardingMaterialAction,
  updateOnboardinMaterialAction,
} from "../../actions";
import {
  getAccessList,
  getActionList,
  getPollList,
  getConsentList,
} from "../../selectors";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const {
    data,
    handleClose,
    classes,
  } = props;

  const dispatch = useDispatch();
  const accessList = useSelector(getAccessList);
  const actionList = useSelector(getActionList);
  const pollList = useSelector(getPollList);
  const consentList = useSelector(getConsentList);

  const create = (dataToSend) => {
    dispatch(createOnboardingMaterialAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateOnboardinMaterialAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={"Código"}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={"Nombre"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-access`}>{"Con acceso para"}</InputLabel>
            <Select
              value={formData.access}
              labelId={`label-access`}
              id={`select-access`}
              onChange={(e) => setFormValue(e.target.value, "access")}
              margin={"none"}
            >
              {
                accessList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}
                  >
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-action`}>{"Código de acción"}</InputLabel>
            <Select
              value={formData.action}
              labelId={`label-action`}
              id={`select-action`}
              onChange={(e) => setFormValue(e.target.value, "action")}
              margin={"none"}
            >
              {
                actionList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}
                  >
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ ...gridStyle, height: "" }}>
          <TextField
            fullWidth
            label={"Instructivo o guía de uso"}
            variant="outlined"
            multiline
            rows={5}
            margin={"none"}
            value={formData.guide}
            onChange={(e) => setFormValue(e.target.value, "guide")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink id={`label-poll`}>{"Encuesta"}</InputLabel>
            <Select
              disabled={Boolean(formData.link || formData.file || formData.consent)}
              value={formData.poll}
              labelId={`label-poll`}
              id={`select-poll`}
              onChange={(e) => setFormValue(e.target.value, "poll")}
              margin={"none"}
              displayEmpty
            >
              <MenuItem className={"whiteText"} value={''}>{'Ninguno'}</MenuItem>
              {
                pollList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}
                  >
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink id={`label-consent`}>{"Consentimiento"}</InputLabel>
            <Select
              disabled={Boolean(formData.link || formData.poll || formData.file)}
              value={formData.consent}
              labelId={`label-consent`}
              id={`select-consent`}
              onChange={(e) => setFormValue(e.target.value, "consent")}
              margin={"none"}
              displayEmpty
            >
              <MenuItem className={"whiteText"} value={''}>{'Ninguno'}</MenuItem>
              {
                consentList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}
                  >
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        {
          (formData.poll || formData.consent) &&
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-accessRequired`}>{"Uso o acceso obligatorio"}</InputLabel>
              <Select
                value={formData.accessRequired}
                labelId={`label-accessRequired`}
                id={`select-accessRequired`}
                onChange={(e) => setFormValue(e.target.value, "accessRequired")}
                margin={"none"}
              >
                {
                  yesNoArray?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }
        <Grid container item alignItems="center" xs={12} style={gridStyle}>
          <TextField
            disabled={Boolean(formData.file || formData.poll || formData.consent)}
            fullWidth
            margin={"none"}
            label={"Link"}
            value={formData.link}
            onChange={(e) => setFormValue(e.target.value, "link")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
          <FileInput
            disabled={Boolean(formData.link || formData.poll || formData.consent)}
            id={data?.internalCode || "create"}
            acceptedFormats={[".ppt", ".pdf", ".txt", ".doc", ".docs", ".svg", ".bmp", ".jpg", ".png"]}
            handleChange={(files) => setFormValue(files, 'file')}
          />
          <FileList
            files={formData.file ? [formData.file] : null}
            handleClick={() => setFormValue(null, 'file')}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Form);