import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsLoadingPrivacyData,
  getTypeList,
  getModeList,
  getPeople,
  getGroups,
  getTasks,
  getGoals,
  getIsLoadingGridData,
  getIsLoadingFeed,
  getComments,
  getIsLoadingComments,
} from "@icarius-pages/feedback/selectors";
import { getFeedbackAction, getFeedbackPrivacyOptionsAction } from "@icarius-pages/feedback/actions";
import { UPDATE_INDIVIDUAL_ACTION_FEEDBACK } from "../actionTypes";

const useFeedback = () => {

  const [feedbackFormIsOpen, setFeedbackFormIsOpen] = useState(false);
  const [selectedFeedbackPublication, setSelectedFeedbackPublication] = useState(false);

  const dispatch = useDispatch();
  const typeList = useSelector(getTypeList);
  const modeList = useSelector(getModeList);
  const people = useSelector(getPeople);
  const groups = useSelector(getGroups);
  const tasks = useSelector(getTasks);
  const goals = useSelector(getGoals);
  const comments = useSelector(getComments);

  useEffect(() => {
    dispatch(getFeedbackAction(false))
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(getFeedbackPrivacyOptionsAction());
        }
      });
  }, [dispatch])

  const isLoadingPrivacyData = useSelector(getIsLoadingPrivacyData);
  const isLoadingGridData = useSelector(getIsLoadingGridData);
  const isLoadingFeed = useSelector(getIsLoadingFeed);
  const isLoadingComments = useSelector(getIsLoadingComments);

  const isLoadingFeedback = {
    gridData: isLoadingGridData,
    privacyData: isLoadingPrivacyData,
    feed: isLoadingFeed,
    comments: isLoadingComments,
  }

  const handleOpenFeedbackForm = () => {
    setFeedbackFormIsOpen(true);
  }

  const handleCloseFeedbackForm = () => setFeedbackFormIsOpen(false);

  const handleOpenFeedbackPublication = (feedbackPublication) => {
    setSelectedFeedbackPublication(feedbackPublication);
  }

  const handleCloseFeedbackPublication = () => setSelectedFeedbackPublication(null);

  const handleUpdateAfterCreatePublication = (dataToSend) => {
    dispatch({
      type: UPDATE_INDIVIDUAL_ACTION_FEEDBACK,
      payload: { feedback: dataToSend.data.data },
    });
  }

  const state = {
    feedbackFormIsOpen,
    selectedFeedbackPublication,
  }

  const handlers = {
    handleOpenFeedbackForm,
    handleCloseFeedbackForm,
    handleOpenFeedbackPublication,
    handleCloseFeedbackPublication,
    handleUpdateAfterCreatePublication,
  }

  return {
    state,
    handlers,
    typeList,
    modeList,
    people,
    groups,
    tasks,
    goals,
    comments,
    isLoadingFeedback,
  };
}

export default useFeedback;