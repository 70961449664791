import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import { useHistory } from "react-router-dom";
import CreateEditForm from "./createEditForm";
import TreeViewDialog from "./dialogs/treeViewDialog/index";
import DuplicateDialog from "./dialogs/duplicateDialog";
import useOnboardingProfiles from "./useOnboardingProfiles";
import { VisibilityIcon } from "@icarius-icons";

const OnboardingProfiles = () => {

  const history = useHistory();

  const {
    data,
    materialTreeData,
    actionTypeList,
    momentList,
    isLoading,
    materialsList,
    state,
    handlers,
  } = useOnboardingProfiles();

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.talentAcquisitionSettings)}
      type={"goBack"}
    />
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const duplicateButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("duplicate")}
      onClick={() => handlers.handleOpenDuplicateDialog(gridRef)}
      type={"duplicate"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const viewTreeButton = (gridRef) => (
    <CustomIconButton
      title={"Ver árbol de visualización"}
      onClick={() => handlers.handleViewTree(gridRef)}
    >
      <VisibilityIcon />
    </CustomIconButton>
  )

  return (
    <>
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditForm
          allRows={data}
          materialsList={materialsList}
          isLoading={isLoading}
          data={state.selectedData}
          actionTypeList={actionTypeList}
          momentList={momentList}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
      {
        !(state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CommonPage
          isLoading={isLoading}
          title={'Perfiles de onboarding'}
          gridTitle={'Perfiles de onboarding'}
          columnDefPage={paths.onboardingProfiles}
          rowData={data}
          menuItems={[
            goBackButton,
            createButton,
            modifyButton,
            viewTreeButton,
            deleteButton,
            duplicateButton,
          ]}
          hasExpand
          hasHelp
        >
          {
            state.duplicateDialogIsOpen &&
            <DuplicateDialog
              allRows={data}
              open={state.duplicateDialogIsOpen}
              data={state.selectedData}
              isLoading={isLoading}
              handleClose={handlers.handleCloseDuplicateDialog}
            />
          }
          {
            state.treeViewDialogIsOpen &&
            <TreeViewDialog
              open={state.treeViewDialogIsOpen}
              data={materialTreeData}
              handleClose={handlers.handleCloseTreeViewDialog}
            />
          }
        </CommonPage>
      }
    </>
  )
}

export default OnboardingProfiles;