import React, { useEffect, useState } from "react";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOnboardingProcessAssignmentAction,
  getOnboardingProcessAssignmentAction,
} from "../actions";
import {
  getData,
  getLoading,
  getCollaboratorsList,
  getCanAssign,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useOnboardingProcessesAssignment = (processCode) => {

  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);
  const data = useSelector(getData);
  const colaboratorsList = useSelector(getCollaboratorsList);
  const canAssign = useSelector(getCanAssign);

  useEffect(() => {
    if (Boolean(processCode)) {
      dispatch(getOnboardingProcessAssignmentAction(processCode));
    }
    return () => dispatch({ type: RESET_STATE });
  }, [dispatch, processCode])

  const showClosedStatusWarning = () => {
    dispatch(openSnackbarAction({
      msg: "Para crear colaboradores en el proceso o bien eliminarlos, primero debe cambiar el estado del proceso de “Cerrado” a “Abierto”",
      severity: "info",
      duration: 10000,
    }));
  }

  const handleOpenCreateDialog = (processStatus) => {
    if (processStatus === "C") {
      showClosedStatusWarning();
      return;
    }

    if (!canAssign) {
      dispatch(openDialogAction({
        title: "Atención",
        msg: (
          <>
            No es posible agregar a nuevos colaboradores a este proceso, porque ya hay actividades avanzadas para uno o más colaboradores.
            <br />
            Cree un nuevo proceso para incorporar a nuevos colaboradores.
          </>
        ),
        acceptOnly: true,
      }));
      return;
    }

    setCreateDialogIsOpen(true);
  }

  const handleOpenDeleteDialog = (processStatus, gridRef) => {
    if (processStatus === "C") {
      showClosedStatusWarning();
      return;
    }

    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    const collaborators = selectedRows.map((item) => item['CODIGO DE EMPLEADO']);

    dispatch(openDialogAction({
      maxWidth: "md",
      title: getLocalizedString("atention"),
      msg: (
        <>
          Si elimina al colaborador del proceso de onboarding, también se eliminará:
          <br />
          Toda la trazabilidad y el detalle de su participación en el proceso.
          <br />
          Sus comentarios, adjuntos, y feedbacks recibidos durante el proceso.
          <br />
          Las acciones realizadas y las etapas por las cuales ha pasado.
          <br />
          <br />
          ¿Está seguro que desea continuar?
        </>
      ),
      onConfirm: () => {
        dispatch(openDialogAction({
          maxWidth: "md",
          title: getLocalizedString("atention"),
          msg: '¿Está seguro que desea eliminar al colaborador el proceso de onboarding?',
          onConfirm: () => dispatch(deleteOnboardingProcessAssignmentAction({ processCode, collaborators })),
        }));
      }
    }));
  }

  const handleCloseCreateDialog = () => {
    setCreateDialogIsOpen(false);
  }

  const state = {
    createDialogIsOpen,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleCloseCreateDialog,
  }

  return {
    isLoading,
    data,
    colaboratorsList,
    state,
    handlers,
  }
}

export default useOnboardingProcessesAssignment;