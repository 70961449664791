import React from "react";
import {
    Grid,
    Typography,
} from "@material-ui/core";
import PublicationTypeList from "../questions/publicationTypeList";

const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center" style={{ marginTop: 20 }}>
            <Grid container item sm={12} md={10}>
                <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
                    {title}
                </Typography>
                <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

const Details = (props) => {
    const {
        materialsList,
        formData,
        setFormValue,
        actionTypeList,
        momentList,
        color,
    } = props;

    return (
        <StepContainer title={'Pasos de onboarding para el perfil'}>
            <Grid container item xs={12}>
                <PublicationTypeList
                    materialsList={materialsList}
                    publicationArr={formData.details?.sort(function (a, b) { return a.number - b.number })}
                    color={color}
                    setFormValue={setFormValue}
                    momentList={momentList}
                    actionTypeList={actionTypeList}
                />
            </Grid>
        </StepContainer>
    )
}


export default Details;
