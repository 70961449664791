import {
  GET_ORGANIZATION_STRUCTURE_DATA,
  GET_ORGANIZATION_STRUCTURE_DATA_FULFILLED,
  GET_ORGANIZATION_STRUCTURE_DATA_REJECTED,
  GET_ORGANIZATION_OCCUPATION_DATA,
  GET_ORGANIZATION_OCCUPATION_DATA_FULFILLED,
  GET_ORGANIZATION_OCCUPATION_DATA_REJECTED,
  SAVE_ORGANIZATION_STRUCTURE_DATA,
  SAVE_ORGANIZATION_STRUCTURE_DATA_FULFILLED,
  SAVE_ORGANIZATION_STRUCTURE_DATA_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { getOrgStructure, saveOrgStructure, getDepartmentOccupationEndpoint } from "@icarius-connection/api";

export const getOrgStructureAction = () => async (dispatch) => {

  dispatch({ type: GET_ORGANIZATION_STRUCTURE_DATA });
  try {
    let response = await getOrgStructure();
    if (response.data.status === "OK") {

      let orgChart = response.data.result;
      let statistics = response.data.statistics;

      dispatch({
        type: GET_ORGANIZATION_STRUCTURE_DATA_FULFILLED,
        payload: {
          orgChart,
          statistics
        },
      });

      return response;
    } else {
      dispatch({ type: GET_ORGANIZATION_STRUCTURE_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_ORGANIZATION_STRUCTURE_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getDepartmentOccupation = (node) => async (dispatch) => {

  dispatch({ type: GET_ORGANIZATION_OCCUPATION_DATA });
  try {
    let query = `?code=${node}`;
    let response = await getDepartmentOccupationEndpoint(query);
    if (response.data.status === "OK") {

      let orgChart = response.data.result;

      dispatch({
        type: GET_ORGANIZATION_OCCUPATION_DATA_FULFILLED,
        payload: {
          orgChart,
        },
      });

      return response;
    } else {
      dispatch({ type: GET_ORGANIZATION_OCCUPATION_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_ORGANIZATION_OCCUPATION_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const saveOrgStructureAction = (data) => async (dispatch) => {

  dispatch({ type: SAVE_ORGANIZATION_STRUCTURE_DATA });
  try {
    let response = await saveOrgStructure({ data });
    if (response.data.status === "OK") {

      let orgChart = response.data.result;
      let statistics = response.data.statistics;

      dispatch({
        type: SAVE_ORGANIZATION_STRUCTURE_DATA_FULFILLED,
        payload: {
          orgChart,
          statistics
        },
      });

      return response;
    } else {
      dispatch({ type: SAVE_ORGANIZATION_STRUCTURE_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: SAVE_ORGANIZATION_STRUCTURE_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
