import React from "react";
import {
    TextField,
    Grid,
    Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const gridStyleNoHeight = { padding: "0px 10px", marginTop: "15px" };
const gridStyle = { height: 75, padding: "0px 10px" };

const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center">
            <Grid container item sm={12} md={10}>
                <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
                    {title}
                </Typography>
                <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

const styles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    cssOutlinedInput: {
        color: "var(--mainText) !important",
        '&$cssFocused $notchedOutline': {
            color: "var(--mainText) !important",
        }
    },

    cssFocused: {
        color: "var(--mainText) !important",
    },

    notchedOutline: {
        borderColor: 'var(--icons) !important',
        color: "var(--mainText) !important",
    },
});

const Header = (props) => {
    const {
        formData,
        isCreate,
        setFormValue,
        classes,
    } = props;

    return (
        <StepContainer title={'Indique los parámetros del perfil'}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <TextField
                    disabled={!isCreate}
                    required
                    fullWidth
                    margin={"none"}
                    label={'Código de perfil'}
                    value={formData.code}
                    inputProps={{ maxLength: 20 }}
                    onChange={(e) => setFormValue(e.target.value, "code")}
                />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <TextField
                    required
                    fullWidth
                    margin={"none"}
                    label={'Nombre del perfil'}
                    value={formData.name}
                    inputProps={{ maxLength: 60 }}
                    onChange={(e) => setFormValue(e.target.value, "name")}
                />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyleNoHeight}>
                <TextField
                    fullWidth
                    margin={"none"}
                    label={'Comentarios del perfil'}
                    value={formData.comment}
                    onChange={(e) => setFormValue(e.target.value, "comment")}
                    multiline={true}
                    variant="outlined"
                    rows={4}
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        },
                    }}
                    InputProps={{
                        spellCheck: 'false',
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        }
                    }}
                />
            </Grid>
        </StepContainer>
    )
}


export default withStyles(styles)(Header);