import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Tipo de beneficio',
            field: 'typeBenefitString',
            filter: "agSetColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Beneficio activo',
            field: 'typeBenefitActive',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Imagen del tipo de beneficio',
            field: 'typeBenefitImage',
            filter: "agSetColumnFilter",
            cellRenderer: "ImageRenderer",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Tipo de funcionario',
            field: 'typeFuncString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Cargo',
            field: 'positionString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Función',
            field: 'functionString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Sindicato',
            field: 'laborUnionString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Categoría',
            field: 'categoryString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Especialidad',
            field: 'specialityString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Lugar de trabajo',
            field: 'workPlaceString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Gerencia',
            field: 'managementString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Obra o Faena',
            field: 'workOrTaskString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.organizationalBenefits],
        config: {
            headerName: 'Centro de costo de gestión',
            field: 'costCenterString',
            filter: "agSetColumnFilter",
        }
    },
]