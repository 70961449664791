import React, { useEffect } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useEvaluationProcessDefinitionAssignment from "./useEvaluationProcessDefinitionAssignment";
import CreateDialog from "./createDialog";

const EvaluationProcessDefinitionAssignment = ({ history, location }) => {

  const processCode = new URLSearchParams(location.search).get("code");
  const processName = new URLSearchParams(location.search).get("name");
  const processStatus = new URLSearchParams(location.search).get("status");
  const processStatusString = new URLSearchParams(location.search).get("statusString");

  useEffect(() => {
    if (!processCode) {
      history.push(paths.evaluationProcessDefinition);
    }
  }, [processCode, history])

  const {
    isLoading,
    data,
    colaboratorsList,
    state,
    handlers,
  } = useEvaluationProcessDefinitionAssignment(processCode);

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.onboardingProcesses)}
      type={"goBack"}
    />
  )

  const createButton = () => (
    <CustomIconButton
      title={'Asignar colaboradores'}
      onClick={() => handlers.handleOpenCreateDialog(processStatus)}
      type={"add"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(processStatus, gridRef)}
      type={"delete"}
    />
  )

  const title = (() => {
    if (processStatus === "A") return `Asignar colaboradores al proceso: ${processName} (${processStatusString})`
    if (processStatus === "C") return `Ver colaboradores del proceso: ${processName} (${processStatusString})`
    return "";
  })();

  return (
    <CommonPage
      isLoading={isLoading}
      title={title}
      gridTitle={title}
      columnDefPage={paths.onboardingProcessesAssignment}
      rowData={data}
      menuItems={[goBackButton, createButton, deleteButton]}
      hasExpand
      hasHelp
    >
      {
        state.createDialogIsOpen &&
        <CreateDialog
          open={state.createDialogIsOpen}
          colaborators={colaboratorsList}
          processStatusString={processStatusString}
          planCode={processCode}
          processCode={processCode}
          processName={processName}
          loading={isLoading}
          data={data}
          planName={processName}
          handleClose={handlers.handleCloseCreateDialog}
        />
      }
    </CommonPage>
  )
}

export default EvaluationProcessDefinitionAssignment;