import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getData,
  getTypeBenefitList,
  getTypeFuncList,
  getFunctionList,
  getPositionList,
  getLaborUnionList,
  getCategoryList,
  getSpecialityList,
  getWorkPlaceList,
  getManagementList,
  getWorkOrTaskList,
  getCostCenterList,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  deleteOrganizationalBenefitsAction,
  getOrganizationalBenefitsAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useOrganizationalBenefits = () => {

  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar o borrar
  const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const typeBenefitList = useSelector(getTypeBenefitList);
  const typeFuncList = useSelector(getTypeFuncList);
  const functionList = useSelector(getFunctionList);
  const positionList = useSelector(getPositionList);
  const laborUnionList = useSelector(getLaborUnionList);
  const categoryList = useSelector(getCategoryList);
  const specialityList = useSelector(getSpecialityList);
  const workPlaceList = useSelector(getWorkPlaceList);
  const managementList = useSelector(getManagementList);
  const workOrTaskList = useSelector(getWorkOrTaskList);
  const costCenterList = useSelector(getCostCenterList);

  useEffect(() => {
    dispatch(getOrganizationalBenefitsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar el beneficio organizacional?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenCreateDialog = (gridRef) => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleDelete = (document) => {
    if (document.status === "H") {
      dispatch(openSnackbarAction({ msg: 'No se puede eliminar el beneficio para un plan histórico', severity: "error", duration: 10000 }));
    } else {
      dispatch(deleteOrganizationalBenefitsAction({ internalCode: document.internalCode }));
    }
  }

  const handleCloseCreateEditDuplicateDialog = () => {
    setCreateDialogIsOpen(false);
    setViewDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedDocument(null);
  }


  const handleOpenViewDialog = (row) => {
    setSelectedDocument(row.data);
    setViewDialogIsOpen(true);
  }

  const handleCloseViewDialog = () => {
    setViewDialogIsOpen(false);
  }

  const state = {
    createDialogIsOpen,
    editDialogIsOpen,
    selectedDocument,
    viewDialogIsOpen,
  }

  const handlers = {
    handleDelete,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleCloseCreateEditDuplicateDialog,
    handleCloseViewDialog,
    handleOpenViewDialog,
    handleOpenEditDialog,
  }

  return {
    data,
    typeBenefitList,
    typeFuncList,
    functionList,
    positionList,
    laborUnionList,
    categoryList,
    specialityList,
    workPlaceList,
    managementList,
    workOrTaskList,
    costCenterList,
    isLoading,
    state,
    handlers
  }
}

export default useOrganizationalBenefits;