import React, { useState } from "react";
import { MenuItem } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "src/app/selectors";
import Form from "./form";
import Header from "./header";

const CommentItem = (props) => {

    const {
        data,
        people,
        actionCode,
        isLoading,
        deleteCommentAction,
        createCommentAction,
        updateCommentAction,
    } = props;

    const [formIsOpen, setFormIsOpen] = useState(false);

    const dispatch = useDispatch();
    const userData = useSelector(getUserData);

    const isOwner = userData.code === data.owner.code;

    const handleDelete = () => {
        dispatch(deleteCommentAction({ internalCode: data.internalCode }));
    }

    return (
        <div
            style={{
                marginTop: 10,
                padding: 20,
                paddingTop: 10,
                borderRadius: 10,
                background: 'var(--mainBackgroundColor)',
                border: '1px solid var(--medalTableBorder)',
            }}
        >
            <Header
                image={data.owner.image}
                name={data.owner.name}
                date={data.modifiedDate}
                hasMenu={!isLoading && isOwner}
                menuOptions={
                    [
                        isOwner && !formIsOpen &&
                        <MenuItem key={3} onClick={() => setFormIsOpen(true)}>Editar</MenuItem>,
                        isOwner &&
                        <MenuItem key={4} onClick={handleDelete}>Borrar</MenuItem>,
                    ]
                }
            />
            <div style={{ padding: '10px 0px' }}>
                <Form
                    readOnly={!formIsOpen}
                    data={data}
                    actionCode={actionCode}
                    people={people}
                    createCommentAction={createCommentAction}
                    updateCommentAction={updateCommentAction}
                    handleClose={() => setFormIsOpen(false)}
                />
            </div>
        </div>
    )
}

export default CommentItem;