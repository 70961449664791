import React, { useEffect } from "react";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import DialogTransition from "@icarius-common/dialogTransition";
import {
  getGalleryImagesAction,
} from "@icarius-common/galleryDialog/actions";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { createOrganizationalBenefitsAction, modifyOrganizationalBenefitsAction } from "../../../actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };


const CreateEditDialog = (props) => {

  const {
    open,
    isView,
    loading,
    data,
    typeBenefitList,
    typeFuncList,
    functionList,
    positionList,
    laborUnionList,
    categoryList,
    specialityList,
    workPlaceList,
    managementList,
    workOrTaskList,
    costCenterList,
    handleClose } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGalleryImagesAction('news'));
  }, [dispatch])

  const create = (dataToSend) => {
    dispatch(createOrganizationalBenefitsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyOrganizationalBenefitsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, modify, openValidationError);

  if (loading) return null;

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"md"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {isView ? "Ver beneficio organizacional" : isCreate ? "Crear beneficio organizacional" : "Editar beneficio organizacional"}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent>
            <Grid container item xs={12}>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* typeBenefit */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-typeBenefit`}>{"Tipo de beneficio"}</InputLabel>
                  <Select
                    disabled={!isCreate}
                    value={formData.typeBenefit}
                    labelId={`label-typeBenefit`}
                    id={`select-typeBenefit`}
                    onChange={(e) => setFormValue(e.target.value, "typeBenefit")}
                    margin={"none"}
                  >
                    {
                      typeBenefitList.filter(elem => isCreate ? elem.points === 0 && !elem.exchangeForPoints && elem.active : true).map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* typeFunc */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-typeFunc`}>{"Tipo de funcionario"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.typeFunc}
                    labelId={`label-typeFunc`}
                    id={`select-typeFunc`}
                    onChange={(e) => setFormValue(e.target.value, "typeFunc")}
                    margin={"none"}
                  >
                    {
                      typeFuncList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* function */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-function`}>{"Función"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.function}
                    labelId={`label-function`}
                    id={`select-function`}
                    onChange={(e) => setFormValue(e.target.value, "function")}
                    margin={"none"}
                  >
                    {
                      functionList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* position */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-position`}>{"Cargo"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.position}
                    labelId={`label-position`}
                    id={`select-position`}
                    onChange={(e) => setFormValue(e.target.value, "position")}
                    margin={"none"}
                  >
                    {
                      positionList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* laborUnion */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-laborUnion`}>{"Sindicato"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.laborUnion}
                    labelId={`label-laborUnion`}
                    id={`select-laborUnion`}
                    onChange={(e) => setFormValue(e.target.value, "laborUnion")}
                    margin={"none"}
                  >
                    {
                      laborUnionList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* category */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-category`}>{"Categoría"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.category}
                    labelId={`label-category`}
                    id={`select-category`}
                    onChange={(e) => setFormValue(e.target.value, "category")}
                    margin={"none"}
                  >
                    {
                      categoryList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* speciality */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-speciality`}>{"Especialidad"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.speciality}
                    labelId={`label-speciality`}
                    id={`select-speciality`}
                    onChange={(e) => setFormValue(e.target.value, "speciality")}
                    margin={"none"}
                  >
                    {
                      specialityList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* workPlace */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-workPlace`}>{"Lugar de trabajo"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.workPlace}
                    labelId={`label-workPlace`}
                    id={`select-workPlace`}
                    onChange={(e) => setFormValue(e.target.value, "workPlace")}
                    margin={"none"}
                  >
                    {
                      workPlaceList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* management */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-management`}>{"Gerencia"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.management}
                    labelId={`label-management`}
                    id={`select-management`}
                    onChange={(e) => setFormValue(e.target.value, "management")}
                    margin={"none"}
                  >
                    {
                      managementList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* workOrTask */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-workOrTask`}>{"Obra o Faena"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.workOrTask}
                    labelId={`label-workOrTask`}
                    id={`select-workOrTask`}
                    onChange={(e) => setFormValue(e.target.value, "workOrTask")}
                    margin={"none"}
                  >
                    {
                      workOrTaskList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* costCenter */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-costCenter`}>{"Centro de costo de gestión"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.costCenter}
                    labelId={`label-costCenter`}
                    id={`select-costCenter`}
                    onChange={(e) => setFormValue(e.target.value, "costCenter")}
                    margin={"none"}
                  >
                    {
                      costCenterList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          {
            !isView &&
            <DialogActions>
              <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
              <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
            </DialogActions>
          }
        </div>
      </Dialog>
    </>
  );
}

export default CreateEditDialog;
