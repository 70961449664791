import { useState } from "react";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";


const useHandleForm = (allRows, data, createCallback, modifyCallback, invalidDataCallback) => {

  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "details") {
        return isCreate ? [] : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "comment",
      "details",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const codeRegex = /^([A-Za-z])([A-Za-z]|[0-9]|_|-){0,12}$/;

    const schema = yup.object().shape({
      'code': yup.string().matches(codeRegex).required(),
      'name': yup.string().max(60).required(),
      'comment': yup.string(),
      'details': yup.array().min(1).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (isCreate) {
      const rowCodeCoincidence = allRows.filter(el => el.code === formData?.code);
      if (rowCodeCoincidence.length > 0) {
        dispatch(openSnackbarAction({ msg: 'No puede haber perfiles con códigos repetidos', severity: 'error' }));
        return false;
      }
    }

    const rowCoincidence = allRows.filter(el => el.code !== formData?.code && el.name === formData?.name);
    if (rowCoincidence.length > 0) {
      dispatch(openSnackbarAction({ msg: 'No puede haber perfiles con nombres repetidos', severity: 'error' }));
      return false;
    }

    if (formData.details.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Agregue al menos un paso', severity: 'error' }));
      return false;
    }


    if (await dataIsValid()) {
      isCreate ? createCallback(formData) : modifyCallback({ ...formData, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    submit,
  };
}

export default useHandleForm;
