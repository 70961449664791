import React, { useMemo, useState } from "react";
import ProfileImg from "@icarius-common/profileImg";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VariableSizeList } from "react-window";
import { SearchIcon } from "@icarius-icons/index";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const ITEM_HEIGHT = 45;

const QuickUserSelector = (props) => {

  const {
    user,
    userList,
    handleChange,
    tooltipTitle,
    disabled,
  } = props;

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const handleOpen = (event) => {
    if (disabled) return;
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setTooltipIsOpen(false);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setSearchValue("");
  };

  const handleSelectUser = (event, item) => {
    event.stopPropagation();
    handleChange(item.key);
    handleClose(event);
  }

  const getVariableListHeight = () => {
    const totalSize = filteredUserList.length * ITEM_HEIGHT;
    return totalSize > 250 ? 250 : totalSize;
  }

  const filteredUserList = useMemo(() => {
    // filtrar segun campo de busqueda
    const listWithoutSelectedUser = user ? userList.filter((item) => item.key !== user.key) : userList;

    if (!searchValue) return listWithoutSelectedUser;

    return listWithoutSelectedUser.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [user, userList, searchValue])

  const Row = ({ index, style }) => {
    const item = filteredUserList[index];

    return (
      <div style={style}>
        <MenuItem
          onClick={(event) => handleSelectUser(event, item)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            padding: 10,
          }}
        >
          <ProfileImg
            image={item?.image}
            size={25}
          />
          <Typography className="whiteText">
            {item.name}
          </Typography>
        </MenuItem>
      </div>
    )
  }

  return (
    <Tooltip title={tooltipTitle} open={tooltipIsOpen}>
      <div>
        <IconButton
          onMouseEnter={() => setTooltipIsOpen(true)}
          onMouseLeave={() => setTooltipIsOpen(false)}
          onClick={handleOpen}
          style={{
            padding: 0,
            cursor: disabled ? "auto" : "pointer",
          }}
        >
          {
            user ?
              <ProfileImg
                image={user?.image}
                size={25}
              />
              :
              <PersonAddIcon />
          }
        </IconButton>
        {
          Boolean(anchorEl) &&
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClick={(event) => event.stopPropagation()}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: { background: "var(--secondaryBackgroundColor)" }
            }}
          >
            <div style={{ padding: 10 }}>
              <TextField
                value={searchValue}
                onClick={(event) => event.stopPropagation()}
                onChange={(event) => setSearchValue(event.target.value)}
                placeholder={"Buscar colaborador"}
                style={{ border: '1px solid var(--icons)', borderRadius: 10, padding: 10 }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>
            {
              filteredUserList.length === 0 &&
              <Typography className="whiteText" style={{ margin: "10px 20px 20px" }}>
                No se encontraron colaboradores
              </Typography>
            }
            <VariableSizeList
              style={{ overflowX: "hidden" }}
              className="List"
              height={getVariableListHeight()}
              width={450}
              itemCount={filteredUserList.length}
              itemSize={() => ITEM_HEIGHT}
            >
              {Row}
            </VariableSizeList>
          </Popover>
        }
      </div>
    </Tooltip>
  )
}

export default QuickUserSelector;