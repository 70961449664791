import React, { useMemo, useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Typography,
  Checkbox,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import OnboardingTree from "@icarius-common/onboardingTree";

const TreeViewDialog = (props) => {

  const {
    open,
    data,
    collaboratorList,
    processName,
    handleClose,
  } = props;

  const [hideStopped, setHideStopped] = useState(true);
  const [hideNoNecessary, setHideNoNecessary] = useState(true);

  const filteredCollaboratorList = useMemo(() => {
    return collaboratorList.filter((item) => {
      if (hideStopped && item.status === "D") return false;
      return true;
    });
  }, [collaboratorList, hideStopped])

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move', paddingRight: 20, width: "95%" }} id="draggable-dialog-title">
        {`Árbol de visualización del proceso ${processName}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <div style={{ marginBottom: 10, display: "flex", gap: 30 }}>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer", gap: 10 }}
            onClick={() => setHideStopped(prev => !prev)}
          >
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {"Excluir colaboradores detenidos"}
            </Typography>
            <Checkbox checked={hideStopped} style={{ padding: 0 }} />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer", gap: 10 }}
            onClick={() => setHideNoNecessary(prev => !prev)}
          >
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {"Excluir acciones no necesarias"}
            </Typography>
            <Checkbox checked={hideNoNecessary} style={{ padding: 0 }} />
          </div>
        </div>
        <Typography className="whiteText" style={{ marginBottom: 10, fontSize: 20, fontWeight: 600 }}>
          {`${filteredCollaboratorList.length} colaboradores en el proceso`}
        </Typography>
        <OnboardingTree
          data={data}
          hideNoNecessary={hideNoNecessary}
          hideStopped={hideStopped}
        />
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("close")} />
      </DialogActions>
    </Dialog>
  );
}

export default TreeViewDialog;