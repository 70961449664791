import React, { useMemo } from "react";
import ProfileImg from "@icarius-common/profileImg";
import {
  Card,
  CardContent,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor, getIsAdmin, getUserData } from "src/app/selectors";
import DotsMenu from "@icarius-common/dotsMenu";
import { LinkIcon } from "@icarius-icons/index";
import PollIcon from '@material-ui/icons/Poll';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SubjectIcon from '@material-ui/icons/Subject';
import QuickUserSelector from "./quickUserSelector";
import { generalColors } from "@icarius-utils/colors";

const Action = (props) => {

  const {
    data,
    collaboratorList,
    responsibleOptionsList,
    handleClick,
    handleEdit,
    handleDelete,
    handleReplicate,
    handleAssignResponsible,
    handleOpenMaterial,
    readOnly,
  } = props;

  const color = useSelector(getAppColor);
  const isAdmin = useSelector(getIsAdmin);
  const { level } = useSelector(getUserData);

  const actionCollaborator = collaboratorList.find((item) => item.key === data.collaboratorCode);
  const actionResponsible = responsibleOptionsList.find((item) => item.key === data.responsibleCode);

  const assignResponsibleIsDisabled = readOnly || ["N", "R"].includes(data.status) || level !== "E";

  const getCursor = () => {
    if (!readOnly && !["N", "R"].includes(data.status)) return "grab";
    if (!readOnly) return "pointer";
    return "";
  }

  const tooltipTitle = (() => {
    if (actionResponsible) return `Responsable: ${actionResponsible?.name}`;
    if (assignResponsibleIsDisabled) return "Sin responsable asignado";
    return "Asignar responsable";
  })();

  const filteredUserList = useMemo(() => {
    return responsibleOptionsList.filter((item) => {
      if (!item.isActive) return false;
      if (!isAdmin && !item.isAdmin) return false;
      return true;
    })
  }, [responsibleOptionsList, isAdmin])

  return (
    <Card
      onClick={() => handleClick(data)}
      style={{
        background: "var(--tertiaryBackgroundColor)",
        cursor: getCursor(),
      }}
    >
      <CardContent style={{ padding: 14 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <ProfileImg
              image={actionCollaborator?.image}
              size={25}
            />
            <div>
              <Typography className="whiteText" style={{ fontSize: 14 }}>
                {actionCollaborator?.name}
              </Typography>
              {
                ["D", "R"].includes(actionCollaborator?.status) &&
                <Typography style={{ fontSize: 14, fontWeight: 600, color: color }}>
                  {actionCollaborator?.statusName}
                </Typography>
              }
            </div>
          </div>
          {
            ["M", "E"].includes(level) && !readOnly &&
            <DotsMenu noPadding>
              <MenuItem onClick={() => handleEdit(data)}>
                Editar
              </MenuItem>
              {
                level === "E" && data.status === "P" && Boolean(data.responsibleCode) &&
                <MenuItem onClick={() => handleReplicate(data.code)}>
                  Replicar
                </MenuItem>
              }
              {
                level === "E" && data.status !== "R" &&
                <MenuItem onClick={() => handleDelete(data.code)}>
                  Eliminar
                </MenuItem>
              }
            </DotsMenu>
          }
        </div>
        <Tooltip title={data.description} arrow>
          <Typography className="whiteText" style={{ marginTop: 10, marginBottom: 10 }}>
            {data.name}
          </Typography>
        </Tooltip>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 10
          }}
        >
          <Typography className="whiteText" style={{ fontSize: 14 }}>
            {data.statusDate}
          </Typography>
          <QuickUserSelector
            user={actionResponsible}
            userList={filteredUserList}
            handleChange={(newResponsible) => handleAssignResponsible(data, newResponsible)}
            disabled={assignResponsibleIsDisabled}
            tooltipTitle={tooltipTitle}
          />
        </div>
        {
          Boolean(data.materialList.length) &&
          <div
            style={{
              paddingTop: 10,
              display: "flex",
              flexWrap: "wrap",
              gap: 15,
            }}
          >
            {
              data.materialList.map((item) => {

                const icon = (() => {
                  const accessedStyle = {
                    fill: item.accessDate ? color : "",
                    cursor: readOnly ? "" : "pointer",
                  };

                  if (item.poll) return <PollIcon style={accessedStyle} />;
                  if (item.link) return <LinkIcon style={accessedStyle} />;
                  if (item.file) return <DescriptionIcon style={accessedStyle} />;
                  if (item.consent) return <AssignmentTurnedInIcon style={accessedStyle} />;
                  return <SubjectIcon style={accessedStyle} />;
                })();

                return (
                  <div
                    key={item.code}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (readOnly) return;
                      if (handleOpenMaterial) {
                        handleOpenMaterial(item, data);
                      }
                    }}
                  >
                    <Tooltip
                      title={`${item.name}${item.accessDate ? ` - Último acceso: ${item.accessDate}` : ""}`}
                      arrow
                    >
                      <div style={{ position: "relative" }}>
                        {icon}
                        {
                          Boolean(
                            (item.poll && item.poll.isCompleted) ||
                            (item.consent && item.consent.acceptDate)
                          ) &&
                          <div style={{ position: "absolute", top: 10, left: 10 }}>
                            <CheckIcon style={{ fill: generalColors.green }} />
                          </div>
                        }
                      </div>
                    </Tooltip>
                  </div>
                )
              })
            }
          </div>
        }
      </CardContent>
    </Card>
  )
}

export default Action;