import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import CommentItem from "./commentItem";
import CommentForm from "./form";
import useResetStateByKey from "@icarius-utils/hooks/useResetStateByKey";
import ProfileImg from "@icarius-common/profileImg";
import { useSelector } from "react-redux";
import { getAvatar } from "src/app/selectors";

const List = (props) => {

    const {
        data = [],
        actionCode,
        people,
        isLoading,
        createCommentAction,
        updateCommentAction,
        deleteCommentAction,
    } = props;

    const [showEveryComment, setShowEveryComment] = useState(false);

    const {
        key,
        resetState,
    } = useResetStateByKey();

    const avatar = useSelector(getAvatar);

    return (
        <div style={{ width: "100%", marginTop: 20 }}>
            <div style={{ display: 'flex', gap: 10 }}>
                <ProfileImg
                    image={avatar}
                    size={45}
                />
                <CommentForm
                    key={key}
                    actionCode={actionCode}
                    people={people}
                    createCommentAction={createCommentAction}
                    updateCommentAction={updateCommentAction}
                    handleClose={resetState}
                />
            </div>
            {
                data.slice(0, showEveryComment ? data.length : 2).map((item) => {
                    return (
                        <CommentItem
                            key={item.internalCode}
                            data={item}
                            isLoading={isLoading?.comments}
                            actionCode={actionCode}
                            people={people}
                            deleteCommentAction={deleteCommentAction}
                            createCommentAction={createCommentAction}
                            updateCommentAction={updateCommentAction}
                        />
                    )
                })
            }
            {
                !Boolean(data.length) &&
                <Typography
                    align="center"
                    className="whiteText"
                    style={{ fontSize: 20, paddingTop: 20 }}
                >
                    No se encontraron comentarios
                </Typography>
            }
            {
                !showEveryComment && data.length > 2 &&
                <Typography
                    align="center"
                    className="whiteText"
                    style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        marginTop: 20,
                    }}
                    onClick={() => setShowEveryComment(true)}
                >
                    Ver todos
                </Typography>
            }
        </div>
    )
}

export default List;