import * as actionTypes from "./actionTypes";
import {
  getCategoriesAndSpecialtiesAPI,
  deleteCategoriesAndSpecialtiessAPI,
  createCategoriesAndSpecialtiesAPI,
  updateCategoriesAndSpecialtiesAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;    
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getCategoriesAndSpecialtiesAction = (type) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getCategoriesAndSpecialtiesAPI(`?type=${type}`);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteCategoriesAndSpecialtiesAction = (dataToSend, type) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteCategoriesAndSpecialtiessAPI(dataToSend);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({
      msg: `${type === "E" ? "Especialidad" : "Categoría"} se ha eliminado correctamente`,
      severity: "success"
    }));
    return response;
  } catch (e) {
    if(e.response.data.error === "IN_USE"){
      dispatch(openSnackbarAction({
        msg: `No es posible eliminar la ${type === "E" ? "especialidad" : "categoría"} porque está en uso`,
        severity: "error"
      }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));  
    }
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
  }
};

export const createCategoriesAndSpecialtiesAction = (dataToSend, type) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createCategoriesAndSpecialtiesAPI(dataToSend);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({
      msg: `${type === "E" ? "Especialidad" : "Categoría"} se ha creado correctamente`,
      severity: "success"
    }));
    return response;
  } catch (e) {
    if(e.response.data.error === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({
        msg: `No puede existir más de una ${type === "E" ? "especialidad" : "categoría"} que comparta el mismo código o nombre`,
        severity: "error"
      }));
    }else{
      errorHandler(e, dispatch);
    }
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    
  }
};

export const modifyCategoriesAndSpecialtiesAction = (dataToSend, type) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateCategoriesAndSpecialtiesAPI(dataToSend);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({
      msg: `${type === "E" ? "Especialidad" : "Categoría"} se ha actualizado correctamente`,
      severity: "success"
    }));
    return response;
  } catch (e) {
    if(e.response.data.error === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ 
        msg: `No puede existir más de una ${type === "E" ? "especialidad" : "categoría"} que comparta el mismo código o nombre`,
        severity: "error"
      }));
    }else{
      if(e.response.data.error === "IN_USE"){
        dispatch(openSnackbarAction({
          msg: `No es posible modificar la ${type === "E" ? "especialidad" : "categoría"} porque está en uso`,
          severity: "error"
        }));
      }else{
        errorHandler(e, dispatch);
      }
    }
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
  }
};