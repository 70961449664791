import NAME from "./constants";

export const GET_ORGANIZATION_STRUCTURE_DATA = NAME + "/GET_ORGANIZATION_STRUCTURE_DATA";
export const GET_ORGANIZATION_STRUCTURE_DATA_FULFILLED = NAME + "/GET_ORGANIZATION_STRUCTURE_DATA_FULFILLED";
export const GET_ORGANIZATION_STRUCTURE_DATA_REJECTED = NAME + "/GET_ORGANIZATION_STRUCTURE_DATA_REJECTED";
export const GET_ORGANIZATION_OCCUPATION_DATA = NAME + "/GET_ORGANIZATION_OCCUPATION_DATA";
export const GET_ORGANIZATION_OCCUPATION_DATA_FULFILLED = NAME + "/GET_ORGANIZATION_OCCUPATION_DATA_FULFILLED";
export const GET_ORGANIZATION_OCCUPATION_DATA_REJECTED = NAME + "/GET_ORGANIZATION_OCCUPATION_DATA_REJECTED";
export const SAVE_ORGANIZATION_STRUCTURE_DATA = NAME + "/SAVE_ORGANIZATION_STRUCTURE_DATA";
export const SAVE_ORGANIZATION_STRUCTURE_DATA_FULFILLED = NAME + "/SAVE_ORGANIZATION_STRUCTURE_DATA_FULFILLED";
export const SAVE_ORGANIZATION_STRUCTURE_DATA_REJECTED = NAME + "/SAVE_ORGANIZATION_STRUCTURE_DATA_REJECTED";