import * as actionTypes from "./actionTypes";
import {
  getOnboardingTrackingAPI,
  getMomentsOnboardingTrackingAPI,
  getActionsOnboardingTrackingAPI,
  updateActionStatusOnboardingTrackingAPI,
  deleteActionOnboardingTrackingAPI,
  assignActionResponsibleOnboardingTrackingAPI,
  getCollaboratorsOnboardingTrackingAPI,
  addCollaboratorOnboardingTrackingAPI,
  changeCollaboratorStatusOnboardingTrackingAPI,
  deleteCollaboratorOnboardingTrackingAPI,
  updateIndividualActionOnboardingTrackingAPI,
  getTreeOnboardingTrackingAPI,
  getProgressOnboardingTrackingAPI,
  getOnboardingTrackingActionCommentsAPI,
  createOnboardingTrackingActionCommentsAPI,
  updateOnboardingTrackingActionCommentsAPI,
  deleteOnboardingTrackingActionCommentsAPI,
  getOnboardingTrackingActionFilesAPI,
  createOnboardingTrackingActionFileAPI,
  deleteOnboardingTrackingActionFileAPI,
  replicateActionOnboardingTrackingAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getOnboardingTrackingAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getOnboardingTrackingAPI();

    let processList = response.data && response.data.processList;
    let employeeList = response.data && response.data.employeeList;
    let actionTypeList = response.data && response.data.actionTypeList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        processList,
        actionTypeList,
        employeeList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getMomentsOnboardingTrackingAction = (processCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_MOMENTS });
  try {
    let response = await getMomentsOnboardingTrackingAPI(`?processCode=${processCode}`);

    let momentList = response.data && response.data.momentList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_MOMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_MOMENTS_FULFILLED,
      payload: {
        momentList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_MOMENTS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getProgressOnboardingTrackingAction = (processCode, momentCode = "") => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PROGRESS });
  try {
    let response = await getProgressOnboardingTrackingAPI(`?processCode=${processCode}&momentCode=${momentCode}`);

    let processProgress = response.data && response.data.processProgress;
    let momentProgress = response.data && response.data.momentProgress;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_PROGRESS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_PROGRESS_FULFILLED,
      payload: {
        progressData: { processProgress, momentProgress },
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_PROGRESS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getTreeOnboardingTrackingAction = (processCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TREE });
  try {
    let response = await getTreeOnboardingTrackingAPI(`?processCode=${processCode}`);

    let treeData = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_TREE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_TREE_FULFILLED,
      payload: {
        treeData,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_TREE_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getActionsOnboardingTrackingAction = (processCode, momentCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ACTIONS });
  try {
    let response = await getActionsOnboardingTrackingAPI(`?processCode=${processCode}&momentCode=${momentCode}`);

    let actionList = response.data && response.data.actionList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_ACTIONS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_ACTIONS_FULFILLED,
      payload: {
        actionList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ACTIONS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getCollaboratorsOnboardingTrackingAction = (processCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COLLABORATORS });
  try {
    let response = await getCollaboratorsOnboardingTrackingAPI(`?processCode=${processCode}`);

    let processCollaboratorList = response.data && response.data.processCollaboratorList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_COLLABORATORS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_COLLABORATORS_FULFILLED,
      payload: {
        processCollaboratorList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COLLABORATORS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const addCollaboratorOnboardingTrackingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.ADD_COLLABORATOR });
  try {
    let response = await addCollaboratorOnboardingTrackingAPI(dataToSend);

    let processCollaboratorList = response.data && response.data.processCollaboratorList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ADD_COLLABORATOR_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.ADD_COLLABORATOR_FULFILLED,
      payload: {
        processCollaboratorList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Colaborador asignado al control del proceso con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ADD_COLLABORATOR_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const deleteCollaboratorOnboardingTrackingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_COLLABORATOR });
  try {
    let response = await deleteCollaboratorOnboardingTrackingAPI(dataToSend);

    let processCollaboratorList = response.data && response.data.processCollaboratorList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_COLLABORATOR_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_COLLABORATOR_FULFILLED,
      payload: {
        processCollaboratorList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Colaborador eliminado del control del proceso con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_COLLABORATOR_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const changeCollaboratorStatusOnboardingTrackingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_COLLABORATOR });
  try {
    let response = await changeCollaboratorStatusOnboardingTrackingAPI(dataToSend);

    let processCollaboratorList = response.data && response.data.processCollaboratorList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_COLLABORATOR_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_COLLABORATOR_FULFILLED,
      payload: {
        processCollaboratorList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Colaborador actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_COLLABORATOR_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const assignActionResponsibleOnboardingTrackingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.ASSIGN_RESPONSIBLE });
  try {
    let response = await assignActionResponsibleOnboardingTrackingAPI(dataToSend);

    let actionList = response.data && response.data.actionList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ASSIGN_RESPONSIBLE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.ASSIGN_RESPONSIBLE_FULFILLED,
      payload: {
        actionList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Responsable asignado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ASSIGN_RESPONSIBLE_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const deleteActionOnboardingTrackingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_ACTION });
  try {
    let response = await deleteActionOnboardingTrackingAPI(dataToSend);

    let momentList = response.data && response.data.momentList;
    let actionList = response.data && response.data.actionList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_ACTION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_ACTION_FULFILLED,
      payload: {
        momentList,
        actionList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Acción eliminada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_ACTION_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const updateIndividualActionOnboardingTrackingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_INDIVIDUAL_ACTION });
  try {
    let response = await updateIndividualActionOnboardingTrackingAPI(dataToSend);

    let actionList = response.data && response.data.actionList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_INDIVIDUAL_ACTION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_INDIVIDUAL_ACTION_FULFILLED,
      payload: {
        actionList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Acción actualizada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_INDIVIDUAL_ACTION_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const replicateActionOnboardingTrackingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_INDIVIDUAL_ACTION });
  try {
    let response = await replicateActionOnboardingTrackingAPI(dataToSend);

    let actionList = response.data && response.data.actionList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_INDIVIDUAL_ACTION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_INDIVIDUAL_ACTION_FULFILLED,
      payload: {
        actionList,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Acción replicada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_INDIVIDUAL_ACTION_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const updateActionsOnboardingTrackingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_ACTIONS });
  try {
    let response = await updateActionStatusOnboardingTrackingAPI(dataToSend);

    let momentList = response.data && response.data.momentList;
    let actionList = response.data && response.data.actionList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_ACTIONS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_ACTIONS_FULFILLED,
      payload: {
        momentList,
        actionList,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_ACTIONS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

// comments
export const getOnboardingTrackingActionCommentsAction = (actionCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COMMENTS });
  try {
    let response = await getOnboardingTrackingActionCommentsAPI(`?actionCode=${actionCode}`);

    let comments = response.data && response.data.comments;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_COMMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_COMMENTS_FULFILLED,
      payload: { comments },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COMMENTS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createOnboardingTrackingActionCommentsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_COMMENT });
  try {
    let response = await createOnboardingTrackingActionCommentsAPI({ ...dataToSend, text: JSON.stringify(dataToSend.text) });

    let comments = response.data && response.data.comments;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_COMMENT_FULFILLED,
      payload: { comments },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateOnboardingTrackingActionCommentsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_COMMENT });
  try {
    let response = await updateOnboardingTrackingActionCommentsAPI({ ...dataToSend, text: JSON.stringify(dataToSend.text) });

    let comments = response.data && response.data.comments;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_COMMENT_FULFILLED,
      payload: { comments },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteOnboardingTrackingActionCommentsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_COMMENT });
  try {
    let response = await deleteOnboardingTrackingActionCommentsAPI(dataToSend);

    let comments = response.data && response.data.comments;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_COMMENT_FULFILLED,
      payload: { comments },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

// files
export const getOnboardingTrackingActionFilesAction = (actionCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FILES });
  try {
    let response = await getOnboardingTrackingActionFilesAPI(`?actionCode=${actionCode}`);

    let files = response.data && response.data.files;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FILES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FILES_FULFILLED,
      payload: { files },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FILES_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createOnboardingTrackingActionFileAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_FILE });
  try {
    let formData = new FormData();
    Object.keys(dataToSend).forEach((key) => {
      formData.append(key, dataToSend[key]);
    });

    let response = await createOnboardingTrackingActionFileAPI(formData);

    let files = response.data && response.data.files;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_FILE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FILE_FULFILLED,
      payload: { files },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_FILE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteOnboardingTrackingActionFileAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_FILE });
  try {
    let response = await deleteOnboardingTrackingActionFileAPI(dataToSend);

    let files = response.data && response.data.files;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_FILE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FILE_FULFILLED,
      payload: { files },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_FILE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};