import React from "react";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";


const ConfirmActionDialog = (props) => {

    const { open, handleClose, nodeId, deleteNode, saveChart } = props;

    const confirm = () => {
        handleAgree(nodeId);
        handleClose();
    }

    const handleAgree = nodeId ? deleteNode : saveChart;
    const msg = nodeId ? "¿Está seguro que desea eliminar?" : "¿Está seguro que desea guardar los cambios?"

    return (
        <>
            <Dialog
                TransitionComponent={DialogTransition}
                PaperComponent={PaperDraggable}
                maxWidth={"sm"}
                open={open}
                scroll={"paper"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {getLocalizedString("atention")}
                    <DialogTitleDivider />
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
                <DialogContent>
                    <Typography className="whiteText" variant="h6" gutterBottom>
                        {msg}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <ButtonDialogAction
                        onClick={handleClose}
                        text={getLocalizedString("disagree")}
                    />
                    <ButtonDialogAction
                        isAccept
                        onClick={confirm}
                        text={getLocalizedString("agree")}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ConfirmActionDialog;
