import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useOrganizationalBenefits from "./useOrganizationalBenefits";
import CreateEditDialog from "./dialogs/createEditDialog/index";
import ImageRenderer from "@icarius-table/renderersAndFilters/imageRenderer";

const OrganizationalBenefits = ({ history }) => {

  const {
    data,
    typeBenefitList,
    typeFuncList,
    functionList,
    positionList,
    laborUnionList,
    categoryList,
    specialityList,
    workPlaceList,
    managementList,
    workOrTaskList,
    costCenterList,
    isLoading,
    state,
    handlers
  } = useOrganizationalBenefits();

  const createButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog(gridRef)}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )
  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.benefitsDefinitions)}
      type={"goBack"}
    />

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Beneficios organizacionales'}
      gridTitle={'Beneficios organizacionales'}
      columnDefPage={paths.organizationalBenefits}
      rowData={data}
      menuItems={[goBackItem, createButton, modifyButton, deleteButton]}
      hasExpand
      hasHelp
      handleRowClick={handlers.handleOpenViewDialog}
      frameworkComponents={{ ImageRenderer }}
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen || state.viewDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen || state.viewDialogIsOpen)}
          loading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDuplicateDialog}
          typeBenefitList={typeBenefitList}
          typeFuncList={typeFuncList}
          functionList={functionList}
          positionList={positionList}
          laborUnionList={laborUnionList}
          categoryList={categoryList}
          specialityList={specialityList}
          workPlaceList={workPlaceList}
          managementList={managementList}
          workOrTaskList={workOrTaskList}
          costCenterList={costCenterList}
          isView={state.viewDialogIsOpen}
        />
      }
    </CommonPage>
  )
}

export default OrganizationalBenefits;