import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import {
  OnboardingProcessesIcon,
  OnboardingTrackingIcon,
} from "@icarius-icons/";
import paths from "@icarius-localization/paths";
import { useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";

const Index = () => {

  const { level } = useSelector(getUserData);

  const data = [
    {
      name: "Procesos de onboarding",
      icon: <OnboardingProcessesIcon />,
      path: paths.onboardingProcesses,
    },
    {
      name: level === "E" ? "Seguimiento de onboarding" : "Mis tareas de onboarding",
      icon: <OnboardingTrackingIcon />,
      path: paths.onboardingTracking,
    },
  ];

  return (
    <ScreenSelector
      title={"Onboarding"}
      data={data}
    />
  );
}

export default Index;
