import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  typeBenefitList: [],
  typeFuncList: [],
  functionList: [],
  positionList: [],
  laborUnionList: [],
  categoryList: [],
  specialityList: [],
  workPlaceList: [],
  managementList: [],
  workOrTaskList: [],
  costCenterList: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        typeBenefitList: action.payload.typeBenefitList,
        typeFuncList: action.payload.typeFuncList,
        positionList: action.payload.positionList,
        functionList: action.payload.functionList,
        laborUnionList: action.payload.laborUnionList,
        categoryList: action.payload.categoryList,
        specialityList: action.payload.specialityList,
        workPlaceList: action.payload.workPlaceList,
        managementList: action.payload.managementList,
        workOrTaskList: action.payload.workOrTaskList,
        costCenterList: action.payload.costCenterList,
      };

    case actionTypes.DELETE_FULFILLED:
    case actionTypes.CREATE_FULFILLED:
    case actionTypes.MODIFY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };

    case actionTypes.GET:
    case actionTypes.MODIFY:
    case actionTypes.CREATE:
    case actionTypes.DELETE:
      return { ...state, isLoading: true };

    case actionTypes.GET_REJECTED:
    case actionTypes.MODIFY_REJECTED:
    case actionTypes.DELETE_REJECTED:
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
