import React, { useCallback, useState, useRef, useEffect } from 'react';
import OrgChart from '@balkangraph/orgchart.js';
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Tooltip,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import CustomIconButton from '@icarius-common/abmComponents/customIconButton';
import { TreeOrgChartIcon, RoomIcon, AccountTreeIcon, FilterButtonIcon } from "@icarius-icons";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Loader from "@icarius-common/loader";
import { DOWNLOAD_ORGCHART_PDF_ENDPOINT } from "@icarius-connection/endpoints";
import { DownloadIcon } from '@icarius-icons/index';

const OwnOrgChart = React.memo((props) => {
    const {
        color,
        setCurrentNode,
        setDialogIsOpen,
        nodes,
        setChartRef,
        handleGoBack,
        hasMultipleBranches,
        setPositionOccupation,
        theme,
        handleOpenHighlightWorkplaceDialog,
        hasFilterData,
        setFiltersDialogIsOpen,
        handleOpenHighlightManagementDialog,
        highlightData,
    } = props;

    const chartRef = useRef();
    const divRef = useRef();
    const nodeIdRef = useRef();
    const [nodePositionOccupation, setNodePositionOccupation] = useState(null);

    const detailsIcon = '<svg width="24" height="24" viewBox="0 0 512 512"><path fill="#7A7A7A" d="M447.933,103.629c-0.034-3.076-1.224-6.09-3.485-8.352L352.683,3.511c-0.004-0.004-0.007-0.005-0.011-0.008 C350.505,1.338,347.511,0,344.206,0H89.278C75.361,0,64.04,11.32,64.04,25.237v461.525c0,13.916,11.32,25.237,25.237,25.237 h333.444c13.916,0,25.237-11.32,25.237-25.237V103.753C447.96,103.709,447.937,103.672,447.933,103.629z M356.194,40.931 l50.834,50.834h-49.572c-0.695,0-1.262-0.567-1.262-1.262V40.931z M423.983,486.763c0,0.695-0.566,1.261-1.261,1.261H89.278 c-0.695,0-1.261-0.566-1.261-1.261V25.237c0-0.695,0.566-1.261,1.261-1.261h242.94v66.527c0,13.916,11.322,25.239,25.239,25.239 h66.527V486.763z"></path><path fill="#7A7A7A" d="M362.088,164.014H149.912c-6.62,0-11.988,5.367-11.988,11.988c0,6.62,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.368,11.988-11.988C374.076,169.381,368.707,164.014,362.088,164.014z"></path><path fill="#7A7A7A" d="M362.088,236.353H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.62,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.368,11.988-11.988C374.076,241.721,368.707,236.353,362.088,236.353z"></path><path fill="#7A7A7A" d="M362.088,308.691H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.367,11.988-11.988C374.076,314.06,368.707,308.691,362.088,308.691z"></path><path fill="#7A7A7A" d="M256,381.031H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.368,11.988,11.988,11.988H256 c6.62,0,11.988-5.367,11.988-11.988C267.988,386.398,262.62,381.031,256,381.031z"></path></svg>'
    const personIcon = '<svg fill="#7A7A7A" width="24" height="24" class="MuiSvgIcon-root MuiSvgIcon-root css-zjt8k" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="PersonIcon" tabindex="-1" title="Person"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>'

    const [chart, setChart] = useState(null);
    const [orientation, setOrientation] = useState(0);
    const [vacanciesHighlightingInProgress, setVacanciesHighlightingInProgress] = useState(false);

    const showSettingsDialog = () => {
        setFiltersDialogIsOpen(true)
    }

    useEffect(() => {
        chartRef.current = chart;
        setChartRef(chart)
    }, [chart, setChartRef])

    useEffect(() => {
        if (nodePositionOccupation)
            setPositionOccupation(nodePositionOccupation);
    }, [nodePositionOccupation, setPositionOccupation])

    const showEditForm = useCallback(() => {
        setDialogIsOpen(true)
    }, [setDialogIsOpen])


    const showDetailsForm = useCallback(() => {
        setDialogIsOpen(true)
    }, [setDialogIsOpen])

    const editForm = function () {
        nodeIdRef.current = null;
    };

    const detailsHandler = useCallback((idNode) => {
        showDetailsForm();
        nodeIdRef.current = idNode;

        if (chartRef.current) {
            const node = chartRef.current.get(nodeIdRef.current);
            if (node) {
                setCurrentNode(node)
            }
        }
    }, [showDetailsForm, setCurrentNode])


    useEffect(() => {
        editForm.prototype.init = function (obj) {
            this.obj = obj;
        };


        const occupationHandler = (idNode) => {
            setNodePositionOccupation(null)
            const node = chartRef.current.get(idNode);
            if (node) {
                setNodePositionOccupation(node)
            }
        }

        editForm.prototype.show = function (idNode) {
            showEditForm();
            nodeIdRef.current = idNode;
            const node = chartRef.current.get(nodeIdRef.current);
            setCurrentNode(node)
        };

        editForm.prototype.hide = function (showldUpdateTheNode) {

        };

        OrgChart.SEARCH_PLACEHOLDER = "Buscar...";
        OrgChart.RES.IT_IS_LONELY_HERE_LINK = "No hay cargos disponibles";
        OrgChart.IT_IS_LONELY_HERE = '<g transform="translate(-105, 0)" style="cursor:pointer;"  control-add="control-add"><text fill="#039be5" style="font-size: smaller;">No hay cargos disponibles</text></g>'
        OrgChart.searchUI.createItem = function (t, e, r, i) {
            let result = i;
            const indexMarkStart = result.indexOf("<mark>");
            const isNameSeparated = result.indexOf(";");
            if (indexMarkStart !== -1 && isNameSeparated !== -1) {
                let lastIndexOfSeparator = result.lastIndexOf(";", indexMarkStart);
                if (lastIndexOfSeparator === -1) {
                    lastIndexOfSeparator = 0;
                }

                let indexOfNextSeparator = result.indexOf(";", indexMarkStart);
                if (indexOfNextSeparator === -1) {
                    result = result.substring(lastIndexOfSeparator + 1)
                } else {
                    result = result.substring(lastIndexOfSeparator + 1, indexOfNextSeparator)
                }
            }
            return r && (r = "<b>" + r + "</b>") && `<tr data-search-item-id="${e}">\n<td class="boc-search-image-td">\n${t = t ? `<div class="boc-search-photo" style="background-image: url(${t})"></div>` : `<div class="boc-search-photo">${OrgChart.icon.user(32, 32, "#aeaeae")}</div>`}\n</td>\n<td class="boc-search-text-td">${r}<br/>${result}</td>\n</tr>`
        };

        OrgChart.templates.invisibleGroup.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';
        OrgChart.templates.group.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';
        OrgChart.templates.group.nodeMenuButton = '';
        OrgChart.templates.group.min = Object.assign({}, OrgChart.templates.group);
        OrgChart.templates.group.min.imgs = "{val}";
        OrgChart.templates.group.min.img_0 = "";

        OrgChart.templates.customTheme = Object.assign({}, OrgChart.templates.ana);
        // para que salga el circulo con la cantidad
        OrgChart.templates.ula.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
            + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';
        OrgChart.templates.customTheme.size = [265, 190];
        OrgChart.templates.customTheme.resume = '<text class="resume" style="font-size: 18px;" fill="#ffffff" x="230" y="30" text-anchor="end">{val}</text>';
        OrgChart.templates.customTheme.field_0 = "<text " + OrgChart.attr.width + '="230" data-text-overflow="multiline" style="font-size: 18px;" fill="#FFF" x="10" y="90" text-anchor="left">{val}</text>';
        OrgChart.templates.customTheme.field_1 = "<text " + OrgChart.attr.width + '="230" ' + OrgChart.attr.text_overflow + '="multiline" style="font-size: 14px;" fill="#FFF" x="10" y="145" text-anchor="left">{val}</text>';
        OrgChart.templates.customTheme.nodeMenuButton = '<g style="cursor:pointer;" transform="matrix(1,0,0,1,225,160)" ' + OrgChart.attr.control_node_menu_id + '="{id}"><rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect><circle cx="0" cy="0" r="2" fill="#FFF"></circle><circle cx="7" cy="0" r="2" fill="#FFF"></circle><circle cx="14" cy="0" r="2" fill="#FFF"></circle></g>';

        OrgChart.templates.customThemeAssistant = Object.assign({}, OrgChart.templates.ula);
        OrgChart.templates.customThemeAssistant.size = [265, 190];
        OrgChart.templates.customThemeAssistant.resume = '<text class="resume" style="font-size: 18px;" fill="#777" x="230" y="30" text-anchor="end">{val}</text>';
        OrgChart.templates.customThemeAssistant.field_0 = "<text " + OrgChart.attr.width + '="230" data-text-overflow="multiline" style="font-size: 18px;" fill="#039be5" x="10" y="90" text-anchor="left">{val}</text>';
        OrgChart.templates.customThemeAssistant.field_1 = "<text " + OrgChart.attr.width + '="230" ' + OrgChart.attr.text_overflow + '="multiline" style="font-size: 14px;" fill="#afafaf" x="10" y="145" text-anchor="left">{val}</text>';
        OrgChart.templates.customThemeAssistant.nodeMenuButton = '<g style="cursor:pointer;" transform="matrix(1,0,0,1,225,160)" ' + OrgChart.attr.control_node_menu_id + '="{id}"><rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect><circle cx="0" cy="0" r="2" fill="#039be5"></circle><circle cx="7" cy="0" r="2" fill="#039be5"></circle><circle cx="14" cy="0" r="2" fill="#039be5"></circle></g>';
        OrgChart.templates.customThemeAssistant.img_0 = '<clipPath id="{randId}"><circle cx="50" cy="30" r="40"></circle></clipPath><image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="10" y="-10" width="80" height="80" ></image>'
        OrgChart.templates.ula.node = '<rect x="0" y="0" height="{h}" width="{w}" fill="#ffffff" stroke-width="1" stroke="#aeaeae"></rect><line x1="0" y1="0" x2="265" y2="0" stroke-width="2" stroke="#00FF00" style="stroke: #039be5;" ></line>'
        if (!chart) {
            const newChart = new OrgChart(divRef.current, {
                exportUrl: DOWNLOAD_ORGCHART_PDF_ENDPOINT,
                template: "customTheme",
                nodes: nodes, //carga de datos
                align: OrgChart.ORIENTATION,
                layout: OrgChart.treeRightOffset,
                nodeMouseClick: OrgChart.action.none,
                nodeMenu: {
                    ocupacion: {
                        icon: personIcon,
                        text: "Ocupación",
                        onClick: occupationHandler
                    },
                    detalles: {
                        icon: detailsIcon,
                        text: "Detalles",
                        onClick: detailsHandler
                    }
                },
                editUI: new editForm(),
                //el menu
                toolbar: {
                    zoom: true,
                    fit: true,
                    expandAll: true
                },

                searchDisplayField: "employeeName",
                searchFieldsWeight: {
                    "employeeName": 100, //percent
                    "employees": 20 //percent
                },

                nodeBinding: {
                    img_0: "picture",
                    field_0: "employeeName",
                    field_1: "Nombre del cargo",
                    field_2: "Cantidad de puestos",
                    field_3: "Puestos ocupados",
                    field_4: "Puestos libres",
                    field_5: "Grado de responsabilidad",
                    field_6: "employees",
                    resume: "resume"
                },

                tags: {
                    "assistant": {
                        template: "customThemeAssistant"
                    },
                    "no-group": {
                        template: "invisibleGroup", //sin grupo                   
                    },
                    "group": {
                        template: "group", //con grupo
                    },
                    "subtree": { //config general
                        subTreeConfig: {
                            //layout: OrgChart.treeRightOffset,
                            collapse: {
                                level: 2,
                            }
                        },
                    },
                    "subtree-min": { //con min
                        min: true,
                    }
                }
            });
            setChart(newChart)
        }
    }, [chart, detailsHandler, showEditForm, setCurrentNode, color, nodes, theme])
    const handleChangeOrientation = (orientation) => {
        setOrientation(orientation);
        if (chart) {
            chart.draw(OrgChart.action.update, null, function () {
                chart.setOrientation(orientation);
            });
        }
    }

    const handleClickExportPDF = () => {
        if (chart) {
            chart.exportPDF({
                padding: 30,
                filename: "Organigrama.pdf"
            });
        }
    }

    useEffect(() => {
        if (chart) {
            chart.on('click', function (sender, args) {
                if (args.node.tags.indexOf("group") !== -1) {
                    if (args.node.min) {
                        sender.maximize(args.node.id);
                    }
                    else {
                        sender.minimize(args.node.id);
                    }
                    chart.draw(OrgChart.action.update, null, function () {
                        chart.fit();
                    });
                    return false;
                }
                return true;
            });

            chart.load(nodes)

            chart.draw(OrgChart.action.update, null, function () {
                chart.fit();
            });
        }
    }, [chart, orientation, detailsHandler, nodes, setCurrentNode, showEditForm, setChart])

    const handleeTreeLayout = () => {
        if (chartRef.current) {
            chartRef.current._layoutConfigs['base'].layout = OrgChart.tree;
            chartRef.current.draw();
        }

    }

    const handleRightOffsetTreeLayout = () => {
        if (chartRef.current) {
            chartRef.current._layoutConfigs['base'].layout = OrgChart.treeRightOffset;
            chartRef.current.draw();
        }
    }

    useEffect(() => {
        setVacanciesHighlightingInProgress(true);
        setTimeout(() => {
            if (!document.hidden) {
                if (chart) {
                    chart.config.nodes.forEach(node => {
                        if (node) {
                            // Limpio antes
                            if (node.hasOwnProperty("tags")) {
                                node.tags = node.tags.filter(item => item !== "management" && item !== "management-workplace" && item !== "workplace")
                            }

                            const childrenManagementCriteria = node.children.filter(el => highlightData["management"].includes(el["managementKey"]));
                            if (childrenManagementCriteria.length > 0) {
                                if (highlightData && highlightData["management"] && highlightData["management"].length > 0) {
                                    if (node.hasOwnProperty("tags")) {
                                        node["tags"].push("management")
                                    } else {
                                        node["tags"] = ["management"]
                                    }
                                } else {
                                    if (node.hasOwnProperty("tags") && node.tags.includes("management")) {
                                        node.tags = node.tags.filter(item => item !== "management" && item !== "management-workplace")
                                    }
                                }
                            } else {
                                if (node.hasOwnProperty("tags") && node.tags.includes("management")) {
                                    node.tags = node.tags.filter(item => item !== "management" && item !== "management-workplace")
                                }
                            }
                            const childrenWorkplaceCriteria = node.children.filter(el => highlightData["workplace"].includes(el["workplaceKey"]));
                            if (childrenWorkplaceCriteria.length > 0) {
                                if (highlightData && highlightData["workplace"] && highlightData["workplace"].length > 0) {
                                    if (node.hasOwnProperty("tags")) {
                                        node["tags"].push("workplace");
                                        if (node["tags"].includes("management")) {
                                            node["tags"].push("management-workplace");
                                        }
                                    } else {
                                        node["tags"] = ["workplace"];
                                    }
                                } else {
                                    if (node.hasOwnProperty("tags") && node.tags.includes("workplace")) {
                                        node.tags = node.tags.filter(item => item !== "workplace" && item !== "management-workplace")
                                    }
                                }
                            } else {
                                if (node.hasOwnProperty("tags") && node.tags.includes("workplace")) {
                                    node.tags = node.tags.filter(item => item !== "workplace" && item !== "management-workplace")
                                }
                            }

                            chart.updateNode(node);
                        }
                    })

                    chart.draw(OrgChart.action.update, null, function () {
                        chart.fit();
                    });

                    setVacanciesHighlightingInProgress(false)
                }
            }
        }, 100);
    }, [chart, highlightData])


    return (
        <>
            {vacanciesHighlightingInProgress && <Loader open={vacanciesHighlightingInProgress} />}
            <Grid container direction="row" justify="center">
                {
                    hasMultipleBranches &&
                    <CustomIconButton
                        title={getLocalizedString("goBack")}
                        onClick={handleGoBack}
                        type={"goBack"}
                    />
                }
                <CustomIconButton
                    title={"Filtrar organigrama"}
                    onClick={showSettingsDialog}
                    isSelected={hasFilterData}
                >
                    <FilterButtonIcon />
                </CustomIconButton>
                <CustomIconButton
                    title={"Resaltar por gerencia"}
                    onClick={handleOpenHighlightManagementDialog}
                    isSelected={highlightData && highlightData["management"] && highlightData["management"].length > 0}
                >
                    <AccountBalance />
                </CustomIconButton>
                <CustomIconButton
                    title={"Resaltar por lugar de trabajo"}
                    isSelected={highlightData && highlightData["workplace"] && highlightData["workplace"].length > 0}
                    onClick={handleOpenHighlightWorkplaceDialog}
                >
                    <RoomIcon />
                </CustomIconButton>
                <Tooltip title={"Estructura en forma de árbol con desplazamiento a la derecha"}>
                    <Button
                        height="25px"
                        style={{
                            color: color,
                            minWidth: "56px",
                            marginRight: 5,
                        }}
                        disableRipple={true}
                        variant={"outlined"}
                        onClick={handleRightOffsetTreeLayout} >
                        <AccountTreeIcon htmlColor={color} height="25px" />
                    </Button>
                </Tooltip>
                <Tooltip title={"Estructura en forma de árbol"}>
                    <Button
                        height="25px"
                        style={{
                            color: color,
                            minWidth: "56px",
                            marginRight: 5,
                        }}
                        disableRipple={true}
                        variant={"outlined"}
                        onClick={handleeTreeLayout} >
                        <TreeOrgChartIcon fill={color} height="25px" />
                    </Button>
                </Tooltip>
                <Tooltip title={"Exportar PDF"}>
                    <Button
                        height="25px"
                        style={{
                            color: color,
                            minWidth: "56px",
                            marginRight: 5,
                        }}
                        disableRipple={true}
                        variant={"outlined"}
                        onClick={handleClickExportPDF} >
                        <DownloadIcon fill={color} height="25px" />
                    </Button>
                </Tooltip>


                <FormControl style={{ width: "350px", margin: "0px 10px" }}>
                    <InputLabel id="orientation-label">{"Orientación"}</InputLabel>
                    <Select labelId="orientation-label" value={orientation} onClick={(e) => handleChangeOrientation(e.target.value || 0)}>
                        <MenuItem className={"whiteText"} value={0}>{"Arriba"}</MenuItem>
                        <MenuItem className={"whiteText"} value={3}>{"Izquierda"}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <div id="tree" ref={divRef} style={{ maxHeight: "calc(100vh - 200px)" }}></div>
        </>
    );
})

export default OwnOrgChart;